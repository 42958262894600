import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    HStack,
    Image,
    Flex,
    Stack,
    VStack,
    Text,
    useToast
} from '@chakra-ui/react';
import modalControlAtom from '~/recoil/atom/modal';
import payControlAtom from '~/recoil/atom/pay';
import { useSetRecoilState, useRecoilState } from 'recoil';
import BookingDetail from '~/components/BookingDetail';
import CancelPolicy from '~/components/CancelPolicy';
import HotelInfo from '~/components/HotelInfo/index';
import Quote from '~/components/Quote';
import CheckInInformation from '~/components/CheckInInformation';
import ContactInformation from '~/components/ContactInformation';
import Coupon from '~/components/Coupon';
import PayType from '~/components/PayType';
import Countdown from '~/components/Countdown';
import {
    getQueryString,
    getToken
} from '~/utils/tools';
import { useNavigate } from 'react-router-dom';
import PageContainer from '~/containers/PageContainer';
import { createBooking } from '~/awsHttpApi/createBooking';
import getBooking from '~/awsHttpApi/getBooking';
import getHotel from '~/awsHttpApi/getHotel';
import getHotelRates from '~/awsHttpApi/getHotelRates';
import {
    Bed,
    ContactInfo,
    FullName,
    Hotel,
    Rate,
    RoomsInfoList,
    RoomInfo,
} from '~/types';
import StepOne from '~/assets/images/stepOne.png';
import StepTwo from '~/assets/images/stepTwo.png';
import DefaultImg from '~/assets/images/default.png';

import { useI18NText } from '~/i18n/i18n';
import { setTimeout } from 'timers';


let guestByRoomProps:FullName[] = [
    {
        firstName: '',
        lastName: ''
    }
]
let ContactProps: ContactInfo = {
    name: {
        lastName: '',
        firstName: '',
    },
    phone: '',
    email: '',
}
export default function ConfirmOrder() {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'confirmorder.' });
    let guestRoomList = [];
    for (let i = 0; i < Number(getQueryString('rooms')); i++) {
        guestRoomList.push(guestByRoomProps);
    }
    const toast = useToast();
    const [
        {
            isPayTipModalOpen
        },
        setModalControl,
      ] = useRecoilState(modalControlAtom);
    const setPayControlAtom = useSetRecoilState(payControlAtom);
    const navigate = useNavigate();
    const [adults, setAdults] = useState(getQueryString('adults'));
    let num = getQueryString('rooms') || 1;
    const [rooms, setRooms] = useState(num);
    const [start, setStart] = useState(getQueryString('checkin') || '');
    const [end, setEnd] = useState(getQueryString('checkout') || '');
    const [rateId, setRateId] = useState(getQueryString('rateId') || '');
    const [tripid, setTripid] = useState(getQueryString('tripid') || '');
    const [hotelId, setHotelId] = useState(getQueryString('hotelid') || '');
    const [step, setStep] = useState(1);
    const [isEdit, setIsEdit] = useState(true);
    let [loading, setLoading] = useState(true);
    const [hotel, setHotel] = useState<Hotel | null>(null);
    let [guestByRoom, setGuestByRoom] = useState(guestRoomList);
    let [Contact, setContactProps] = useState(ContactProps);
    let [hotelDetail, setHotelDetail] = useState<Rate | null>(null);
    let [bedInfo, setBedIndo] = useState({});
    let [isPay, setIsPay] = useState(false);
    let Count = Number(getQueryString('adults')) + Number(getQueryString('children'));
    let [isPhone, setIsPhone] = useState(true);
    let [isEmail, setIsEmail] = useState(true);
    let [bookingId, setBookingId] = useState<string>('');
    let [canSubmit, setCanSubmit] = useState(true);
    let [hotelPrice, setHotelPrice] = useState(0);
    let siv = null;
    useEffect(()=>{
        if (loading && tripid) {
            window.scrollTo(0, 0);
            getInit(tripid);
            setLoading(false);
        }
        if (step == 1) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
        if (isPayTipModalOpen) {
            setCanSubmit(true);
        }
    },[loading, step, tripid, isPayTipModalOpen]);
    const getInit = async (hotelId:string) => {
        let rates: Rate[];
        rates = await getHotelRates({
            token: getToken(),
            hotelId: hotelId,
            checkIn: start.replace(new RegExp('/', 'g'), '-'),
            checkOut: end.replace(new RegExp('/', 'g'), '-'),
            group: {
                adults: Number(adults),
                //   children: kids.map(({ age }) => age),
                children: [],
            },
            roomCount: Number(rooms),
        });
        let hotelInfo = await getHotel({
            token: getToken(),
            hotelId
        });
        let roomRates = hotelInfo?.rooms || [];
        let roomRatesList: RoomsInfoList[] = [];
        hotelInfo?.rooms.forEach((item, index)=>{
            let ratesList = rates?.filter(json=>{
                return Number(json?.roomId) == Number(item?.roomId);
            });
            roomRatesList.push({
                roomId: item.roomId,
                name: item.name,
                images: item.images,
                showMore: index == 0 ? true : false,
                list: ratesList
            });
        });
        let hotel = rates.filter(item=> {
            return item.rateId == rateId;
        })[0];
        let bed = {};
        if (hotel && hotel.bed) {
            bed = JSON.parse(hotel.bed)[0]
        }
        console.log('bed', bed);
        console.log('hotel', hotel);
        console.log('hotelInfo', hotelInfo);
        if (hotel && hotel.price) {
            let price = hotel.price * Number(rooms);
            setHotelPrice(price);
        }
        setBedIndo(bed);
        setHotelDetail(hotel);
        setHotel(hotelInfo);
    }
    const onChangePerson = (event:any) => {
        let {
            key,
            value,
            type
        } = event;
        let guestByRoomItem = [...guestByRoom[value]];
        if (type == 'add') {
            guestByRoomItem.push({
                lastName: '',
                firstName: '',
            });
            guestByRoom = guestByRoom.map((item, index)=> {
                if(index === value) {
                    item = [...guestByRoomItem];
                }
                return item;
            });
        } else {
            guestByRoom[value].splice(key, 1);
        }
        setGuestByRoom([...guestByRoom]);
    }
    const onChangeGuest = (event:any) => {
        let {
            type,
            key,
            subKey,
            value
        } = event;
        let list = deepClone(guestByRoom);
        list = list.map((item:any, index:number) => {
            if (index === key) {
                item = item.map((json:any, num:number) => {
                if (num === subKey) {
                    json[type] = value;
                }
                return json;
                });
            }
            return item;
        });
        setGuestByRoom([...list]);
    }
    const deepClone = (obj:any) => {
        const isObject = (args:any) => (typeof args === 'object' || typeof args === 'function') && typeof args !== null
        if (!isObject) throw new Error('Not Reference Types')
        let newObj = Array.isArray(obj) ? [...obj] : { ...obj }
        Reflect.ownKeys(newObj).map(key => {
            newObj[key] = isObject(obj[key]) ? deepClone(obj[key]) : obj[key]
        })
        return newObj
    }
    const changeContact = (event:any) => {
        let {
            type,
            value
        } = event;
        switch (type) {
            case 'firstName':
                Contact.name.firstName = value;
                break;
            case 'lastName':
                Contact.name.lastName = value;
                break;
            case 'phone':
                Contact.phone = value;
                break;
            case 'email':
                Contact.email = value;
                break;
            default:
                Contact.name.firstName = value;
                break;
        }
        setContactProps({...Contact})
    }
    const toPayOrder = () => {
        if (step === 1) {
            setIsPay(true);
            let flag = true;
            guestByRoom.forEach(item=>{
                item.forEach(json=>{
                    if (json.firstName === '' || json.lastName === '') {
                        flag = false;
                    }
                })
            });
            if ((typeof(Contact.name) === 'string' && Contact.name === '') || typeof(Contact.name) === 'object' && Contact.name.firstName === '') {
                flag = false;
            }
            if (Contact.phone === '') {
                flag = false;
            }
            let phoneReg = /^1[3456789]\d{9}$/;
            if (Contact.phone !== '' && !phoneReg.test(Contact.phone)) {
                setIsPhone(false);
                flag = false;
            }
            let emailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
            if (Contact.email !== '' && !emailReg.test(Contact.email)) {
                setIsEmail(false);
                flag = false;
            }
            if (flag) {
                payOrder();
            }
        }
    }
    const payOrder = async () => {
        if (!canSubmit) {
            return;
        }
        if (step == 1) {
            setModalControl((prev) => ({ ...prev, isPayTipModalOpen: true }));
            try {
                const token = getToken();
                const { bookingId, paymentURL, invalidTime } = await createBooking(token, {
                  customer: {
                    contactPerson: Contact,
                    guest: guestByRoom,
                  },
                  rates: [
                    {
                      id: rateId,
                      count: Number(rooms),
                    },
                  ],
                  hotelId,
                  checkIn: start,
                  checkOut: end,
                });
                setCanSubmit(false);
                if (bookingId) {
                    navigate(`/payorder/${encodeURIComponent(bookingId)}`);
                    setModalControl((prev) => ({ ...prev, isPayTipModalOpen: false }));
                }
            } catch (err) {
                setCanSubmit(true);
            } finally {
            }
        } else {
            setModalControl((prev) => ({ ...prev, isPayTipModalOpen: true }));
            if (bookingId) {
                setModalControl((prev) => ({ ...prev, isPayTipModalOpen: false, isWechatPayModalOpen: true }));
            }
        }
    }
    const getDetail = async(orderId: string) => {
        if (!orderId) return;
        let res = await getBooking({ token:getToken(), bookingId: orderId })
        if (res && res.status == 'PROCESSING') {
            navigate(`/payresult/${bookingId}`)
        }
    }
    return (
        <PageContainer
            topBoxStyle={{ position: 'static' }}
        >
            <Box
                bgColor='#FFFFFF'
                pt='75px'
            >
                <Image
                    src={step == 1 ? StepOne : StepTwo}
                    h='47px'
                    ml='auto'
                    mr='auto'
                    mb='24px'
                />
            </Box>
            <VStack
                align="stretch"
                bgColor='#FAFAFA'
                px='124px'
                pb='63px'
            >
                <Text
                    my='24px'
                    fontSize='28px'
                    color='gray.100'
                    fontWeight='500'
                    letterSpacing='0 !important'
                >
                    {getI18NText('填写信息')}
                </Text>
                <Flex
                    justifyContent='space-between'
                >   
                    <VStack
                        align="stretch"
                        w='830px'
                    >
                        <CheckInInformation
                            title={getI18NText('入住信息')}
                            subTitle=''
                            guestByRoom={guestByRoom}
                            isEdit={isEdit}
                            onChangePerson={(e:any)=>onChangePerson(e)}
                            onChangeGuest={(e:any)=>onChangeGuest(e)}
                            isPay={isPay}
                        />
                        <ContactInformation
                            contactPerson={Contact}
                            isEdit={isEdit}
                            onChange={(e:any) => changeContact(e)}
                            isPay={isPay}
                            isPhone={isPhone}
                            isEmail={isEmail}
                        />
                    </VStack>
                    <Box
                        w='338px'
                    >
                        <Stack
                            spacing='12px'
                        >
                           <HotelInfo
                                image={hotel?.images[0] ? hotel?.images[0].url : DefaultImg}
                                checkIn={hotelDetail?.checkIn}
                                checkOut={hotelDetail?.checkOut}
                                bedInfo={bedInfo}
                                hotelName={hotelDetail?.hotel.name['zh-CN']}
                                address={hotelDetail?.hotel.address['zh-CN']}
                                roomName={hotelDetail?.roomName['zh-CN']}
                                hasBreakfast={hotelDetail?.hasBreakfast}
                                num={Number(adults)}
                                hotelId={hotelId}
                            />
                            <CancelPolicy
                                cancelPolicies={hotelDetail?.cancelPolicies}
                            />
                            <Quote
                                checkIn={hotelDetail?.checkIn}
                                checkOut={hotelDetail?.checkOut}
                                dailyPrice={hotelDetail?.dailyPrice}
                            />
                            {
                                (hotelDetail && rooms) &&
                                <BookingDetail
                                    rooms={rooms ? rooms : 0}
                                    showPay={step == 1 ? true :  false}
                                    price={hotelPrice || 0}
                                    toPay={()=>toPayOrder()}
                                />
                            }
                        </Stack>
                    </Box>
                </Flex>
            </VStack>
        </PageContainer>
    )
}