import { useState, useRef } from 'react';
import {
    Box,
    BoxProps,
    Center,
    Divider,
    Flex,
    HStack,
    Icon,
    Image,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    Text,
    Button,
} from '@chakra-ui/react';
import formatDate from 'date-fns/format';
import bedIcon from '~/assets/images/bedIcon.jpeg';
import forkIcon from '~/assets/images/fork.png';
import tipIcon from '~/assets/images/tip.png';
import tipActiveIcon from '~/assets/images/tipActive.png';
import tipsIcon from '~/assets/images/tips.png';
import {
    AiOutlineExclamationCircle,
    AiOutlineQuestionCircle
} from 'react-icons/ai';
import {
    Rate,
    Meal
} from '~/types';
import searchRecentCancelPolicy from '~/utils/searchRecentCancelPolicy';
import { useI18NText } from '~/i18n/i18n';

type RoomTypeProps = {
    img?: string;
    key?: number;
    data?: any;
    type?: number;
    onNavigate: (obj: NavigateProps) => void;
    onShow: (id: any) => void;
}
type NavigateProps = {
    rateId?: string;
    hotelId?: string;
    roomId?: number
}
export default function RoomType({
    img = '',
    key = 0,
    data = {},
    type = 0,
    onNavigate,
    onShow
}: RoomTypeProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })

    const initialFocusRef = useRef();
    const handleActiveStyle = (isActive: boolean): BoxProps => {
        return isActive
            ? {
                color: 'danger.100',
            }
            : {
                color: 'fontColor',
            }
    };
    const handleActivesStyle = (isActive: boolean): BoxProps => {
        return isActive
            ? {
                color: '#14C0E5',
            }
            : {
                color: 'fontColor',
            }
    };
    const getAveragePrice = (dailyPrice: Array<number>) => {
        let AveragePrice = 0;
        let totalPrice = dailyPrice.reduce((acr, cur) =>{
            return acr + cur;
        });
        AveragePrice = Math.ceil(totalPrice / dailyPrice.length);
        return AveragePrice;
    }
    const renderItem = (item:Rate) => {
        if (!item) {
           return 
        }
        const parsedMeal: Meal = JSON.parse(item.meal || '{}');
        // const mealText = parsedMeal.Name_CN || phasBreakfastarsedMeal.Name || hasBreakfast ? '含早餐' : '不含早餐';
        const mealText = item.hasBreakfast ? '含早餐' : '不含早餐';
        const recentCancelPolicy = searchRecentCancelPolicy(item.cancelPolicies);
        let cancelPolicyText = '';
        if (!recentCancelPolicy || recentCancelPolicy.percent === 1) {
            cancelPolicyText = getI18NText('不可退订');
        }

        if (recentCancelPolicy && 0 < recentCancelPolicy.percent && recentCancelPolicy.percent < 1) {
            cancelPolicyText = getI18NText('部分退款');
        }

        if (recentCancelPolicy && recentCancelPolicy.percent === 0) {
            cancelPolicyText = getI18NText('免费取消');
        }
        return (
            <HStack
                h='80px'
                alignItems='center'
                key={item.rateId}
                borderBottom='3px solid #F2F2F2'
            >
                <HStack
                    w='317px'
                    ml='37px'
                    alignItems='center'
                >
                    {
                        type == 0 &&
                        <HStack
                            w='179px'
                            marginInlineStart='0 !important'
                        >
                            <Popover
                                placement='right'
                                closeOnBlur={false}
                                trigger='hover'
                            >
                                <PopoverTrigger>
                                    <Text
                                        h='40px'
                                        lineHeight='20px'
                                        w='153px'
                                        mr='24px'
                                        color='blue'
                                        fontSize='12px'
                                        fontWeight='400'
                                        letterSpacing='0 !important'
                                        cursor='pointer'
                                    >
                                        {item?.roomName?.['zh-CN']}
                                    </Text>
                                </PopoverTrigger>
                                <PopoverContent
                                    w='360px'
                                    color='white'
                                    bg='blue'
                                    borderColor='blue'
                                    p='12px 30px 12px 12px'
                                    h='64px'
                                >
                                    <PopoverArrow bg='blue' />
                                    {/* <PopoverCloseButton /> */}
                                    <PopoverBody
                                        p='0 !important'
                                    >
                                        <Text
                                            fontSize='12px'
                                            lineHeight='20px'
                                            color='white'
                                            letterSpacing='0 !important'
                                        >
                                            {item?.roomName?.['zh-CN']}{item?.roomName?.['en-US']}
                                        </Text>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </HStack>
                    }
                    <Image
                        src={bedIcon}
                        objectFit='cover'
                        w='24px'
                        h='24px'
                        marginInlineStart='0 !important'
                    />
                    <Text
                        fontSize={type == 0 ? '16px' : '18px'}
                        color='fontColor'
                        marginInlineStart='0 !important'
                        ml='12px !important'
                        letterSpacing='0 !important'
                    >
                        {JSON.parse(item.bed)[0].count}{JSON.parse(item.bed)[0].desc}
                    </Text>
                </HStack>
                <Box
                    w='116px'
                    ml='68.25px !important'
                >
                    <Flex
                        alignItems='center'
                        mb='4px'
                    >
                        <Image
                            src={forkIcon}
                            w='24px'
                            h='24px'
                            mr='12px'
                        />
                        <Text
                            fontSize='14px'
                            color='fontColor'
                            marginInlineStart='0 !important'
                            letterSpacing='0 !important'
                        >
                            {mealText}
                        </Text>
                    </Flex>
                    <Flex
                        alignItems='center'
                    >
                        <Popover
                            placement='right'
                            closeOnBlur={false}
                            trigger='hover'
                        >
                            <Image
                                src={tipIcon}
                                w='24px'
                                h='24px'
                                mr='12px'
                            />
                            <PopoverTrigger>
                                <Text
                                    fontSize='14px'
                                    color='fontColor'
                                    marginInlineStart='0 !important'
                                    cursor='pointer'
                                    _hover={{
                                        color: '#2D6CDF'
                                    }}
                                    letterSpacing='0 !important'
                                >
                                    {cancelPolicyText}
                                </Text>
                            </PopoverTrigger>
                            <PopoverContent color='white' bg='blue' borderColor='blue'>
                                <PopoverArrow bg='blue' />
                                <PopoverCloseButton />
                                <PopoverBody>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='19.6px'
                                        color='white'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText("取消政策说明")}
                                    </Text>
                                    {
                                        item.cancelPolicies.map((json:any)=>{
                                            let {
                                                endAt,
                                                fromTime,
                                                currency,
                                                percent,
                                                refund
                                            } = json;
                                            if (refund == 0) {
                                                return (
                                                    <Text
                                                        fontSize='14px'
                                                        lineHeight='19.6px'
                                                        color='white'
                                                        letterSpacing='0 !important'
                                                    >
                                                        {formatDate(new Date(endAt), 'yyyy-MM-dd HH:mm')} {getI18NText("起")} {getI18NText("不可取消")}
                                                    </Text>
                                                )
                                            }
                                            if (percent == 1) {
                                                return (
                                                    <Text
                                                        fontSize='14px'
                                                        lineHeight='19.6px'
                                                        color='white'
                                                        letterSpacing='0 !important'
                                                    >
                                                        {formatDate(new Date(endAt), 'yyyy-MM-dd HH:mm')} {getI18NText("前")} {getI18NText("免费取消")}
                                                    </Text>
                                                )
                                            }
                                            else {
                                                return (
                                                    <Text
                                                        fontSize='14px'
                                                        lineHeight='19.6px'
                                                        color='white'
                                                        letterSpacing='0 !important'
                                                    >
                                                        {formatDate(new Date(fromTime), 'yyyy-MM-dd HH:mm')} - {formatDate(new Date(endAt), 'yyyy-MM-dd HH:mm')} {getI18NText("需要扣取")} ¥ {refund}
                                                    </Text>
                                                )
                                            }
                                        })
                                    }
                                    {/* <Text
                                        fontSize='14px'
                                        lineHeight='19.6px'
                                        color='white'
                                        letterSpacing='0 !important'
                                    >
                                        12-28 17:50:59 {getI18NText("前")} {getI18NText("免费取消")}
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='19.6px'
                                        color='white'
                                        letterSpacing='0 !important'
                                    >
                                        12-29 17:51:00 - 01-21 23:59:59 {getI18NText("需要扣取")} ¥ 304
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='19.6px'
                                        color='white'
                                        letterSpacing='0 !important'
                                    >
                                        01-03 00:00:00 {getI18NText("起")} {getI18NText("不可取消")}
                                    </Text> */}
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </Flex>
                </Box>
                <HStack
                    w='96px'
                    ml='70.25px !important'
                    alignItems='center'
                >
                    <Icon
                        as={AiOutlineExclamationCircle}
                        boxSize='1.25rem'
                        mr='12px'
                        color='#2D6CDF'
                    />
                    <Text
                        fontSize='14px'
                        marginInlineStart='0 !important'
                        letterSpacing='0 !important'
                        // {...handleActiveStyle(item.confirmType == 1)}
                        color='danger.100'
                    >
                        {getI18NText("立即确认")}
                    </Text>
                </HStack>
                <Box
                    w='112px'
                    ml='68.25px !important'
                >
                    <Flex
                        alignItems='baseline'
                    >
                        <Text
                            fontSize='12px'
                            marginInlineStart='0 !important'
                            color='fontColor'
                            letterSpacing='0 !important'
                        >
                            ¥
                        </Text>
                        <Text
                            fontSize='24px'
                            color='blue'
                            fontWeight='500'
                            marginInlineStart='0 !important'
                            letterSpacing='0 !important'
                        >
                            {getAveragePrice(item.dailyPrice)}
                        </Text>
                    </Flex>
                    <Flex
                        alignItems='baseline'
                    >
                        <Text
                            fontSize='12px'
                            marginInlineStart='0 !important'
                            color='fontColor'
                            letterSpacing='0 !important'
                        >
                            {getI18NText("总价")}  ¥
                        </Text>
                        <Text
                            fontSize='14px'
                            marginInlineStart='0 !important'
                            color='gray.100'
                            fontWeight='500'
                            letterSpacing='0 !important'
                        >
                            {item.price}
                        </Text>
                    </Flex>
                </Box>
                <Button
                    w='170px'
                    h='44px'
                    lineHeight='44px'
                    textAlign='center'
                    fontSize='18px'
                    color='white'
                    bgColor='blue'
                    borderRadius='4px'
                    ml='68.25px !important'
                    letterSpacing='0 !important'
                    onClick={(e)=> onNavigate({
                        hotelId: item.hotelId,
                        rateId: item.rateId,
                        roomId: item.roomId
                    })}
                    _hover={{
                        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #002C5E'
                    }}
                >
                    {getI18NText("抢先预定")}
                </Button>
            </HStack>
        )
    }
    return (
        <Box
            key={key}
            bgColor='white'
            borderRadius='4px !important'
            overflow='hidden'
            filter='drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.15))'
        >
            <Flex
                justifyContent='space-between'
                bgColor='white'
                alignItems='center'
            >
                <Image
                    src={img}
                    w='200px'
                    h='140px'
                />
                <Box
                    flex='1'
                    pl='27px'
                >
                    <Text
                        fontSize='28px'
                        fontWeight='500'
                        color='blue'
                        lineHeight='33.6px'
                        letterSpacing='0 !important'
                    >
                        {data.name.zh_CN}
                    </Text>
                    <Text
                        fontSize='16px'
                        fontWeight='400'
                        color='blue'
                        lineHeight='22.4px'
                        letterSpacing='0 !important'
                    >
                        {data.name.en}
                    </Text>
                </Box>
            </Flex>
            {
                data.showMore ? (
                    <Box>
                        {
                            data.list.map((item:any)=>
                                renderItem(item)
                            )
                        }
                    </Box>
                ) : (
                    renderItem(data.list[0])
                )
            }
            <Divider orientation='horizontal' h='1px' color='#002C5E' />
            {
                data.list.length > 1 &&
                <Center
                    h='32px'
                    fontSize='14px'
                    color='blue'
                    cursor='pointer'
                    onClick={(e)=> onShow({id: data.roomId})}
                >
                    {data.showMore ? getI18NText('收起所有报价') : getI18NText('查看所有报价')}
                </Center>
            }
        </Box>
    )
}