import { CancelPolicy } from '~/types';

import compareAsc from 'date-fns/compareAsc';
import parseISO from 'date-fns/parseISO';
import formatISO from 'date-fns/formatISO';

export default (cancelPolicies: CancelPolicy[]) => {
  const cancelPoliciesShallowed = [...cancelPolicies];
  cancelPoliciesShallowed.sort(({ endAt: endAtA }, { endAt: endAtB }) =>
    compareAsc(parseISO(endAtA), parseISO(endAtB)),
  );

  const lastestCancelPolicy = cancelPoliciesShallowed?.[cancelPolicies.length - 1];
  // console.log('lastestCancelPolicy', lastestCancelPolicy);
  if (lastestCancelPolicy && compareAsc(parseISO(lastestCancelPolicy.endAt), new Date()) < 0) {
    cancelPoliciesShallowed[cancelPoliciesShallowed.length - 1].endAt = formatISO(new Date());
  }

  return cancelPoliciesShallowed;
};
