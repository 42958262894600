import { Flex, Box, FlexProps, useDisclosure, BoxProps } from '@chakra-ui/react';

import Content from './Content';
import Header from '~/containers/Header';
import Footer from '~/containers/Footer';
import WechatPay from '~/components/WechatPay';
import PayTip from '~/components/PayTip';
import ChangePwdSuccess from '~/components/ChangePwdSuccess';
import ChangeUserInfoSuccess from '~/components/ChangeUserInfoSuccess';
import LoginOut from '~/components/LoginOut';
import modalControlAtom from '~/recoil/atom/modal';
import payControlAtom from '~/recoil/atom/pay';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import useAuthingClient from '~/hooks/useAuthingClient';

type PageContainerProps = {
  children?: React.ReactNode;
  containerStyles?: FlexProps;
  topBoxStyle?: BoxProps;
  hideHeader?: boolean;
  hideFooter?: boolean;
  afterHeaderComponent?: React.ReactNode;
};

export default function PageContainer({
  children,
  containerStyles = {},
  topBoxStyle = {},
  hideFooter = false,
  hideHeader = false,
  afterHeaderComponent = null,
}: PageContainerProps) {
  const {
    login,
    logout,
    // getIdToken
  } = useAuthingClient();
  const navigate = useNavigate();
  const [
    {
      isWechatPayModalOpen,
      isPayTipModalOpen,
      isLoginOutModalOpen,
      isSureChangePwdModal,
      isSureChangeUserInfoModal,
    },
    setModalControl,
  ] = useRecoilState(modalControlAtom);
  const [{ paymentURL, payOrderNo, payPrice }, setPayControl] = useRecoilState(payControlAtom);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleWechatPayModalOpen = () => {
    setModalControl((prev) => ({ ...prev, isWechatPayModalOpen: false }));
  };

  const handlePayTipModalOpen = () => {
    setModalControl((prev) => ({ ...prev, isPayTipModalOpen: false }));
  };

  const handleLoginOutModalOpen = () => {
    setModalControl((prev) => ({ ...prev, isLoginOutModalOpen: false }));
  };

  const handleSureChangePwdModal = () => {
    logout();
    setModalControl((prev) => ({ ...prev, isSureChangePwdModal: false }));
  };

  const handleSureChangeUserInfoModal = () => {
    setModalControl((prev) => ({ ...prev, isSureChangeUserInfoModal: false }));
  };

  const handleLoginOut = () => {
    logout();
    setModalControl((prev) => ({ ...prev, isLoginOutModalOpen: false }));
  };

  return (
    <Flex
      minW="1140px"
      direction="column"
      align="stretch"
      bg="#FAFAFA"
      minH="100vh"
      {...containerStyles}
    >
      <Box pos="sticky" top={0} zIndex={100} {...topBoxStyle}>
        {!hideHeader && <Header />}
        {afterHeaderComponent}
      </Box>
      <Box as="main" flex="1" w="1440px" mx="auto !important">
        {children}
      </Box>
      {!hideFooter && <Footer />}
      <>
        <WechatPay
          isOpen={isWechatPayModalOpen}
          onClose={handleWechatPayModalOpen}
          paymentURL={paymentURL}
          payOrderNo={payOrderNo}
          payPrice={payPrice}
        />
        <PayTip isOpen={isPayTipModalOpen} onClose={handlePayTipModalOpen} />
        <ChangeUserInfoSuccess
          isOpen={isSureChangeUserInfoModal}
          onClose={handleSureChangeUserInfoModal}
        />
        <ChangePwdSuccess isOpen={isSureChangePwdModal} onClose={handleSureChangePwdModal} />
        {/* <EmptyLocationTip isOpen={isEmptyLocationModalOpen} onClose={handleEmptyLocationModalOpen} /> */}
        <LoginOut
          isOpen={isLoginOutModalOpen}
          onClose={handleLoginOutModalOpen}
          onLoginOut={handleLoginOut}
        />
      </>
    </Flex>
  );
}
