import React from 'react';
import { Center } from '@chakra-ui/react';

import baseStyle from '../styles/baseStyle';

type GrayDayProps = {
  day: number;
};

const GrayDay: React.FC<GrayDayProps> = ({ day }) => {
  return (
    <Center {...baseStyle} color="#E3E3E3">
      {day}
    </Center>
  );
};

export default GrayDay;
