// import { useState } from 'react';
import {
    Box,
    Divider,
    HStack,
    Input,
    Flex,
    Stack,
    Text,
    Image,
    FormControl,
    FormErrorMessage
} from '@chakra-ui/react';
import {
    FullName
} from '~/types';
import addIcon from '~/assets/images/addIcon.png';
import closeIcon from '~/assets/images/close.png';

import { useI18NText } from '~/i18n/i18n';

type CheckInInformationProps = {
    title?: string,
    subTitle?: string,
    isEdit?: boolean,
    guestByRoom?: Array<Array<FullName>>,
    onChangePerson?: any,
    onChangeGuest?: any,
    isPay?: boolean,
};

export default function CheckInInformation({
    title = '',
    subTitle = '',
    isPay = false,
    guestByRoom,
    isEdit = false,
    onChangePerson,
    onChangeGuest
} :CheckInInformationProps ) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })

    const changeGuest = (value:number, key:number, type:string, event:any) => {
        onChangePerson({
            value,
            key,
            type
        })
    }
    const changeValue = (type: string, key: number, val: number, event: any) => {
        console.log('event', event);
        let value = event.target.value;
        if (type == 'lastName' || type == 'firstName') {
            // value = value.replace(/[^a-zA-Z]/g,'')
            value = value.replace(/[\d]/g,'');
        }
        onChangeGuest({
            type: type,
            key: key,
            subKey: val,
            value: value
        })
    }
    return (
        <Box>
            <Flex
                h='44px'
                borderRadius='4px'
                pl='16px'
                pr='25px'
                mb='8px'
                bgColor='#EBEEF5'
                alignItems='center'
            >
                <Text
                    fontWeight='500'
                    fontSize='22px'
                    color='blue'
                    letterSpacing='0 !important'
                >
                    {getI18NText('入住信息')}
                </Text>
                <Text
                    ml='10px'
                    fontSize='12px'
                    color='gray.800'
                    letterSpacing='0 !important'
                >
                    {getI18NText('每间至少填写1位成人姓名，所填姓名需与居住时所持证件一致。')}
                </Text>
            </Flex>
            <HStack
                p='24px'
                display='block'
                as='div'
                borderRadius='4px'
                bgColor='white'
            >
                {
                    isEdit ? (
                        <Text
                            fontSize='12px'
                            color='danger.100'
                            h='40px'
                            lineHeight='40px'
                            bgColor='rgba(255, 103, 112, 0.2)'
                            pl='10px'
                            mb='16px'
                            letterSpacing='0 !important'
                        >
                            {getI18NText('请仔细核对入住人姓名，若与实际入住人不符，可能会导致加费或无法入住。')}
                        </Text>
                    ) : null
                }
                <Stack
                    spacing='12px'
                    marginInlineStart='0 !important'
                >
                    {
                        guestByRoom && guestByRoom.map((item, index)=>
                            <Box
                                key={index}
                                marginInlineStart='0 !important'
                            >
                                <Text
                                    fontSize='18px'
                                    lineHeight='25.2px'
                                    color='dark'
                                    mb='4px'
                                    letterSpacing='0 !important'
                                    marginInlineStart='0 !important'
                                >
                                    {getI18NText('房间')}{index + 1}
                                </Text>
                                {
                                    item.map((json, key) => 
                                        <Flex
                                            marginInlineStart='0 !important'
                                            key={key}
                                            pos='relative'
                                            mb='20px'
                                        >
                                            <Box
                                                w='275px'
                                                pr={isEdit ? '15px' : 0}
                                            >
                                                <HStack>
                                                    <Text
                                                        fontSize='14px'
                                                        lineHeight='19.6px'
                                                        mb='4px'
                                                        color={isEdit ? 'dark' : 'gray.800'}
                                                        letterSpacing='0 !important'
                                                    >
                                                        {getI18NText('名')}
                                                    </Text>
                                                    {
                                                    isEdit &&
                                                        <Text
                                                            fontSize='14px'
                                                            lineHeight='19.6px'
                                                            mb='4px !important'
                                                            color='danger.100'
                                                            letterSpacing='0 !important'
                                                            marginInlineStart='0 !important'
                                                        >
                                                            *
                                                        </Text>
                                                    }
                                                </HStack>
                                                {
                                                    isEdit ? (
                                                        <FormControl
                                                            w='260px'
                                                            isInvalid={isPay && json.lastName === ''}
                                                        >
                                                            <Input
                                                                w='260px'
                                                                h='40px'
                                                                color='gray.800'
                                                                placeholder={`${getI18NText('拼音 例')}: Fang`}
                                                                fontSize='14px'
                                                                value={json.lastName}
                                                                onChange={(e) => changeValue('lastName', index, key, e)}
                                                            />
                                                            {
                                                                (isPay && json.lastName === '') &&
                                                                <FormErrorMessage>{getI18NText("请输入正确信息")}</FormErrorMessage>
                                                            }
                                                        </FormControl>
                                                    ) : (
                                                        <Text
                                                            color='blue'
                                                            fontSize='14px'
                                                            lineHeight='19.6px'
                                                            letterSpacing='0 !important'
                                                        >
                                                            {json.lastName}
                                                        </Text>
                                                    )
                                                }
                                            </Box>
                                            <Box
                                                w='275px'
                                                pr={isEdit ? '15px' : 0}
                                            >
                                                <HStack>
                                                    <Text
                                                        fontSize='14px'
                                                        lineHeight='19.6px'
                                                        mb='4px'
                                                        color={isEdit ? 'dark' : 'gray.800'}
                                                        letterSpacing='0 !important'
                                                    >
                                                        {getI18NText('姓')}
                                                    </Text>
                                                    {
                                                    isEdit &&
                                                        <Text
                                                            fontSize='14px'
                                                            lineHeight='19.6px'
                                                            mb='4px !important'
                                                            color='danger.100'
                                                            letterSpacing='0 !important'
                                                            marginInlineStart='0 !important'
                                                        >
                                                            *
                                                        </Text>
                                                    }
                                                </HStack>
                                                {
                                                    isEdit ? (
                                                        <FormControl
                                                            w='260px'
                                                            isInvalid={isPay && json.firstName === ''}
                                                        >
                                                            <Input
                                                                w='260px'
                                                                h='40px'
                                                                color='gray.800'
                                                                placeholder={`${getI18NText('拼音 例')}: Fang`}
                                                                fontSize='14px'
                                                                value={json.firstName}
                                                                onChange={(e) => changeValue('firstName', index, key, e)}
                                                            />
                                                            {
                                                                (isPay && json.firstName === '') &&
                                                                <FormErrorMessage>{getI18NText("请输入正确信息")}</FormErrorMessage>
                                                            }
                                                        </FormControl>
                                                    ) : (
                                                        <Text
                                                            color='blue'
                                                            fontSize='14px'
                                                            lineHeight='19.6px'
                                                            letterSpacing='0 !important'
                                                        >
                                                            {json.firstName}
                                                        </Text>
                                                    )
                                                }
                                            </Box>
                                            {/* <Box
                                                flex='1'
                                                pos='relative'
                                            >
                                                <Text
                                                    fontSize='14px'
                                                    lineHeight='19.6px'
                                                    mb='4px'
                                                    color={isEdit ? 'dark' : 'gray.800'}
                                                    letterSpacing='0 !important'
                                                >
                                                    {getI18NText('国籍')}
                                                </Text>
                                                <Text
                                                    color='blue'
                                                    fontSize='14px'
                                                    lineHeight={isEdit ? '48px' : '19.6px'}
                                                    letterSpacing='0 !important'
                                                >
                                                    {getI18NText('中国大陆')}
                                                </Text>
                                                {
                                                    isEdit && 
                                                    <Image
                                                        src={addIcon}
                                                        w='20px'
                                                        h='20px'
                                                        pos='absolute'
                                                        right='0'
                                                        top='36.8px'
                                                        cursor='pointer'
                                                        onClick={(e) => AddGuest(index, key, e)}
                                                    />
                                                }
                                            </Box> */}
                                            {
                                                (isEdit && item.length < 2) && 
                                                <Image
                                                    src={addIcon}
                                                    w='20px'
                                                    h='20px'
                                                    pos='absolute'
                                                    right='0'
                                                    top='36.8px'
                                                    cursor='pointer'
                                                    onClick={(e) => changeGuest(index, key, 'add', e)}
                                                />
                                            }
                                            {
                                                (isEdit && item.length == 2) && 
                                                <Image
                                                    src={closeIcon}
                                                    w='20px'
                                                    h='20px'
                                                    pos='absolute'
                                                    right='0'
                                                    top='36.8px'
                                                    cursor='pointer'
                                                    onClick={(e) => changeGuest(index, key, 'reduce', e)}
                                                />
                                            }
                                        </Flex>
                                    )
                                }
                                <Divider orientation='horizontal' bgColor='#F2F2F2' />
                            </Box>
                        )
                    }
                </Stack>
            </HStack>
        </Box>
    )
}