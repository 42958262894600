import React from 'react';
import {
  Button,
  Center,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  keyframes,
  Flex
} from '@chakra-ui/react';

import type { BoxProps } from '@chakra-ui/react';
import TipIcon from '~/assets/images/tipIcon.png';

import { useI18NText } from '~/i18n/i18n';

const flashing = keyframes`
    0% { opacity:1 }
    80% { opacity:0.3 }
    100% { opacity:1 }
`;

type LoadingModalProps = {
  isOpen: boolean;
  onSubmit: any,
  onClose: any
};

export default function LoadingModal({ isOpen, onSubmit, onClose }: LoadingModalProps) {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const pointBaseStyle: BoxProps = {
    position: 'absolute',
    bg: 'trip.primary',
    h: 4,
    w: 4,
    borderRadius: 'full',
  };

  const armBaseStyle: BoxProps = {
    position: 'absolute',
    w: 4,
    h: 10,
    bottom: 0,
    transformOrigin: '50% 100%',
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody
          py='32px'
          w='451px'
        >
          <Center>
            <Image
              src={TipIcon}
              w='36px'
              h='36px'
              mb='8px'
            />
          </Center>
          <Center
            mb='16px'
          >
            <Text
              color='blue'
              fontSize='22px'
              fontWeight='500'
              h='29px'
              lineHeight='29px'
            >
              {getI18NText('确认取消')}?
            </Text>
          </Center>
          <Center
            mb='32px'
          >
            <Text
              fontSize='14px'
              fontWeight='normal'
              color='#333333'
            >
              确认取消此笔订单，确认后将依照取消政策进行退款
            </Text>
          </Center>
          <Flex
            justifyContent='center'
            alignContent='center'
          >
            <Button
                w='96px'
                h='48px'
                lineHeight='48px'
                bgColor='#F0D43A'   
                textAlign='center'
                color='#002C5E'
                fontSize='14px'
                _hover={{
                    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #F0D43A'
                }}
                onClick={onSubmit}
            >
                {getI18NText('确认取消')}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
