import axios, { AxiosError } from 'axios';
import { HotelPrice, PriorityOption, HotelList } from '~/types';

type GetHotelListRatesRequest = {
  token: string;
  keyword: string;
  sortingWay: PriorityOption;
  checkIn: string;
  checkOut: string;
  stars: number[];
  priceRange?: {
    min?: number;
    max?: number;
  };
  page?: number;
  size?: number;
  group: {
    adults: number;
    children: number[];
  };
  onlyCity: string;
};

const PAGE_SIZE = 15;

export default async ({
  token,
  keyword,
  sortingWay,
  stars,
  checkIn,
  checkOut,
  priceRange,
  page = 0,
  size = PAGE_SIZE,
  group,
  onlyCity = ''
}: GetHotelListRatesRequest): Promise<HotelList> => {
  try {
    const hotelListRatesResponse = await axios.post(
      `${process.env.REACT_APP_AWS_HTTP_BASE_URL}/hotel/list`,
      {
        keyword,
        sortingWay,
        stars,
        checkIn,
        checkOut,
        priceRange,
        page,
        size,
        group,
        onlyCity
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: 8 * 1000,
      },
    );

    return {
      hotels: hotelListRatesResponse.data?.hotels || [],
      totalCount: hotelListRatesResponse.data?.totalCount || 0,
      totalPage: hotelListRatesResponse.data?.totalPage || 0,
    }
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;

    bookingFailedError.response
      ? console.error('Request failed for get booking api: ', bookingFailedError.response.data)
      : console.error('Request failed for get booking api: ', error);

    return {
      hotels: [],
      totalCount: 0,
      totalPage: 0,
    };
  }
};
