import { ComponentStyleConfig } from '@chakra-ui/react';
import Button from './Button';
// import Input from './Input';
import Radio from './Radio';
import Text from './Text';
import Heading from './Heading';
import Checkbox from './Checkbox';

const components: Record<string, ComponentStyleConfig> = {
  Button,
  // Input,
  Radio,
  Text,
  Heading,
  Checkbox,
};

export default components;
