import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, Text, keyframes } from '@chakra-ui/react';

import type { BoxProps } from '@chakra-ui/react';
import { useI18NText } from '~/i18n/i18n';

const flashing = keyframes`
    0% { opacity:1 }
    80% { opacity:0.3 }
    100% { opacity:1 }
`;

type LoadingModalProps = {
  isOpen: boolean;
};

export default function LoadingModal({ isOpen }: LoadingModalProps) {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const pointBaseStyle: BoxProps = {
    position: 'absolute',
    bg: 'trip.primary',
    h: 4,
    w: 4,
    borderRadius: 'full',
  };

  const armBaseStyle: BoxProps = {
    position: 'absolute',
    w: 4,
    h: 10,
    bottom: 0,
    transformOrigin: '50% 100%',
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalOverlay />
      <ModalContent bg="white">
        <ModalBody>
          {/* {Array.from({ length: 9 }, (_, index) => (
            <Box
              {...armBaseStyle}
              key={index}
              transform={`translateX(-50%) rotateZ(${40 * index}deg)`}
            >
              <Box
                {...pointBaseStyle}
                animation={`${flashing} infinite 1.8s linear ${index * 200}ms`}
              />
            </Box>
          ))} */}
          <Text color='blue' fontSize='18px' fontWeight='500' textAlign='center'>
            {getI18NText("页面加载中")}...
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
