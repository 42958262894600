import {
    Box,
    Divider,
    Flex,
    HStack,
    Image,
    Text,
} from '@chakra-ui/react';
import hotel from '~/assets/images/hotel.png';
import bedIcon from '~/assets/images/bed.png';
import tableware from '~/assets/images/tableware.png';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';

import { useI18NText } from '~/i18n/i18n';
import {
    Lang
} from '~/types'


// type HotelInfoContainerProps = {
//     type?: string,
//     image?: string,
//     bedInfo?: any,
//     hotelDetail?: any,
//     checkIn?: any,
//     checkOut?: any,
//     adultCount?: number,
//     occupancy?: number,
//     hasBreakfast?: boolean
//     roomName?: Lang
// }
type HotelInfoContainerProps = {
    hotelId?: string,
    image?: string,
    hotelName?: string,
    address?: string,
    roomName?: any,
    bedInfo?: any,
    hasBreakfast?: boolean,
    checkIn?: any,
    checkOut?: any,
    num?: number
}
export default function HotelInfoContainer({
    hotelId,
    image,
    hotelName,
    address,
    roomName,
    bedInfo,
    hasBreakfast,
    checkIn,
    checkOut,
    num,
}: HotelInfoContainerProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })
    const formatTime = `yyyy${getI18NText('年')}MM${getI18NText('月')}dd${getI18NText('日')}`

    const navigate = useNavigate();
    const dateStart = new Date(checkIn);
    const dateEnd = new Date(checkOut);
    let difValue = 0;
    if (dateEnd && dateStart) {
        difValue = (Number(dateEnd) - Number(dateStart)) / (1000 * 60 * 60 * 24);
    }
    return (
        <HStack
            display='block'
            as='div'
            bgColor='white'
            borderRadius='4px'
            overflow='hidden'
            filter='drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.15))'
        >
            <Image
                src={image}
                objectFit='cover'
                w='338px'
                h='162px'
                borderRadius='4px 4px 0 0'
            />
            <HStack
                display='block'
                as='div'
                p='12px'
            >
                <Flex
                    marginInlineStart='0 !important'
                    alignItems='baseline'
                    px='5px'
                >
                    <Text
                        fontSize='22px'
                        fontWeight='500'
                        color='fontColor'
                        mr='8px'
                        letterSpacing='0 !important'
                        w='234px'
                    >
                        {hotelName}
                    </Text>
                    <Text
                        fontSize='14px'
                        w='58px'
                        color='#003E82'
                        letterSpacing='0 !important'
                        cursor='pointer'
                        onClick={(e)=> navigate(`/hotel/${hotelId}?rooms=1&start=${format(new Date(checkIn), 'yyyy-MM-dd')}&end=${format(new Date(checkOut), 'yyyy-MM-dd')}&adults=${num}`)}
                    >
                        {getI18NText('酒店详情')}
                    </Text>
                </Flex>
                <Text
                    fontSize='14px'
                    color='gray.800'
                    lineHeight='19.6px'
                    marginInlineStart='0 !important'
                    letterSpacing='0 !important'
                    px='5px'
                >
                    {address}
                </Text>
                <Divider
                    orientation='horizontal' 
                    bgColor='#F2F2F2'
                    marginInlineStart='0 !important'
                    letterSpacing='0 !important'
                    w='304px'
                    px='auto'
                />
                <Box
                    pt='10.5px'
                    px='5px'
                    marginInlineStart='0 !important'
                >
                    <Flex
                        marginInlineStart='0 !important'
                        mb='2px'
                        alignItems='center'
                    >
                        <Image
                            src={hotel}
                            w='24px'
                            h='24px'
                            mr='12px'
                        />
                        <Text
                            fontSize='18px'
                            color='fontColor'
                            fontWeight='500'
                            letterSpacing='0 !important'
                        >
                            {roomName}
                        </Text>
                    </Flex>
                    <Flex
                        marginInlineStart='0 !important'
                        mb='2px'
                        alignItems='center'
                    >
                        <Image
                            src={bedIcon}
                            w='24px'
                            h='24px'
                            mr='12px'
                        />
                        <Text
                            fontSize='14px'
                            color='fontColor'
                            fontWeight='400'
                            letterSpacing='0 !important'
                        >
                            {bedInfo.count} {bedInfo.desc}  
                        </Text>
                    </Flex>
                    <Flex
                        marginInlineStart='0 !important'
                        alignItems='center'
                        mb='12px'
                    >
                        <Image
                            src={tableware}
                            w='24px'
                            h='24px'
                            mr='12px'
                        />
                        <Text
                            fontSize='14px'
                            color='fontColor'
                            fontWeight='400'
                            letterSpacing='0 !important'
                        >
                            {hasBreakfast ? getI18NText('含早餐') : getI18NText('不含早餐')}
                        </Text>
                    </Flex>
                </Box>
                <Divider
                    orientation='horizontal' 
                    variant='dashed'
                    bgColor='#F2F2F2'
                    marginInlineStart='0 !important'
                />
                <Box
                    pt='16px'
                    pr='11px'
                    pl='9px'
                    marginInlineStart='0 !important'
                >
                    <HStack
                        mb='12px'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Text
                            fontSize='14px'
                            color='gray.800'
                            letterSpacing='0 !important'
                        >
                            {getI18NText('入住日期')}
                        </Text>
                        {
                            checkIn &&
                            <Text
                                fontSize='14px'
                                color='fontColor'
                                letterSpacing='0 !important'
                            >
                                {format(new Date(checkIn), formatTime)}
                            </Text>
                        }
                    </HStack>
                    <HStack
                        mb='12px'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Text
                            fontSize='14px'
                            color='gray.800'
                            letterSpacing='0 !important'
                        >
                            {getI18NText('离店日期')}
                        </Text>
                        {
                            checkOut &&
                            <Text
                                fontSize='14px'
                                color='fontColor'
                                letterSpacing='0 !important'
                            >
                                {format(new Date(checkOut), formatTime)}
                            </Text>
                        }
                    </HStack>
                    <HStack
                        mb='12px'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Text
                            fontSize='14px'
                            color='gray.800'
                            letterSpacing='0 !important'
                        >
                            {getI18NText('入住时间')}
                        </Text>
                        <HStack
                            flex='1'
                        >
                            <Text
                                fontSize='14px'
                                color='gray.800'
                                w='167px'
                                textAlign='right'
                                letterSpacing='0 !important'
                            >
                                {getI18NText('共')}
                            </Text>
                            <Text
                                fontSize='14px'
                                color='fontColor'
                                flex='1'
                                textAlign='right'
                                letterSpacing='0 !important'
                            >
                                {difValue} {getI18NText('晚')} 
                            </Text>
                        </HStack>
                    </HStack>
                    <HStack
                        mb='12px'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Text
                            fontSize='14px'
                            color='gray.800'
                            letterSpacing='0 !important'
                        >
                            {getI18NText('入住人数')}
                        </Text>
                        <HStack
                            flex='1'
                        >
                            <Text
                                fontSize='14px'
                                color='gray.800'
                                w='167px'
                                textAlign='right'
                                letterSpacing='0 !important'
                            >
                                {getI18NText('共')}
                            </Text>
                            <Text
                                fontSize='14px'
                                color='fontColor'
                                flex='1'
                                textAlign='right'
                                letterSpacing='0 !important'
                            >
                                {num} {getI18NText('人')} 
                            </Text>
                        </HStack>
                    </HStack>
                </Box>
            </HStack>
        </HStack>
    )
}