import React, { useState, useMemo } from 'react';
import { Box, Flex, Icon, Text, Image } from '@chakra-ui/react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

import { getYear, getMonth, addMonths, isSameYear, differenceInMonths } from 'date-fns';

import Week from './components/Week';
import Calendar from './components/Calendar';

import type { CalendarDate } from './type';
import leftIcon from '~/assets/images/left.png';
import rightIcon from '~/assets/images/right.png';

import { useI18NText } from '~/i18n/i18n';

type DesktopProps = {
  showBefore?: boolean;
  rangeDate: CalendarDate;
  onSetRangeDate: (rangeDate: CalendarDate) => void;
};

const Desktop: React.FC<DesktopProps> = ({ showBefore = false, rangeDate, onSetRangeDate }) => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })
  // 12 months
  const months = Array.from({ length: 12 }, (_, i) => `${i + 1}${getI18NText('月')}`);

  const [date, setDate] = useState(new Date());

  // arrow clickable
  const [isLeftArrowClickable, isRightArrowClickable] = useMemo(() => {
    const currentMonth = getMonth(new Date());
    const diff = differenceInMonths(date, new Date());

    if (currentMonth === getMonth(date) && isSameYear(new Date(), date)) {
      return [false, true];
    }

    if (diff === 10) {
      return [true, false];
    }

    return [true, true];
  }, [date]);

  const handlePreviousMonth = () => {
    if (!showBefore && !isLeftArrowClickable) return;
    setDate((prev) => addMonths(prev, -1));
  };

  const handleNextMonth = () => {
    if (!isRightArrowClickable) return;
    setDate((prev) => addMonths(prev, 1));
  };

  const nextMonthDate = addMonths(date, 1);

  const monthTitle = `${getYear(date)} ${months[getMonth(date)]}`;

  const nextMonthTitle = `${getYear(nextMonthDate)} ${months[getMonth(nextMonthDate)]}`;
  return (
    <Flex
      py='28px'
      justifyContent='space-between'
    >
      <Box>
        <Flex alignItems="center">
          <Image
            src={leftIcon}
            w='30px'
            h='32.7px'
            onClick={handlePreviousMonth}
            cursor='pointer'
          />
          <Text flex="1" textAlign="center">
            {monthTitle}
          </Text>
        </Flex>
        <Week />
        <Calendar
          showBefore={showBefore}
          year={getYear(date)}
          month={getMonth(date) + 1}
          rangeDate={rangeDate}
          onSetRangeDate={onSetRangeDate}
        />
      </Box>
      <Box>
        <Flex alignItems="center">
          <Text flex="1" textAlign="center">
            {nextMonthTitle}
          </Text>
          <Image
            src={rightIcon}
            w='30px'
            h='32.7px'
            onClick={handleNextMonth}
            cursor='pointer'
          />
        </Flex>
        <Week />
        <Calendar
          showBefore={showBefore}
          year={getYear(nextMonthDate)}
          month={getMonth(nextMonthDate) + 1}
          rangeDate={rangeDate}
          onSetRangeDate={onSetRangeDate}
        />
      </Box>
    </Flex>
  );
};

export default Desktop;
