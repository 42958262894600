import {
    Button,
    Center,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Text,
    Flex
} from '@chakra-ui/react';
import TipIcon from '~/assets/images/tipIcon.png';

import { useI18NText } from '~/i18n/i18n';

type LoginOutProps = {
  isOpen: boolean;
  onClose: () => void;
  onLoginOut: () => void;
};
// TODO: error message 介面優化
export default function LoginOut({ isOpen, onClose, onLoginOut }: LoginOutProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="sm"
            isCentered
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody
                    py='32px'
                >
                    <Center>
                        <Image
                            src={TipIcon}
                            w='36px'
                            h='36px'
                            mb='8px'
                        />
                    </Center>
                    <Center>
                        <Text
                            color='blue'
                            fontSize='22px'
                            fontWeight='500'
                            h='29px'
                            lineHeight='29px'
                            mb='16px'
                        >
                            {getI18NText('确认登出')}?
                        </Text>
                    </Center>
                    <Flex
                        justifyContent='center'
                        alignContent='center'
                    >
                        <Button
                            w='96px'
                            h='48px'
                            lineHeight='48px'
                            bgColor='white'   
                            textAlign='center'
                            color='blue'
                            borderWidth='2px'
                            borderColor='blue'
                            fontSize='14px'
                            mr='16px'
                            _hover={{
                                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #ffffff'
                            }}
                            onClick={onClose}
                        >
                            {getI18NText('取消返回')}
                        </Button>
                        <Button
                            w='96px'
                            h='48px'
                            lineHeight='48px'
                            bgColor='blue'   
                            textAlign='center'
                            color='white'
                            fontSize='14px'
                            _hover={{
                                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #002C5E'
                            }}
                            onClick={onLoginOut}
                        >
                            {getI18NText('确认登出')}
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
