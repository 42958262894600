import React from 'react';

import { HStack, Text, Badge } from '@chakra-ui/react';

import type { TextProps } from '@chakra-ui/react';
import { BookingStatus, OrderType } from '~/types';

import { useI18NText } from '~/i18n/i18n';

const orderTypeStyle: {
  [key in OrderType]?: { label: string; style: TextProps };
} = {
  [OrderType.UNPAID]: {
    label: '待付款',
    style: {
      color: '#FF6770',
      bg: '#FFE9EA',
      marginInlineStart: '0 !important',
      letterSpacing: '0 !important',
    },
  },
  [OrderType.UNCONFIRMED]: {
    label: '待确认',
    style: {
      color: '#FF6770',
      bg: '#FFE9EA',
      marginInlineStart: '0 !important',
      letterSpacing: '0 !important',
    },
  },
  [OrderType.CONFIRMED]: {
    label: '已确认',
    style: {
      color: '#41C7AF',
      bg: '#DFF5F1',
      marginInlineStart: '0 !important',
      letterSpacing: '0 !important',
    },
  },
  [OrderType.FAIL]: {
    label: '预定失败',
    style: {
      color: '#939393',
      bg: '#E9E9E9',
      marginInlineStart: '0 !important',
      letterSpacing: '0 !important',
    },
  },
  [OrderType.CANCELED]: {
    label: '已取消',
    style: {
      color: '#939393',
      bg: '#E9E9E9',
      marginInlineStart: '0 !important',
      letterSpacing: '0 !important',
    },
  },
  [OrderType.COMPLETED]: {
    label: '已出行',
    style: {
      color: '#41C7AF',
      bg: '#DFF5F1',
      marginInlineStart: '0 !important',
      letterSpacing: '0 !important',
    },
  },
  [OrderType.EXPIRED]: {
    label: '已失效',
    style: {
      color: '#939393',
      bg: '#E9E9E9',
      marginInlineStart: '0 !important',
      letterSpacing: '0 !important',
    },
  },
};

type OrderStatusLabelProps = {
  status: BookingStatus;
};

export default function OrderStatusLabel({ status }: OrderStatusLabelProps) {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  const textBaseStyle: TextProps = {
    fontWeight: 'bold',
    borderRadius: 'full',
    minW: 24,
    textAlign: 'center',
    px: 2,
    py: 1,
  };

  let selectedStatus: OrderType;
  switch (status) {
    case BookingStatus.PENDING:
      selectedStatus = OrderType.UNPAID;
      break;
    case BookingStatus.PROCESSING:
    case BookingStatus.IN_BOOKING:
      selectedStatus = OrderType.UNCONFIRMED;
      break;
    case BookingStatus.SUCCESS:
      selectedStatus = OrderType.CONFIRMED;
      break;
    case BookingStatus.FAILED:
      selectedStatus = OrderType.FAIL;
      break;
    case BookingStatus.INVALID:
      selectedStatus = OrderType.EXPIRED;
      break;
    case BookingStatus.CANCELLING:
      selectedStatus = OrderType.CANCELED;
      break;
    case BookingStatus.CANCELLED:
      selectedStatus = OrderType.CANCELED;
      break;
    default:
      return <></>;
  }
  const { style = {}, label = '' } = orderTypeStyle[selectedStatus] || {};
  return (
    <HStack
      as='div'
      {...textBaseStyle} {...style}
      paddingInline='0 !important'
      w='108px !important'
    >
      <Badge
          w='10px'
          h='10px'
          bgColor={style.color}
          borderRadius='100%'
          mr='4px'
          ml='15px'
      ></Badge>
      <Text {...style}>{getI18NText(label)}</Text>
    </HStack>
  );
}
