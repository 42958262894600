import { Colors } from '@chakra-ui/react';

const colors: Colors = {
  primary: '#3B5940',
  primaryLight: '#DDEFE1',
  secondary: '#75917A',
  fontColor: '#333333',
  dark: '#000000',
  blue: '#002C5E',
  gray: {
    100: '#4A4A4A',
    200: '#F2F2F2',
    400: '#C4C4C4',
    700: '#D7D7D7',
    800: '#8B8B8B'
  },
  danger: {
    100: '#FF6770',
    200: '#FFF4F4',
    300: '#FCB4B4',
    600: '#E83B3B',
  },
  trip: {
    primary: '#002C5E',
    primaryLight: 'rgba(0, 44, 94, 0.1)',
    primaryDisabled: '#8DADEA',
    primaryHover: '#2B6CE7',
    success: '#52C41A',
    successLight: '#FBFFFA',
    danger: '#FF4356',
    dangerLight: '#FFF4F5',
    dangerHover: '#FC142B',
    black: '#333333',
    gray: '#888888',
    grayLight: '#E3E3E3',
    background: '#F3F3F3',
  },
};

export default colors;
