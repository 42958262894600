import React, { useState } from 'react';
import {
    Button,
    Flex,
    HStack,
    Icon,
    Image,
    Text,
} from '@chakra-ui/react';
import {
    IoChevronDownOutline
} from 'react-icons/io5';
import { useSetRecoilState } from 'recoil';
import filiterControlAtom from '~/recoil/atom/filiter';
import PersonInfo from '../PersonInfo';
import UserIcon from '~/assets/images/user.png';
import SearchIcon from '~/assets/images/searchIcon.png';

import { useI18NText } from '~/i18n/i18n';

type PersonChooseProps = {
    adults?: number;
    rooms?: number;
    children?: number;
    onChange: any;
    onSearch: any,
    childAges: Array<Number>,
    showPerson?: boolean
}
export default function PersonChoose({
    adults = 0,
    rooms = 0,
    children = 0,
    childAges = [0],
    onChange,
    onSearch,
    showPerson = false,
}:PersonChooseProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' });
    const setFiliterControl = useSetRecoilState(filiterControlAtom);
    const showPersonChoose = (event:any) => {
        event.nativeEvent.stopImmediatePropagation();
        setFiliterControl((prev) => ({ ...prev, showPerson: true }));
        setFiliterControl((prev) => ({ ...prev, showDatePicker: false }));
        setFiliterControl((prev) => ({ ...prev, showLocation: false }));
    }
    const ChangePerson = (event:any) => {
        onChange(event)
    }
    const search = (event: any) => {
        onSearch();
    }
    return (
        <Flex
            cursor='pointer'
            justifyContent='space-between'
            alignItems='center'
        >
            <HStack
                position='relative'
                onClick={(e)=>showPersonChoose(e)}
            >
                <Image
                    src={UserIcon}
                    w='24px'
                    h='24px'
                    mr='16px'
                />
                <HStack
                    alignItems='center'
                >
                    <Text
                        fontSize='16px'
                        color='blue'
                        letterSpacing='0 !important'
                    >
                        {rooms}{getI18NText("间")} {adults}{getI18NText("成人")} {children}{getI18NText("儿童")}
                    </Text>
                    <Icon
                        as={IoChevronDownOutline}
                        boxSize='1rem'
                        color='gray.800'
                    />
                </HStack>
                {
                    showPerson &&
                    <PersonInfo
                        roomNum={rooms}
                        aldultNum={adults}
                        childNum={children}
                        childAges={childAges}
                        bottom='-200px'
                        onChange={(e:any)=>ChangePerson(e)}
                    />
                }
            </HStack>
            <Button
                fontSize='16px'
                bgColor='blue'
                color='white'
                borderRadius='4px'
                w='84px'
                h='40px'
                display='flex'
                alignItems='center'
                justifyContent='center'
                _hover={{
                    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #002C5E'
                }}
                onClick={(e)=> search(e)}
            >
                <Image
                    src={SearchIcon}
                    w='20px'
                    h='20px'
                    mr='6px'
                />
                {getI18NText('搜索')}
            </Button>
        </Flex>
    )
}