import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const Radio: ComponentSingleStyleConfig = {
  baseStyle: {
    control: {
      _checked: {
        bgColor: 'dark',
        borderWidth: '0.15rem',
        borderColor: 'white',
        boxShadow: '0px 0px 1px 1px #333',
        _before: {
          bgColor: 'dark',
        },
        _hover: {
          bgColor: 'dark',
          borderWidth: '0.15rem',
          borderColor: 'white',
          boxShadow: '0px 0px 1px 1px #333',
        },
      },
      _focus: {
        boxShadow: '0px 0px 1px 1px #333',
      },
    },
  },
};

export default Radio;
