import { useState } from 'react';
import { Box, Divider, Flex } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import modalControlAtom from '~/recoil/atom/modal';
import filiterControlAtom from '~/recoil/atom/filiter';
import { useNavigate, useLocation } from 'react-router-dom';
import formatDate from 'date-fns/format';
import addDays from 'date-fns/addDays';
import CheckInTime from '../CheckInTime';
import DateRangerPicker from '../DateRangerPicker';
import LocationChoose from '../LocationChoose';
import PersonChoose from '../PersonChoose';

type CommonHeaderProps = {
  location?: string;
  adults?: number;
  rooms?: number;
  children?: number;
  start?: string;
  end?: string;
  form?: string;
  onChangeTime?: any;
  onChangeLocation?: any;
  onChangePerson?: any;
  onSearchList?: any;
  childAges?: Array<Number>;
  showLocation?: boolean;
  showDatePicker?: boolean;
  showPerson?: boolean;
  hotelId?: any
};
export default function CommonHeader({
  location = '',
  adults = 0,
  rooms = 0,
  children = 0,
  start = '',
  end = '',
  form = '',
  onChangeTime,
  onChangeLocation,
  onChangePerson,
  onSearchList,
  childAges = [0],
  showLocation = false,
  showDatePicker = false,
  showPerson = false,
  hotelId = ''
}: CommonHeaderProps) {
  const navigate = useNavigate();
  const locations = useLocation();
  const setFiliterControl = useSetRecoilState(filiterControlAtom);
  const setModalControl = useSetRecoilState(modalControlAtom);
  start = start.replaceAll('-', '/');
  end = end.replaceAll('-', '/');
  const [showDate, setShowDate] = useState(false);
  const onChangeValue = (event: any) => {
    onChangeTime(event);
  };
  const showTimeChoose = (event: any) => {
    event.nativeEvent.stopImmediatePropagation();
    setFiliterControl((prev) => ({ ...prev, showLocation: false }));
    setFiliterControl((prev) => ({ ...prev, showPerson: false }));
    setFiliterControl((prev) => ({ ...prev, showDatePicker: true }));
  };
  const onLocationChange = (event: any) => {
    onChangeLocation(event);
  };
  const onChange = (event: any) => {
    onChangePerson(event);
  };
  const onSearch = (event: any) => {
    if (form == 'list') {
      if (location.replace(/(^\s*)|(\s*$)/g, '') == '') {
        setModalControl((prev) => ({ ...prev, isEmptyLocationModalOpen: true }));
        return;
      }
      onSearchList();
    } else {
      if (location.replace(/(^\s*)|(\s*$)/g, '') == '') {
        setModalControl((prev) => ({ ...prev, isEmptyLocationModalOpen: true }));
        return;
      }
      let arr:any = locations.pathname.split('/');
      let id = arr[arr.length - 1];
      if (hotelId == id) {
        onSearchList();
      } else {
        if (hotelId) {
          window.open(`${window.location.origin}/hotel/${hotelId}?keyword=${encodeURI(
            location,
          )}&adults=${adults}&rooms=${rooms}&children=${children}&start=${start.replaceAll(
            '/',
            '-',
          )}&end=${end.replaceAll('/', '-')}`);
        }
        else {
          navigate(
            `/list?keyword=${encodeURI(
              location,
            )}&adults=${adults}&rooms=${rooms}&children=${children}&start=${start.replaceAll(
              '/',
              '-',
            )}&end=${end.replaceAll('/', '-')}`,
          );
        }
      }
    }
  };
  return (
    <Box
      id="commonHeader"
      h="80px"
      bgColor="white"
      backdropFilter="blur(20px)"
      w="full"
      //   filter="drop-shadow(2px 2px 20px rgba(0, 0, 0, 0.2))"
      position="fixed"
      top="80px"
      left="0"
      boxShadow="0 2px 2px 0 rgb(0 0 0 / 10%)"
    >
      <Flex
        w="1440px"
        ml="auto"
        mr="auto"
        alignItems="center"
        h="80px"
        pt="5px"
        pb="8px"
        px="124px"
      >
        <Box w="268px" pl="12px">
          <LocationChoose
            location={location}
            onLocationChange={(e: any) => onLocationChange(e)}
            showLocation={showLocation}
          />
        </Box>
        <Divider
          orientation="vertical"
          bgColor="#EBEEF5"
          h="48px"
          w="2px"
          marginInlineStart="0 !important"
        />
        <Box w="540.25px" pl="56px" position="relative" onClick={(e) => showTimeChoose(e)}>
          <CheckInTime showIcon={true} start={start} end={end} />
          {showDatePicker && (
            <Box
              // w='722px'
              w="660px"
              bgColor="white"
              boxShadow="2px 4px 8px rgba(0, 0, 0, 0.2)"
              borderRadius="4px"
              position="absolute"
              bottom="-380px"
              px="29px"
            >
              <DateRangerPicker
                showBefore={false}
                start={start}
                end={end}
                onChangeValue={(e: any) => onChangeValue(e)}
              />
            </Box>
          )}
        </Box>
        <Divider
          orientation="vertical"
          bgColor="#EBEEF5"
          h="48px"
          w="2px"
          marginInlineStart="0 !important"
        />
        <Box flex="1" pl="56px">
          <PersonChoose
            adults={adults}
            rooms={rooms}
            children={children}
            childAges={childAges}
            onChange={(e: any) => onChange(e)}
            onSearch={(e: any) => onSearch(e)}
            showPerson={showPerson}
          />
        </Box>
      </Flex>
    </Box>
  );
}
