import React, { useEffect, useState, useCallback } from 'react';
import {
    Box,
    Divider,
    HStack,
    Icon,
    Image,
    Input,
    VStack,
    Text,
    Button,
    useToast
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import _ from 'lodash';
import CheckInTime from '~/components/CheckInTime';
import DateRangerPicker from '~/components/DateRangerPicker';
import {
    IoChevronDownOutline,
    IoSearchOutline
} from 'react-icons/io5';
import {
    getToken
} from '~/utils/tools';
import {
  HitPerPageBase,
  FetchError,
  AlgoliaResponse,
  SearchSuggestion,
  HotelSearchSuggestion,
  LocationType,
} from '~/types';
import { useRecoilState, useSetRecoilState } from 'recoil';
import modalControlAtom from '~/recoil/atom/modal';
import formatDate from 'date-fns/format';
import addDays from 'date-fns/addDays';
import getPoisSuggestions from '~/awsHttpApi/getPoisSuggestions';
import getHotelsSuggestions from '~/awsHttpApi/getHotelsSuggestions';
import { useNavigate } from 'react-router-dom';
import PageContainer from '~/containers/PageContainer';
import ActivityCarousel from '~/components/ActivityCarousel';
import LocationSearch from '~/components/LocationSearch';
import PersonInfo from '~/components/PersonInfo';
import Banner from '~/assets/images/banner.jpeg';
import LocationIcon from '~/assets/images/location.png';
import DateIcon from '~/assets/images/date.png';
import UserIcon from '~/assets/images/user.png';

import { useI18NText } from '~/i18n/i18n';

export default function Index() {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'home.' });
    const [
      {
        isEmptyLocationModalOpen
      },
      setModalControl,
    ] = useRecoilState(modalControlAtom);
    const toast = useToast();
    let startDate = formatDate(addDays(new Date(), 1), 'yyyy/MM/dd');
    let endDate = formatDate(addDays(new Date(), 2), 'yyyy/MM/dd');
    const navigate = useNavigate();
    const [start, setStart] = useState(startDate);
    const [end, setEnd] = useState(endDate);
    const [showDate, setShowDate] = useState(false);
    const [showPerson, setShowPerson] = useState(false);
    const [roomNum, setRoomNum] = useState(1);
    const [aldultNum, setAldultNum] = useState(2);
    const [childNum, setChildNum] = useState(0);
    let [childAges, setChildAges] = useState<Array<Number>>([]);
    const [localtion, setLocation] = useState('');
    const [searchLocation, setSearchLocation] = useState('');
    const [showLocationSearch, setShowLocationSearch] = useState(false);
    const [suggestionLists, setSuggestionList] = useState<Array<HotelSearchSuggestion>>([]);
    const [poisSuggestionsLists, setPoisSuggestionsList] = useState<Array<SearchSuggestion>>([]);
    const [isLoading, setLoading] = useState(false);
    const [hotelId, setHotelId] = useState('');
    document.addEventListener('click', (e) => {
        setShowDate(false);
        setShowPerson(false);
        setShowLocationSearch(false);
    });
    useEffect(() => {
        if (!isLoading && !localStorage.getItem('_trip_b2b_token')) {
            toast({
                title: `登录失效,请重新登录`,
                status: 'error',
                isClosable: true,
                duration: 1000,
                position: 'top'
            });
            setTimeout(()=>{
                navigate('/login');
            }, 1000)
            setLoading(true);
        }
        if (localtion && localtion.replace(/(^\s*)|(\s*$)/g, "") != '') {
            searchKeyword(localtion);
        }
    }, [localtion, isLoading]);
    // const searchKeyword = debounce((localtion) => {
    //     console.log('localtion', localtion)
    //     getSearch(localtion);
    // }, 500);
    const searchKeyword = useCallback(_.debounce((val: string) => {
        console.log('localtion1', val)
        getSearch(val);
    },300), [])
    const getSearch = async(searchText: string) => {
        const suggestionList: HotelSearchSuggestion[] = [];
        const poisSuggestionsList: SearchSuggestion[] = [];
        try {
            const [poisSuggestions, hotelsSuggestions] = await Promise.all([
                getPoisSuggestions({
                    token: getToken(),
                    keyword: searchText,
                    size: 3,
                }),
                getHotelsSuggestions({
                    token: getToken(),
                    keyword: searchText,
                    size: 6,
                }),
            ]);
            poisSuggestions.forEach(({ id, type, name, displayName }) => {
                poisSuggestionsList.push({
                    id,
                    name: name.zh_CN || name.en,
                    displayName: displayName.zh_CN || displayName.en,
                    type,
                });
            });
            hotelsSuggestions.forEach(({ name, hotelId, country, city}) => {
                suggestionList.push({
                    name,
                    hotelId,
                    country,
                    city
                });
            });
        } catch (err) {
            toast({
                position: 'top',
                status: 'error',
                title: FetchError.FETCH_HOTELS_ERROR,
            });
        }
        setSuggestionList(suggestionList);
        setPoisSuggestionsList(poisSuggestionsList);
    }
    const handleChange = (event:any) => {
        let value:any = event.target.value;
        console.log('localtion', value)
        setLocation(value);
    }
    const onChangeValue = (event:any) => {
        if (event.start) {
            setStart(event.start);
        } else {
            setStart('');
        }
        if (event.end) {
            setEnd(event.end);
        } else {
            setEnd('');
        }
    }
    const showTimeChoose = (event:any) => {
        event.nativeEvent.stopImmediatePropagation();
        setShowDate(true);
        setShowPerson(false);
        setShowLocationSearch(false);
    }
    const showPersonChoose = (event:any) => {
        event.nativeEvent.stopImmediatePropagation();
        setShowPerson(true);
        setShowDate(false);
        setShowLocationSearch(false);
    }
    const ChangePerson = (event:any) => {
        switch (event.type) {
            case 'roomNum':
                setRoomNum(event.value);
                break;
            case 'aldultNum':
                setAldultNum(event.value);
                break;
            case 'childNum':
                setChildNum(event.value);
                if (event.action === 'reduce') {
                    childAges.pop();
                } else {
                    childAges.push(0);
                }
                setChildAges(childAges);
                break;
            case 'childAge':
                let childs = [];
                childs = childAges.map((item, index)=> {
                    if (index === event.key) {
                        item = event.value
                    }
                    return item;
                })
                setChildAges(childs);
                break;
            default:
                setRoomNum(event.value);
                break;
        }
    }
    const searchList = (e:any) => {
        if (localtion.replace(/(^\s*)|(\s*$)/g, "") == '') {
            setModalControl((prev) => ({ ...prev, isEmptyLocationModalOpen: true }));
            return;
        }
        let keyword = encodeURI(localtion.replace(/(^\s*)|(\s*$)/g, ""));
        if (hotelId) {
            window.open(`${window.location.origin}/hotel/${hotelId}?keyword=${encodeURI(keyword)}&adults=${aldultNum}&rooms=${roomNum}&children=${childNum}&start=${start.replaceAll('\/', '\-')}&end=${end.replaceAll('\/', '\-')}&childAges=${JSON.stringify(childAges)}`);
        } else {
            navigate(`/list?adults=${aldultNum}&onlyCity=ON&keyword=${keyword}&rooms=${roomNum}&children=${childNum}&start=${start.replaceAll('\/', '\-')}&end=${end.replaceAll('\/', '\-')}&childAges=${JSON.stringify(childAges)}`);
        }
    }
    const handleFocus = (event:any) => {
        event.nativeEvent.stopImmediatePropagation();
        setModalControl((prev) => ({ ...prev, isEmptyLocationModalOpen: false }));
        setShowLocationSearch(true);
        setShowDate(false);
        setShowPerson(false);
    }
    const onQuickChoose = (event:any) => {
        setLocation(event.name);
    }
    const onSuggestionChoose = (event:any) => {
        let {
            hotelId,
            name
        } = event;
        let keyword = '';
        if (typeof(name) === 'string') {
            keyword = name;
        } else {
            keyword = name['zh_CN']
        }
        setLocation(keyword);
        setShowLocationSearch(false);
        if (hotelId) {
            setHotelId(hotelId);
            // navigate(`/list?adults=${aldultNum}&keyword=${encodeURI(keyword)}&rooms=${roomNum}&children=${childNum}&start=${start.replaceAll('\/', '\-')}&end=${end.replaceAll('\/', '\-')}&childAges=${JSON.stringify(childAges)}`);
            // window.open(`${window.location.origin}/hotel/${hotelId}?keyword=${encodeURI(keyword)}&adults=${aldultNum}&rooms=${roomNum}&children=${childNum}&start=${start.replaceAll('\/', '\-')}&end=${end.replaceAll('\/', '\-')}&childAges=${JSON.stringify(childAges)}`);
        } else {
            setHotelId('');
        }
        setShowLocationSearch(false);
        setShowDate(true);
    }
    const renderBanner = () => {
        return (
            <VStack
                as='div'
                align="stretch"
                display='block'
                position='relative'
                minW='1440px'
            >
                <Image
                    src={Banner}
                    w='full'
                />
                <HStack
                    position='absolute'
                    as='div'
                    display='block'
                    w='1054px'
                    top='155px'
                    left='50%'
                    transform='translateX(-50%)'
                    zIndex='99'
                >
                    <Text
                        textAlign='center'
                        fontSize='36px'
                        color='white'
                        fontWeight='500'
                        lineHeight='43.2px'
                        mb='39px'
                        letterSpacing='0 !important'
                    >
                        {getI18NText('想要找酒店')}
                    </Text>
                    <HStack
                        w='100%'
                        h='90px'
                        flexDirection='row'
                        justifyContent='space-between'
                        py='21px'
                        borderRadius='8px'
                        bgColor='white'
                    >
                        <HStack
                            w='240.7px'
                            display='block'
                            position='relative'
                            pl='52px'
                        >
                            <Image
                                src={LocationIcon}
                                w='24px'
                                h='24px'
                                position='absolute'
                                top='0'
                                left='23.27px'
                            />
                            <Box
                                position='relative'
                            >
                                <Text
                                    fontSize='12px'
                                    color='gray.800'
                                    lineHeight='20px'
                                    mb='6px'
                                    letterSpacing='0 !important'
                                >
                                    {getI18NText('目的地')} / {getI18NText('酒店名')}
                                </Text>
                                {
                                    searchLocation == '' ? (
                                        <Box
                                            onClick={(e) => handleFocus(e)}
                                        >
                                            {
                                                isEmptyLocationModalOpen ? (
                                                    <Text
                                                        h='22px'
                                                        fontSize='16px'
                                                        color='#FF6770'
                                                        fontWeight='400'
                                                        lineHeight='22.4px'
                                                    >
                                                        {getI18NText('请输入城市或酒店名称')}
                                                    </Text>
                                                ) : (
                                                    <Input
                                                        placeholder={getI18NText('今天想去哪一个城市？')}
                                                        h='22px'
                                                        w='160px'
                                                        border='none'
                                                        p='0 !important'
                                                        value={localtion}
                                                        onChange={(e)=>handleChange(e)}
                                                        // onBlur={(e) => setShowLocationSearch(false)}
                                                    />
                                                )
                                            }
                                        </Box>
                                    ) : (
                                        <Text
                                            fontSize='16px'
                                            letterSpacing='0 !important'
                                            color='blue'
                                        >
                                            {localtion}
                                        </Text>
                                    )
                                }
                                {/* {
                                    isEmptyLocationModalOpen &&
                                    <Text
                                        pos='absolute'
                                        left='0px'
                                        top='45px'
                                        fontSize='16px'
                                        color='#FF6770'
                                        fontWeight='400'
                                        lineHeight='22.4px'
                                    >
                                        请输入城市或酒店名称
                                    </Text>
                                } */}
                                {
                                    showLocationSearch &&
                                    <LocationSearch
                                        locationName={localtion}
                                        suggestionList={suggestionLists}
                                        poisSuggestionsList={poisSuggestionsLists}
                                        onQuickChoose={(e:any)=>onQuickChoose(e)}
                                        onSuggestionChoose={(e:any)=>onSuggestionChoose(e)}
                                        top='60px'
                                    />
                                }
                            </Box>
                        </HStack>
                        <Divider
                            orientation='vertical'
                            bgColor='#EBEEF5'
                            h='48px'
                            w='2px'
                            marginInlineStart='0 !important'
                        />
                        <HStack
                            w='477.65px'
                            marginInlineStart='0 !important'
                            position='relative'
                            pl='67.9px'
                            onClick={(e)=>{
                                showTimeChoose(e)
                            }}
                        >
                            <Image
                                src={DateIcon}
                                w='24px'
                                h='24px'
                                position='absolute'
                                top='0'
                                left='35.9px'
                            />
                            <Box marginInlineStart='0 !important'>
                                <Text
                                    fontSize='12px'
                                    color='gray.800'
                                    lineHeight='20px'
                                    mb='6px'
                                    letterSpacing='0 !important'
                                >
                                    {getI18NText('入住日期')} / {getI18NText('退房日期')} / {getI18NText('晚数')}
                                </Text>
                                <Box marginInlineStart='0 !important'>
                                    {
                                        start &&
                                        <CheckInTime
                                            showIcon={false}
                                            start={start}
                                            end={end}
                                        />
                                    }
                                    {
                                    showDate &&
                                        <Box
                                            // w='722px'
                                            w='660px'
                                            bgColor='white'
                                            boxShadow='2px 4px 8px rgba(0, 0, 0, 0.2)'
                                            borderRadius='4px'
                                            position='absolute'
                                            bottom='-380px'
                                            px='29px'
                                            marginInlineStart='0 !important'
                                        >
                                            <DateRangerPicker
                                                showBefore={false}
                                                start={start}
                                                end={end}
                                                onChangeValue={(e:any)=>onChangeValue(e)}
                                            />
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        </HStack>
                        <Divider
                            orientation='vertical'
                            bgColor='#EBEEF5'
                            h='48px'
                            w='2px'
                            marginInlineStart='0 !important'
                        />
                        <HStack
                            w='331.65px'
                            marginInlineStart='0 !important'
                            position='relative'
                            pl='60.7px'
                        >
                            <Image
                                src={UserIcon}
                                w='24px'
                                h='24px'
                                position='absolute'
                                top='0'
                                left='28.7px'
                            />
                            <Box
                                marginInlineStart='0 !important'
                            >
                                <Text
                                    fontSize='12px'
                                    color='gray.800'
                                    lineHeight='20px'
                                    mb='6px'
                                    letterSpacing='0 !important'
                                >
                                    {getI18NText('房型')} /{getI18NText('旅客人数')}
                                </Text>
                                <HStack
                                    alignItems='center'
                                    position='relative'
                                    onClick={(e)=>{showPersonChoose(e)}}
                                >
                                    <Text
                                        fontSize='16px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        {roomNum}{getI18NText('间')} {aldultNum}{getI18NText('成人')} {childNum}{getI18NText('儿童')}
                                    </Text>
                                    <Icon
                                        as={IoChevronDownOutline}
                                        boxSize='1rem'
                                        color='gray.800'
                                        marginInlineStart='0 !important'
                                        ml='10px !important'
                                    />
                                    {
                                        showPerson && 
                                        <PersonInfo
                                            roomNum={roomNum}
                                            aldultNum={aldultNum}
                                            childNum={childNum}
                                            childAges={childAges}
                                            bottom='-200px'
                                            onChange={(e:any)=>ChangePerson(e)}
                                        />
                                    }
                                </HStack>
                            </Box>
                            <Button
                                textAlign='center'
                                position='absolute'
                                top='4px'
                                right='20px'
                                w='84px'
                                h='40px'
                                cursor='pointer'
                                onClick={(e) => searchList(e)}
                                bgColor='blue'
                                borderRadius='4px'
                                _hover={{
                                    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #002C5E'
                                }}
                            >
                                <Icon
                                    as={IoSearchOutline}
                                    boxSize='1rem'
                                    color='white'
                                />
                            </Button>
                        </HStack>
                    </HStack>
                </HStack>
            </VStack>
        )
    }
    // document.addEventListener('click',function () {
    //     alert('body')
    // },false)
    return (
        <PageContainer
            topBoxStyle={{ position: 'static' }}
            afterHeaderComponent={
                renderBanner()
            }
        >
            {/* <VStack
                align="stretch"
                spacing='48px'
                bgColor='#FAFAFA'
                pl='142px'
                pr='146px'
                pb='63px'
            >
                <HStack
                    as='div'
                    display='block'
                    position='relative'
                    pt='48px'
                >
                    <Text
                        fontSize='24px'
                        lineHeight='28.8px'
                        fontWeight='500'
                        color='dark'
                        mb='22px'
                        letterSpacing='0 !important'
                    >
                        {getI18NText('最近热门产品')}
                    </Text>
                    <ActivityCarousel/>
                </HStack>
            </VStack> */}
        </PageContainer>
    )
}