import React, { useEffect, useState } from 'react';
import {
    Box,
    Divider,
    Flex,
    HStack,
    Image,
    Text,
} from '@chakra-ui/react';
import LocationIcon from '~/assets/images/locationIcon.png';
import HotelIcon from '~/assets/images/hotelIcon.png';
import {
    areaProps
} from '~/types/area';
import { useI18NText } from '~/i18n/i18n';

type LocationSearchProps = {
    locationName?: string,
    suggestionList?: Array<any>,
    poisSuggestionsList?: Array<any>,
    onQuickChoose?: any,
    onSuggestionChoose?: any,
    top?: string
}

const arealist: areaProps[] = [
    {
        id: 0,
        name: '上海',
    },
    {
        id: 1,
        name: '北京',
    },
    {
        id: 2,
        name: '广州',
    },
    {
        id: 3,
        name: '三亚',
    },
    {
        id: 4,
        name: '杭州',
    },
    {
        id: 5,
        name: '南京',
    },
    {
        id: 6,
        name: '重庆',
    },
    {
        id: 7,
        name: '成都',
    },
    {
        id: 8,
        name: '西安',
    },
    {
        id: 9,
        name: '苏州',
    },
    {
        id: 10,
        name: '厦门',
    },
    {
        id: 11,
        name: '武汉',
    },
    {
        id: 12,
        name: '济南',
    },
    {
        id: 13,
        name: '天津',
    },
    {
        id: 14,
        name: '郑州',
    },
    {
        id: 15,
        name: '青岛',
    },
    {
        id: 16,
        name: '大连',
    },
    {
        id: 17,
        name: '宁波',
    },
    {
        id: 18,
        name: '沈阳',
    },
    {
        id: 19,
        name: '香港',
    }
]
export default function LocationSearch({
    locationName = '',
    poisSuggestionsList = [],
    suggestionList = [],
    onQuickChoose,
    onSuggestionChoose,
    top = '60px'
} : LocationSearchProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })

    const hotCityChoose = (name: string, event: any) => {
        event.nativeEvent.stopImmediatePropagation();
        onQuickChoose({name: name});
    }
    const suggestionChoose = (suggestion:any, event:any) => {
        event.nativeEvent.stopImmediatePropagation();
        onSuggestionChoose(suggestion);
    }
    const keywordscolorful = (str:string, key:string) => {
        var reg = new RegExp("(" + key + ")", "g");
        var newstr = str.replace(reg, "<a style='color:#2D6CDF;'>$1</a>");
        return newstr;
    }
    let [TipText, setTipText] = useState('加载中...')
    useEffect(()=>{
        if (poisSuggestionsList.length == 0 && suggestionList.length == 0) {
            setTimeout(()=>{
                setTipText('没有找到相关数据，请重新输入');
            }, 5000)
        } else {
            setTipText('加载中...');
        }
    }, [poisSuggestionsList, suggestionList])
    if (locationName == '') {
        return (
            <Flex
                position='absolute'
                left='0'
                top={top}
                w='633px'
                h='144px'
                borderRadius='4px'
                boxShadow='2px 2px 20px rgba(0, 0, 0, 0.2)'
                bgColor='white'
                pt='16px'
                alignItems='flex-start'
            >
                <HStack
                    alignItems='flex-start'
                    pl='16px'
                    w='133px'
                >
                    <Image
                        src={LocationIcon}
                        w='24px'
                        h='24px'
                        mr='4px'
                    />
                    <Text
                        fontSize='14px'
                        color='blue'
                        letterSpacing='0 !important'
                        h='24px'
                        lineHeight='24px'
                    >
                        {getI18NText("热门城市")}
                    </Text>
                </HStack>
                <Divider
                    orientation='vertical'
                    w='1px'
                    h='96px'
                    bgColor='#D7D7D7'
                />
                <HStack
                    flex='1'
                    pl='4px'
                    flexWrap='wrap'
                >
                    {
                        arealist.map((item, index)=>
                            <Text
                                key={index}
                                w='96px'
                                h='25px'
                                lineHeight='25px'
                                mb='4px'
                                pl='4px'
                                letterSpacing='0 !important'
                                fontSize='14px'
                                color='gray.100'
                                marginInlineStart='0 !important'
                                cursor='pointer'
                                _hover={{
                                    bgColor: '#EBEEF5',
                                    color: 'blue'
                                }}
                                onClick={(e)=> hotCityChoose(item.name, e)}
                            >
                                {item.name}
                            </Text>
                        )
                    }
                </HStack>
            </Flex>
        )
    } else {
        return (
            <>
                {
                    (poisSuggestionsList.length == 0 && suggestionList.length == 0) ?
                    (
                        <Box
                            position='absolute'
                            left='0'
                            top={top}
                            w='642px'
                            borderRadius='4px'
                            boxShadow='2px 2px 20px rgba(0, 0, 0, 0.2)'
                            bgColor='white'
                            pt='19px'
                            pb='17px'
                        >
                            <Text
                                h='22px'
                                lineHeight='22px'
                                color='#002C5E'
                                fontWeight='500'
                                fontSize='18px'
                                textAlign='center'
                            >
                                {/* 加载中... */}
                                {TipText}
                            </Text>
                        </Box>
                    ) : (
                        <Box
                            position='absolute'
                            left='0'
                            top={top}
                            w='642px'
                            borderRadius='4px'
                            boxShadow='2px 2px 20px rgba(0, 0, 0, 0.2)'
                            bgColor='white'
                            py='16px'
                        >
                            {
                                (poisSuggestionsList && poisSuggestionsList.length > 0) &&
                                <Flex
                                    mb='20px'
                                >
                                    <HStack
                                        alignItems='flex-start'
                                        pl='16px'
                                        w='133px'
                                    >
                                        <Image
                                            src={LocationIcon}
                                            w='24px'
                                            h='24px'
                                            mr='4px'
                                        />
                                        <Text
                                            fontSize='14px'
                                            color='fontColor'
                                            letterSpacing='0 !important'
                                            h='24px'
                                            lineHeight='24px'
                                        >
                                            {getI18NText("区域")}
                                        </Text>
                                    </HStack>
                                    <Box
                                        flex='1'
                                        pl='4px'
                                        pr='16px'
                                        borderLeft='1px solid #D7D7D7'
                                    >
                                        {
                                            poisSuggestionsList.map((item, index)=>
                                                <Text
                                                    key={index}
                                                    pl='6px'
                                                    mb='8px'
                                                    cursor='pointer'
                                                    h='28px'
                                                    fontSize='14px'
                                                    color='fontColor'
                                                    letterSpacing='0 !important'
                                                    onClick={(e)=>suggestionChoose(item, e)}
                                                >
                                                    {item.displayName}
                                                </Text>
                                            )
                                        }
                                    </Box>
                                </Flex>
                            }
                            {
                                (suggestionList && suggestionList.length > 0) &&
                                <Flex>
                                    <HStack
                                        alignItems='flex-start'
                                        pl='16px'
                                        w='133px'
                                    >
                                        <Image
                                            src={HotelIcon}
                                            w='24px'
                                            h='24px'
                                            mr='4px'
                                        />
                                        <Text
                                            fontSize='14px'
                                            color='fontColor'
                                            letterSpacing='0 !important'
                                            h='24px'
                                            lineHeight='24px'
                                        >
                                            {getI18NText("酒店")}
                                        </Text>
                                    </HStack>
                                    <Box
                                        flex='1'
                                        pl='4px'
                                        pr='16px'
                                        borderLeft='1px solid #D7D7D7'
                                    >
                                        {
                                            suggestionList.map(item=>
                                                <HStack
                                                    justifyContent='space-between'
                                                    key={item.id}
                                                    alignItems='center'
                                                    mb='8px'
                                                    cursor='pointer'
                                                    h='28px'
                                                    _hover={{
                                                        bgColor: '#EBEEF5',
                                                        color: 'blue'
                                                    }}
                                                    onClick={(e)=>suggestionChoose(item, e)}
                                                >
                                                    <HStack
                                                        flex='1'
                                                    >
                                                        {/* <Text
                                                            pl='2px'
                                                            fontSize='14px'
                                                            color='#2D6CDF'
                                                            letterSpacing='0 !important'
                                                        >
                                                            {locationName}
                                                        </Text> */}
                                                        <Text
                                                            fontSize='14px'
                                                            color='fontColor'
                                                            marginInlineStart='0 !important'
                                                            letterSpacing='0 !important'
                                                            dangerouslySetInnerHTML={{ __html: keywordscolorful(item.name['zh_CN'], locationName) }}
                                                        >
                                                            {/* {afterStr} */}
                                                            {/* {keywordscolorful(item.name['zh_CN'], locationName)} */}
                                                        </Text>
                                                    </HStack>
                                                    <Text
                                                        w='40%'
                                                        fontSize='14px'
                                                        color='gray.800'
                                                        letterSpacing='0 !important'
                                                        textAlign='right'
                                                    >
                                                        {item.country['zh_CN']},{item.city['zh_CN']}
                                                    </Text>
                                                </HStack>
                                            )
                                        }
                                    </Box>
                                </Flex>
                            }
                        </Box>
                    )
                }
            </>
        )
    }
}