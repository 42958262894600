import React, { useEffect, useState } from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  IconButton,
  Stack,
  HStack,
  VStack,
  Text,
  SimpleGrid,
  Image,
} from '@chakra-ui/react';

import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

type ImgShowModalProps = {
  isOpen: boolean;
  imageLists: Array<imageListsProps>;
  selectedImageIndex: number;
  onChangeSelectedImageIndex: (index: number) => void;
  onClose: any
};
type imageListsProps = {
  caption: string;
  url: string
}
export default function ImgShowModal({
  imageLists,
  isOpen,
  selectedImageIndex,
  onChangeSelectedImageIndex,
  onClose,
}: ImgShowModalProps) {
  let images = imageLists.map(item=>{
    return item.url;
  });
  const handlePrevClick = () => {
    onChangeSelectedImageIndex(Math.max(selectedImageIndex - 1, 0));
  };

  const handleNextClick = () => {
    onChangeSelectedImageIndex(Math.min(selectedImageIndex + 1, images.length - 1));
  };

  const handleImgClick = (index: number, src: string) => {
    onChangeSelectedImageIndex(index);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent m={0} bg="transparent">
        <ModalCloseButton color="white" />
        <ModalBody display="flex">
          <Stack
            flex="1"
            direction={{ base: 'column', md: 'row' }}
            align="center"
            justify={{ base: 'start', md: 'space-between' }}
            m={{ base: 0, md: 5 }}
            mt={{ base: 5 }}
          >
            {/** img control */}
            <VStack w={{ base: 'full', md: '70%' }} align="stretch" spacing={5}>
              <HStack w="full" h="80%" justify="space-between">
                <IconButton
                  aria-label="prev"
                  bg="#00000033"
                  size={'md'}
                  icon={<IoIosArrowBack />}
                  onClick={handlePrevClick}
                />
                <Image
                  src={images.length < selectedImageIndex + 1 ? '' : images[selectedImageIndex]}
                  w="full"
                  h={{ base: '150px', md: '400px' }}
                  objectFit="contain"
                />
                <IconButton
                  aria-label="next"
                  bg="#00000033"
                  size={'md'}
                  icon={<IoIosArrowForward />}
                  onClick={handleNextClick}
                />
              </HStack>
              <Text borderRadius="md" p={1} bg="#000000aa" alignSelf="center" color="white">
                {selectedImageIndex + 1}/{images.length}
              </Text>
            </VStack>

            {/** img list */}
            <Box
              w={{ base: 'full', md: '25%' }}
              h={{ base: '400px', md: '600px' }}
              overflowY="auto"
            >
              <SimpleGrid columns={2} spacing={2}>
                {images.map((src, index) => (
                  <Image
                    key={`${src}_${index}`}
                    src={src}
                    onClick={() => handleImgClick(index, src)}
                    {...(selectedImageIndex === index
                      ? {
                          border: '5px solid',
                          borderColor: 'white',
                        }
                      : {})}
                  />
                ))}
              </SimpleGrid>
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
