import { Booking, Currency, BookingStatus, Lang, Suppliers } from '~/types';

export const booking: Booking = {
  profitMargin: 0,
  supplierId: '',
  currency: Currency.CNY,
  status: '' as BookingStatus,
  createdAt: new Date(),
  bookingId: '',
  updatedAt: new Date(),
  customer: {
    guestByRoom: [],
    contactPerson: {
      name: {
        firstName: '',
        lastName: '',
      },
      phone: '',
      email: '',
    },
    guest: [],
  },
  rates: [
    {
      bed: '[]',
      hotel: {
        address: {
          'zh-CN': '',
          'en-US': '',
        },
        name: {
          'zh-CN': '',
          'en-US': '',
        },
      },
      range: '',
      currency: Currency.CNY,
      feesCurrency: Currency.CNY,
      dailyPrice: [],
      fees: 0,
      occupancy: 0,
      hasBreakfast: false,
      rateId: '',
      roomId: 0,
      roomNum: 0,
      price: 0,
      cancelPolicies: [],
      group: {
        adultCount: 2,
        children: [],
      },
      mealCount: [],
      hotelId: '',
      roomName: {
        'zh-CN': '',
        'en-US': '',
      },
      meal: '{}',
      checkIn: '',
      checkOut: '',
    },
  ],
  price: 0,
  hotel: {
    address: {
      'zh-CN': '',
      'en-US': '',
    },
    name: {
      'zh-CN': '',
      'en-US': '',
    },
    staticDocId: '',
  },
  confirmationPdfUrl: '',
  bookingPayment: {
    invalidAt: "2022-02-19T17:14:44.378Z",
    paymentStatus: '',
    paymentAppUrlMap: {
      Wechat: ''
    }
  }
};
