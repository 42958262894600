import axios, { AxiosError } from 'axios';
import { BookingListItem, BookingStatus, PaymentStatus } from '~/types';

import parseISO from 'date-fns/parseISO';

import queryString from 'query-string';
// import { PaymentStatus } from '~/components/OrderDetail/StatusType';


type BookingListItemResponse = {
  id: string;
  hoteladdress: string;
  hotelname: string;
  hoteladdressen: string;
  hotelnameen: string;
  paymentstatus: PaymentStatus;
  paymenttype: string;
  status: BookingStatus;
  guestnames: string;
  checkindate: string;
  checkoutdate: string;
  createdtime: string;
  agencytotalprice: string | number;
  hotelroomtype: string;
  roomnum: number
};

type GetBookingRequest = {
  token: string;
  status: string;
  payStatus: string;
  sort: string;
  keyword: string;
  dateType: string;
  dateRagne: dateTypeProps;
  page: number,
  size: number,
  gateway: string,
};
type dateTypeProps = {
  min: string,
  max: string
}

export default async ({
  token,
  status,
  payStatus,
  sort,
  keyword,
  dateType,
  dateRagne,
  page,
  size,
  gateway,
}: GetBookingRequest): Promise<{
  bookings: BookingListItem[];
  totalCount: number;
  totalPage: number
}> => {
  try {
    const bookingResponse = await axios.post<{
      orders: BookingListItemResponse[];
      totalCount: number;
      totalPage: number
    }>(
      `${process.env.REACT_APP_AWS_HTTP_BASE_URL}/booking/list`,
      {
        status,        // 未选择则置空
        payStatus,        // 未选择则置空
        sort,
        keyword,
        dateType,   // 未选择则置空
        dateRagne,
        page,
        size,
        gateway
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const {
      orders,
      totalCount,
      totalPage
    } = bookingResponse.data;
    let list = orders.map(
      ({
        agencytotalprice,
        guestnames,
        checkindate,
        checkoutdate,
        createdtime,
        ...bookingOtherFields
      }) => ({
        ...bookingOtherFields,
        guests: guestnames.split('&'),
        checkindate: parseISO(checkindate),
        checkoutdate: parseISO(checkoutdate),
        createdtime: parseISO(createdtime),
        agencytotalprice:
          typeof agencytotalprice === 'string' ? parseInt(agencytotalprice) : agencytotalprice,
        checked: false
      }),
    );
    console.log('list', list)
    return {
      bookings: orders.map(
        ({
          agencytotalprice,
          guestnames,
          checkindate,
          checkoutdate,
          createdtime,
          ...bookingOtherFields
        }) => ({
          ...bookingOtherFields,
          guests: guestnames.split('&'),
          checkindate: parseISO(checkindate),
          checkoutdate: parseISO(checkoutdate),
          createdtime: parseISO(createdtime),
          agencytotalprice:
            typeof agencytotalprice === 'string' ? parseInt(agencytotalprice) : agencytotalprice,
          checked: false
        }),
      ),
      totalCount: Number(totalCount),
      totalPage: Number(totalPage)
    };
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;

    bookingFailedError.response
      ? console.error('Request failed for get booking api: ', bookingFailedError.response.data)
      : console.error('Request failed for get booking api: ', error);

    return {
      bookings: [],
      totalCount: 0,
      totalPage: 0
    };
  }
};
