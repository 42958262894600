import React, {useState} from 'react';
import {
    Box,
    Icon,
    Text,
} from '@chakra-ui/react';
import {
    IoChevronDown
} from 'react-icons/io5'

type SelectProps = {
    w?: string,
    h?: string,
    mr?: string,
    placeholder?: string,
    value?: string,
    list?: Array<listProps>;
    onChange?: any
}
type listProps = {
    id: any,
    value: string,
}
export default function Select({
    w = '100px',
    h = '40px',
    mr = '0px',
    placeholder = '',
    value = '',
    list=[],
    onChange
}:SelectProps) {
    const [show, setShow] = useState(false);
    return (
        <Box
            w={w}
            h={h}
            position='relative'
            onMouseOver={(e) => setShow(true)}
            onMouseLeave={(e) => setShow(false)}
        >
            <Text
                textAlign='center'
                h={h}
                lineHeight={h}
                color={value ? 'blue' : 'gray.800'}
                fontSize='16px'
                fontWeight={value ? '500' : '400'}
                bgColor='white'
                borderWidth='1px'
                borderColor={value ? 'blue' : 'gray.800'}
                borderRadius='4px'
                cursor='pointer'
            >
                {value ? value : placeholder}
            </Text>
            <Icon
                as={IoChevronDown}
                boxSize='1.25rem'
                color='gray.800'
                position='absolute'
                right='17.5px'
                top='10.5px'
            />
            {
                show &&
                <Box
                    position='absolute'
                    left='0'
                    w={w}
                    top={h}
                    bgColor='white'
                    boxShadow='2px 4px 8px rgba(0, 0, 0, 0.2)'
                    borderRadius='4px'
                    pt='4px'
                    zIndex='999'
                >
                    {
                        list.map(item=>
                            <Text
                                h='44px'
                                lineHeight='44px'
                                textAlign='center'
                                color={item.value == value ? 'blue' : 'gray.800'}
                                bgColor='white'
                                fontSize='14px'
                                cursor='pointer'
                                _hover={{
                                    bgColor: '#EBEEF5',
                                    color: 'blue'
                                }}
                                onClick={(e)=>{onChange({
                                    id: item.id, 
                                    value: item.value
                                })}}
                            >
                                {item.value}
                            </Text>    
                        )
                    }
                </Box>
            }
        </Box>
    )
}