import { useMemo, useState, useEffect } from 'react';
import {
    Box,
    Checkbox,
    Divider,
    Flex,
    HStack,
    Image,
    Input,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    Stack,
    VStack,
    Text,
    Icon,
    BoxProps
} from '@chakra-ui/react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import filiterControlAtom from '~/recoil/atom/filiter';
import { useNavigate, useParams } from 'react-router-dom';
import {
    getQueryString,
    getToken,
} from '~/utils/tools';
import PageContainer from '~/containers/PageContainer';
import LoadingModal from '~/components/LoadingModal';
import HotelItem from '~/components/HotelItem';
import CommonHeader  from '~/components/CommonHeader';
import Empty from '~/components/Empty';
import getHotelListRates from '~/awsHttpApi/getHotelListRates';
import {
    HotelItemObject
} from '~/types/HotelItem';
import {
    CheckObject
} from '~/types/check';
import {
    PriorityOption,
    Hotel,
    HotelPrice
} from '~/types';
import AreaImg from '~/assets/images/area.png';
import {
    AiOutlineCloseCircle
} from 'react-icons/ai';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import '~/assets/paginations.css';

import { useI18NText } from '~/i18n/i18n';
import _ from 'lodash';


const accommodationStars: CheckObject[] = [
    {
        id: 5,
        name: '五星 / 豪华',
        value: false,
        type: 'stars',
    },
    {
        id: 4,
        name: '四星 / 高档',
        value: false,
        type: 'stars',
    },
    {
        id: 3,
        name: '三星 / 舒适',
        value: false,
        type: 'stars',
    },
    {
        id: 2,
        name: '二星以下 / 经济型',
        value: false,
        type: 'stars',
    }
]
const Evaluations: CheckObject[] = [
    {
        id: 4,
        name: '5分',
        value: false,
        type: 'Evaluations',
    },
    {
        id: 5,
        name: '4+分',
        value: false,
        type: 'Evaluations',
    },
    {
        id: 6,
        name: '3+分',
        value: false,
        type: 'Evaluations',
    },
    {
        id: 7,
        name: '不限',
        value: false,
        type: 'Evaluations',
    }
]
const Areas: CheckObject[] = [
    {
        id: 8,
        name: '朝阳区',
        value: false,
        type: 'Areas',
    },
    {
        id: 9,
        name: '北京市中心',
        value: false,
        type: 'Areas',
    },
    {
        id: 10,
        name: '海淀',
        value: false,
        type: 'Areas',
    },
    {
        id: 11,
        name: '西城',
        value: false,
        type: 'Areas',
    }
]
export default function List() {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'list.' });
    const [
        {
            showLocation,
            showDatePicker,
            showPerson,
        }
    ] = useRecoilState(filiterControlAtom);
    const setFiliterControl = useSetRecoilState(filiterControlAtom);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [isFormSent, setFormSent] = useState<boolean>(false);
    let page = getQueryString('pageNo') || 1;
    let [pageNo, setPageNo] = useState(Number(page));
    let [pageSize, setPageSize] = useState(10);
    let [stars, setStars] = useState([] as any[]);
    const [totalPage, setTotalPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [hotelList, setHotelList] = useState<HotelPrice[]>([]);
    let [startPrice, setStartPrice] = useState(100);
    let [endPrice, setEndPrice] = useState(10000);
    let [accommodationStarList, setAccommodationStarList] = useState(accommodationStars as any[]);
    let [EvaluationList, setEvaluationList] = useState(Evaluations as any[]);
    let [AreaList, setAreaList] = useState(Areas as any[]);
    let [chosed, setChosed] = useState([] as any[]);
    let keyword = getQueryString('keyword') || '';
    if (keyword) {
        keyword = decodeURI(keyword);
    }
    const [location, setLocation] = useState(keyword);
    let adultsNum = 0;
    if (getQueryString('adults')) {
        adultsNum = Number(getQueryString('adults'));
    }
    let roomsNum = 0;
    if (getQueryString('rooms')) {
        roomsNum = Number(getQueryString('rooms'));
    }
    let childrenNum = 0;
    if (getQueryString('children')) {
        childrenNum = Number(getQueryString('children'));
    }
    let isonlyCity = 'OFF';
    if (getQueryString('onlyCity')) {
        isonlyCity = getQueryString('onlyCity') || 'OFF';
    }
    const [onlyCity, setOnlyCity] = useState(isonlyCity);
    let [adults, setAdults] = useState(adultsNum);
    let [rooms, setRooms] = useState(roomsNum);
    let [children, setChildren] = useState(childrenNum);
    let childs = [];
    let ages = getQueryString('childAges');
    if (ages) {
        childs = JSON.parse(ages);
    }
    let [childAges, setChildAges] = useState<Array<Number>>(childs);
    let [start, setStart] = useState(getQueryString('start') || '');
    let [end, setEnd] = useState(getQueryString('end') || '');
    let [sortingWay, setSortingWay] = useState(PriorityOption.HOT_PRIORITY);
    useEffect(()=>{
        if (loading) {
            getInit();
        }
        type selectType = {
            id: any,
            name: string,
            value: boolean,
        }
        let selectChosed: selectType[] = [];
        accommodationStarList.forEach(item=> {
            if (item.value) {
                selectChosed.push(item);
            }
        });
        EvaluationList.forEach(item=> {
            if (item.value) {
                selectChosed.push(item);
            }
        });
        AreaList.forEach(item=> {
            if (item.value) {
                selectChosed.push(item);
            }
        });
        setChosed(selectChosed);
        const hideModal = () => {
            setFiliterControl((prev) => ({ ...prev, showLocation: false }));
            setFiliterControl((prev) => ({ ...prev, showPerson: false }));
            setFiliterControl((prev) => ({ ...prev, showDatePicker: false }));
        }
        window.addEventListener("click", hideModal);
        return () => {
        window.removeEventListener("click", hideModal);
        };
    }, [accommodationStarList, EvaluationList, AreaList, pageNo, loading]);
    const getInit = () => {
        getHotelListRates({
            token: getToken(),
            keyword: location,
            sortingWay: sortingWay,
            checkIn: start.replaceAll('\/', '\-'),
            checkOut: end.replaceAll('\/', '\-'),
            stars: stars,
            page: Number(pageNo - 1),
            priceRange: {
              min: startPrice,
              max: endPrice,
            },
            size: pageSize,
            group: {
              adults: Number(adults),
              children: [],
            },
            onlyCity: onlyCity
        }).then(res=> {
            let hotelList:HotelPrice[] = [];
            res.hotels.forEach(item=>{
                hotelList.push(item);
            });
            setTotalPage(res.totalPage);
            setTotalCount(res.totalCount);
            hotelList.forEach((item, index)=>{
                if (item.policies.length > 0) {
                    console.log('index', index)
                }
            })
            setHotelList(hotelList);
            setLoading(false);
        });
    }
    const RemoveItem = (obj:CheckObject, event:any) => {
        switch (obj.type) {
            case 'stars':
                accommodationStarList = accommodationStarList.map(item=>{
                    if (item.id == obj.id) {
                        item.value = false;
                    }
                    return item;
                });
                setAccommodationStarList(accommodationStarList);
                break;
            case 'Evaluations':
                EvaluationList = EvaluationList.map(item=>{
                    if (item.id == obj.id) {
                        item.value = false;
                    }
                    return item;
                });
                setEvaluationList(EvaluationList);
                break;
            case 'Areas':
                AreaList = AreaList.map(item=>{
                    if (item.id == obj.id) {
                        item.value = false;
                    }
                    return item;
                });
                setAreaList(AreaList);
                break;
            default:
                accommodationStarList = accommodationStarList.map(item=>{
                    if (item.id == obj.id) {
                        item.value = false;
                    }
                    return item;
                });
                setAccommodationStarList(accommodationStarList);
                break;
        }
        setPageNo(1);
        setLoading(false);
    }
    const ChangRange = (event:any, key: number) => {
        const value = event.target.value;
        if (key == 0) {
            setStartPrice(Number(value));
        } else {
            setEndPrice(Number(value));
        }
    }
    const onChangRange = (event:any) => {
        if (event.keyCode == 13) {
            setLoading(true);
        }
    }
    const clearRange = (e:any) => {
        setStartPrice(Number(100));
        setEndPrice(Number(10000));
        setLoading(true);
        setLoading(true);
    }
    const ChangeStar = (check: boolean, key: any) => {
        let starslist = [] as any[];
        accommodationStarList = accommodationStarList.map(item=>{
            if (item.id == key) {
                item.value = check;
            }
            if (item.value) {
                starslist.push(item.id);
            }
            return item;
        });
        setAccommodationStarList(accommodationStarList);
        setStars(starslist);
        setLoading(true);
    }
    const clearStarHotel = (e:any) => {
        accommodationStarList = accommodationStarList.map(item=>{
            item.value = false;
            return item;
        });
        setAccommodationStarList(accommodationStarList);
        setLoading(true);
    }
    const ChangeEvaluation = (check: boolean, key: any) => {
        EvaluationList = EvaluationList.map(item=>{
            if (item.id == key) {
                item.value = check;
            }
            return item;
        });
        setEvaluationList(EvaluationList);
        setLoading(true);
    }
    const clearEvaluation = (e:any) => {
        EvaluationList = EvaluationList.map(item=>{
            item.value = false;
            return item;
        });
        setEvaluationList(EvaluationList);
        setLoading(true);
    }
    const ChangeArea = (check: boolean, key: any) => {
        AreaList = AreaList.map(item=>{
            if (item.id == key) {
                item.value = check;
            }
            return item;
        });
        setAreaList(AreaList);
        setLoading(true);
    }
    const clearArea = (e:any) => {
        AreaList = AreaList.map(item=>{
            item.value = false;
            return item;
        });
        setAreaList(AreaList);
        setLoading(true);
    }
    const handleActiveStyle = (isActive: boolean): BoxProps => {
        return isActive
            ? {
                bgColor: '#002C5E !important',
                color: 'white !important',
                _hover: {
                    background: '#002C5E !important',
                }
            }
            : {
                bgColor: 'white',
                color: 'blue',
            };
    };
    const onChangePage = (event:any) => {
        setPageNo(event);
        setLoading(true);
    }
    const onChangeTime = (event: any) => {
        if (event.start) {
            setStart(event.start)
        }
        if (event.end) {
            setEnd(event.end)
        }
    }
    const onLocationChange = (event: any) => {
        let {
            name,
            type
        } = event;
        let keyword = '';
        if (typeof(name) === 'string') {
            keyword = name;
        } else {
            keyword = name['zh_CN']
        }
        setLocation(keyword);
        event?.hotelId ? setOnlyCity('OFF') : setOnlyCity('ON');
        // if (event && event?.hotelId) {
        //     window.open(`${window.location.origin}/hotel/${event?.hotelId}?keyword=${encodeURI(keyword)}&onlyCity=${onlyCity}&adults=${adults}&rooms=${rooms}&children=${children}&start=${start.replaceAll('\/', '\-')}&end=${end.replaceAll('\/', '\-')}&childAges=${JSON.stringify(childAges)}`);
        // }
        if (type == 'click') {
            setFiliterControl((prev) => ({ ...prev, showDatePicker: true }));
            setFiliterControl((prev) => ({ ...prev, showPerson: false }));
            setFiliterControl((prev) => ({ ...prev, showLocation: false }));
        } else {
            setFiliterControl((prev) => ({ ...prev, showDatePicker: false }));
            setFiliterControl((prev) => ({ ...prev, showPerson: false }));
            setFiliterControl((prev) => ({ ...prev, showLocation: true }));
        }
    }
    const onChangePerson = (event: any) => {
        switch (event.type) {
            case 'roomNum':
                setRooms(event.value);
                break;
            case 'aldultNum':
                setAdults(event.value);
                break;
            case 'childNum':
                setChildren(event.value);
                if (event.action === 'reduce') {
                    childAges.pop();
                } else {
                    childAges.push(0);
                }
                setChildAges(childAges);
                break;
            case 'childAge':
                let childs = [];
                childs = childAges.map((item, index)=> {
                    if (index === event.key) {
                        item = event.value
                    }
                    return item;
                })
                setChildAges(childs);
                break;
            default:
                setRooms(event.value);
                break;
        }
    }
    const sortList = (type:any, event:any) => {
        setFiliterControl((prev) => ({ ...prev, showLocation: false }));
        setFiliterControl((prev) => ({ ...prev, showPerson: false }));
        setFiliterControl((prev) => ({ ...prev, showDatePicker: false }));
        setSortingWay(type);
        setPageNo(1);
        setLoading(true);
    }
    const onSearchList = (event:any) => {
        setFiliterControl((prev) => ({ ...prev, showLocation: false }));
        setFiliterControl((prev) => ({ ...prev, showPerson: false }));
        setFiliterControl((prev) => ({ ...prev, showDatePicker: false }));
        setPageNo(1);
        setLoading(true);
    }
    return (
        <PageContainer
            // topBoxStyle={{ position: 'static' }}
            afterHeaderComponent={
                <CommonHeader
                    showLocation={showLocation}
                    showDatePicker={showDatePicker}
                    showPerson={showPerson}
                    location={location}
                    adults={adults}
                    start={start}
                    end={end}
                    rooms={rooms}
                    children={children}
                    childAges={childAges}
                    onChangeTime={(e:any)=>onChangeTime(e)}
                    onChangeLocation={(e:any)=>onLocationChange(e)}
                    onChangePerson={(e:any)=>onChangePerson(e)}
                    onSearchList={(e:any)=>onSearchList(e)}
                    form='list'
                    hotelId=''
                />
            }
        >
            <VStack
                align="stretch"
                bgColor='#FAFAFA'
                pl='142px'
                pr='126px'
                pb='63px'
                pt='140px'
            >
                <HStack
                    alignItems='center'
                    justifyContent='flex-end'
                    filter='drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.15))'
                    mb='8px'
                >
                    <Text
                        w='112px'
                        h='38px'
                        bgColor='white'
                        textAlign='center'
                        lineHeight='38px'
                        fontSize='16px'
                        color='blue'
                        marginInlineStart='0 !important'
                        cursor='pointer'
                        _hover={{
                            background: 'linear-gradient(0deg, rgba(148, 148, 148, 0.2), rgba(148, 148, 148, 0.2)), #EBEEF5',
                        }}
                        {...handleActiveStyle(sortingWay == PriorityOption.HOT_PRIORITY)}
                        onClick={(e)=>sortList(PriorityOption.HOT_PRIORITY, e)}
                        borderBottomLeftRadius='4px'
                    >
                        {getI18NText('优先推荐')}
                    </Text>
                    <Text
                        w='112px'
                        h='38px'
                        bgColor='white'
                        textAlign='center'
                        lineHeight='38px'
                        fontSize='16px'
                        color='blue'
                        marginInlineStart='0 !important'
                        cursor='pointer'
                        _hover={{
                            background: 'linear-gradient(0deg, rgba(148, 148, 148, 0.2), rgba(148, 148, 148, 0.2)), #EBEEF5',
                        }}
                        {...handleActiveStyle(sortingWay == PriorityOption.LOW_PRICE_PRIORITY)}
                        onClick={(e)=>sortList(PriorityOption.LOW_PRICE_PRIORITY, e)}
                    >
                        {getI18NText('价格最低')}
                    </Text>
                    <Text
                        w='112px'
                        h='38px'
                        bgColor='white'
                        textAlign='center'
                        lineHeight='38px'
                        fontSize='16px'
                        color='blue'
                        marginInlineStart='0 !important'
                        cursor='pointer'
                        _hover={{
                            background: 'linear-gradient(0deg, rgba(148, 148, 148, 0.2), rgba(148, 148, 148, 0.2)), #EBEEF5',
                        }}
                        {...handleActiveStyle(sortingWay == PriorityOption.HIGH_PRICE_PRIORITY)}
                        onClick={(e)=>sortList(PriorityOption.HIGH_PRICE_PRIORITY, e)}
                    >
                        {getI18NText('价格最高')}
                    </Text>
                    {/* <Text
                        w='112px'
                        h='38px'
                        bgColor='white'
                        textAlign='center'
                        lineHeight='38px'
                        fontSize='16px'
                        color='blue'
                        marginInlineStart='0 !important'
                        cursor='pointer'
                        _hover={{
                            background: 'linear-gradient(0deg, rgba(148, 148, 148, 0.2), rgba(148, 148, 148, 0.2)), #EBEEF5',
                        }}
                        {...handleActiveStyle(sortingWay == PriorityOption.HIGH_COMMENT_PRIORITY)}
                        onClick={(e)=>sortList(PriorityOption.HIGH_COMMENT_PRIORITY, e)}
                    >
                        {getI18NText('评价最高')}
                    </Text> */}
                    <Text
                        w='112px'
                        h='38px'
                        bgColor='white'
                        textAlign='center'
                        lineHeight='38px'
                        fontSize='16px'
                        color='blue'
                        marginInlineStart='0 !important'
                        cursor='pointer'
                        _hover={{
                            background: 'linear-gradient(0deg, rgba(148, 148, 148, 0.2), rgba(148, 148, 148, 0.2)), #EBEEF5',
                        }}
                        {...handleActiveStyle(sortingWay == PriorityOption.HIGH_STAR_PRIORITY)}
                        onClick={(e)=>sortList(PriorityOption.HIGH_STAR_PRIORITY, e)}
                        borderBottomRightRadius='4px'
                    >
                        {getI18NText('星级最高')}
                    </Text>
                </HStack>
                <Flex
                    justifyContent='space-between'
                    pt='8px'
                >
                    <Box
                        w='240px'
                    >
                        {/* <Image
                            src={AreaImg}
                            w='240px'
                            h='110px'
                            borderRadius='4px'
                            mb='16px'
                        /> */}
                        <HStack
                            as='div'
                            display='block'
                            p='24px'
                            bgColor='white'
                            borderRadius='4px'
                            boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
                            mb='16px'
                        >
                            <Flex
                                justifyContent='space-between'
                                alignItems='center'
                                mb='8px'
                            >
                                <Text
                                    color='gray.100'
                                    fontSize='18px'
                                    fontWeight='500'
                                    letterSpacing='0 !important'
                                >
                                    {getI18NText('房间价格')}
                                </Text>
                                <Text
                                    fontSize='12px'
                                    color='blue'
                                    letterSpacing='0 !important'
                                    cursor='pointer'
                                    onClick={(e)=>clearRange(e)}
                                >
                                    {getI18NText('清空')}
                                </Text>
                            </Flex>
                            <Text
                                fontSize='14px'
                                lineHeight='19.6px'
                                color='gray.800'
                                marginInlineStart='0 !important'
                                mb='8px'
                                letterSpacing='0 !important'
                            >
                                {getI18NText('单晚单房价格')}
                            </Text>
                            <RangeSlider
                                aria-label={['min', 'max']}
                                min={0}
                                max={10000}
                                step={1}
                                value={[startPrice, endPrice]}
                                colorScheme='blue'
                                marginInlineStart='0 !important'
                                onChange={(val) => {
                                    setStartPrice(val[0]);
                                    setEndPrice(val[1]);
                                    // setLoading(true);
                                }}
                                onChangeEnd={(val) => {
                                    setLoading(true);
                                }}
                                w='185px'
                            >
                                <RangeSliderTrack bg='#D7D7D7'>
                                    <RangeSliderFilledTrack bg='#002C5E'/>
                                </RangeSliderTrack>
                                <RangeSliderThumb
                                    w='14px'
                                    h='14px'
                                    index={0}
                                    borderWidth='2px'
                                    borderColor='#002C5E'
                                    boxShadow='0px 0px 4px rgba(0, 0, 0, 0.1)'
                                />
                                <RangeSliderThumb
                                    w='14px'
                                    h='14px'
                                    index={1}
                                    borderWidth='2px'
                                    borderColor='#002C5E'
                                    boxShadow='0px 0px 4px rgba(0, 0, 0, 0.1)'
                                />
                            </RangeSlider>
                            <Flex
                                alignItems='center'
                                marginInlineStart='0 !important'
                            >
                                <Input
                                    w='80px'
                                    h='30px'
                                    value={startPrice}
                                    onChange={(e)=>ChangRange(e, 0)}
                                    onKeyUp={(e:any)=>onChangRange(e)}
                                    placeholder='0'
                                />
                                <Text
                                    w='10px'
                                    fontSize='12px'
                                    color='gray.100'
                                    mx='8px !important'
                                    letterSpacing='0 !important'
                                >
                                    -
                                </Text>
                                <Input
                                    w='80px'
                                    h='30px'
                                    value={endPrice}
                                    onChange={(e)=>ChangRange(e, 1)}
                                    onKeyUp={(e:any)=>onChangRange(e)}
                                    placeholder='1024'
                                />
                            </Flex>
                        </HStack>
                        <HStack
                            as='div'
                            display='block'
                            p='24px'
                            bgColor='white'
                            borderRadius='4px'
                            boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
                            mb='16px'
                        >
                            <Flex
                                justifyContent='space-between'
                                alignItems='center'
                                mb='16px'
                            >
                                <Text
                                    color='gray.100'
                                    fontSize='18px'
                                    fontWeight='500'
                                    letterSpacing='0 !important'
                                >
                                    {getI18NText('住宿星级')}
                                </Text>
                                <Text
                                    fontSize='12px'
                                    color='blue'
                                    letterSpacing='0 !important'
                                    cursor='pointer'
                                    onClick={(e)=>clearStarHotel(e)}
                                >
                                    {getI18NText('清空')}
                                </Text>
                            </Flex>
                            <Stack spacing='24px'>
                                {
                                    accommodationStarList.map(item=>
                                        <Checkbox
                                            key={item.id}
                                            isChecked={item.value}
                                            onChange={(e) => ChangeStar(e.target.checked, item.id)}
                                        >
                                            {item.name}
                                        </Checkbox>
                                    )
                                }
                            </Stack>
                        </HStack>
                        {/* <HStack
                            as='div'
                            display='block'
                            p='24px'
                            bgColor='white'
                            borderRadius='4px'
                            boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
                            mb='16px'
                        >
                            <Flex
                                justifyContent='space-between'
                                alignItems='center'
                                mb='16px'
                            >
                                <Text
                                    color='gray.100'
                                    fontSize='18px'
                                    fontWeight='500'
                                    letterSpacing='0 !important'
                                >
                                    {getI18NText('旅客评价')}
                                </Text>
                                <Text
                                    fontSize='12px'
                                    color='blue'
                                    letterSpacing='0 !important'
                                    cursor='pointer'
                                    onClick={(e)=> clearEvaluation(e)}
                                >
                                    {getI18NText('清空')}
                                </Text>
                            </Flex>
                            <Stack spacing='24px'>
                                {
                                    EvaluationList.map(item=>
                                        <Checkbox
                                            key={item.id}
                                            isChecked={item.value}
                                            onChange={(e) => ChangeEvaluation(e.target.checked, item.id)}
                                        >
                                            {item.name}
                                        </Checkbox>
                                    )
                                }
                            </Stack>
                        </HStack> */}
                        {/* <HStack
                            as='div'
                            display='block'
                            p='24px'
                            bgColor='white'
                            borderRadius='4px'
                            boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
                            mb='16px'
                        >
                            <Flex
                                justifyContent='space-between'
                                alignItems='center'
                                mb='16px'
                            >
                                <Text
                                    color='gray.100'
                                    fontSize='18px'
                                    fontWeight='500'
                                    letterSpacing='0 !important'
                                >
                                    {getI18NText('商圈')}/{getI18NText('区域')}
                                </Text>
                                <Text
                                    fontSize='12px'
                                    color='blue'
                                    letterSpacing='0 !important'
                                    cursor='pointer'
                                    onClick={(e)=>clearArea(e)}
                                >
                                    {getI18NText('清空')}
                                </Text>
                            </Flex>
                            <Stack spacing='24px'>
                                {
                                    AreaList.map(item=>
                                        <Checkbox
                                            key={item.id}
                                            isChecked={item.value}
                                            onChange={(e) => ChangeArea(e.target.checked, item.id)}
                                        >
                                            {item.name}
                                        </Checkbox>
                                    )
                                }
                            </Stack>
                        </HStack> */}
                    </Box>
                    <Box
                        flex='1'
                        pl='45px'
                    >
                        <Text
                            lineHeight='22.4px'
                            fontSize='16px'
                            fontWeight='500'
                            color='dark'
                            mb='16px'
                        letterSpacing='0 !important'
                        >
                            {getI18NText('找到_个酒店', { interpolation: { num: totalCount } })}
                        </Text>
                        <Flex
                            alignItems='center'
                            flexWrap='wrap'
                        >
                            {
                                chosed.map(item=>
                                    <HStack
                                        alignItems='center'
                                        pl='16px'
                                        pr='12.5px'
                                        h='40px'
                                        mr='8px'
                                        mb='16px'
                                        borderWidth='1px'
                                        borderColor='blue'
                                        borderRadius='4px'
                                        cursor='pointer'
                                        key={item.id}
                                    >
                                        <Text
                                            mr='5.5px'
                                            color='blue'
                                            fontSize='14px'
                                            letterSpacing='0 !important'
                                        >
                                            {item.name}
                                        </Text>
                                        <Icon
                                            as={AiOutlineCloseCircle}
                                            boxSize='1rem'
                                            color='blue'
                                            marginInlineStart='0 !important'
                                            onClick={(e) => {RemoveItem(item, e)}}
                                        />
                                    </HStack>
                                )
                            }
                        </Flex>
                        {
                            hotelList.map(item=>
                                <HotelItem
                                    searchlocation={location}
                                    key={item.hotelId}
                                    hotelId={item.hotelId}
                                    address={item.address}
                                    country={item.country}
                                    city={item.city}
                                    attractions={item.attractions}
                                    description={item.description}
                                    facilities={item.facilities}
                                    images={item.images}
                                    location={item.location}
                                    name={item.name}
                                    policies={item.policies}
                                    rooms={item.rooms}
                                    star={item.star}
                                    lowestPrice={item.lowestPrice}
                                    roomnum={rooms}
                                    adults={adults}
                                    children={children}
                                    start={start}
                                    end={end}
                                    childAges={childAges}
                                    onlyCity={onlyCity}
                                />
                            )
                        }
                        {
                            (hotelList.length == 0 && !loading) && 
                            <Empty
                                tip='您慢了一步！建议您重新搜索其他酒店。'
                            />
                        }
                        <Flex
                            justifyContent='flex-end'
                        >
                            {
                                totalCount > 0 &&
                                <Pagination
                                    total={totalCount}
                                    current={pageNo}  
                                    pageSize={pageSize}
                                    onChange={(e)=> {onChangePage(e)}}
                                    className="paginations"
                                    prevIcon={<div className='prevIcon'></div>}
                                    nextIcon={<div className='nextIcon'></div>}
                                    jumpPrevIcon={<div className='jumpPrevIcon'></div>}
                                    jumpNextIcon={<div className='jumpNextIcon'></div>}
                                />
                            }
                        </Flex>
                    </Box>
                </Flex>
            </VStack>
            <LoadingModal isOpen={isFormSent || loading} />
        </PageContainer>
    )
}