import axios, { AxiosError } from 'axios';
import * as queryString from 'query-string';

import { Locale } from '~/types';

type GetHotelRequest = {
  token: string;
  keyword: string;
  size?: number;
};

type HotelSuggestion = {
  hotelId: string;
  name: Record<Locale, string>;
  city: Record<Locale, string>;
  country: Record<Locale, string>;
};

export default async ({
  token,
  keyword,
  size = 5,
}: GetHotelRequest): Promise<HotelSuggestion[]> => {
  try {
    const hotelResponse = await axios.get<{
      suggestions: HotelSuggestion[];
    }>(
      `${process.env.REACT_APP_AWS_HTTP_BASE_URL}/hotel/suggestions?${queryString.stringify({
        keyword,
        size,
      })}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return hotelResponse.data.suggestions;
  } catch (error) {
    const hotelFailedError = error as AxiosError<{ errorCode: string; message: string }>;

    hotelFailedError.response
      ? console.error(
          'Request failed for get hotel suggestions api: ',
          hotelFailedError.response.data,
        )
      : console.error('Request failed for get hotel suggestions api: ', error);

    return [];
  }
};
