import { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    HStack,
    Icon,
    Text,
} from '@chakra-ui/react';
import {
    IoChevronDown,
    IoChevronUp
} from 'react-icons/io5'
import hotel from '~/assets/images/hotel.png';
import bed from '~/assets/images/bed.png';
import tableware from '~/assets/images/tableware.png';
import formatDate from 'date-fns/format';
import addDays from 'date-fns/addDays';

import { useI18NText } from '~/i18n/i18n';

let style = {
    timeStyle: {
        w: '105px',
        mr: '11px',
        h: '17px',
        lineHeight: '17px',
        fontSize: '14px',
        color: 'gray.800',
        letterSpacing: '0 !important'
    },
    descStyle: {
        w: '65px',
        color: 'fontColor',
        fontSize: '14px',
        letterSpacing: '0 !important'
    }
}
type QuoteProps = {
    checkIn?: any,
    checkOut?: any,
    dailyPrice?: Array<number>
}
type listProps = {
    inTime: string;
    dailyPrice: number;
}
export default function Quote({
    checkIn,
    checkOut,
    dailyPrice
} : QuoteProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })
    const dateStart = new Date(checkIn);
    const dateEnd = new Date(checkOut);
    const difValue = (Number(dateEnd) - Number(dateStart)) / (1000 * 60 * 60 * 24);
    let showmore = false;
    if (dailyPrice && dailyPrice.length > 2) {
        showmore = true;
    }
    const [more, setMore] = useState<Boolean>(showmore);
    let dailyPriceList = [] as any[];
    if (dailyPrice) {
        for (let i = 0; i < difValue; i++) {
            dailyPriceList.push({
                inTime: formatDate(addDays(new Date(dateStart), i), `MM月dd日`),
                dailyPrice: dailyPrice[i]
            });
        }
    }
    const renderIten =()=> {
        let list = [] as any[];
        if (dailyPriceList.length < 3) {
            list = dailyPriceList;
        } else {
            if (more) {
                list = dailyPriceList.splice(0,2);
            } else {
                list = dailyPriceList;
            }
        }
        return (
            list.map((item, index)=>
                <Flex
                    key={index}
                    mb='12px'
                    h='17px'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Text {...style.timeStyle}>{item.inTime}</Text>
                    <Text {...style.descStyle} textAlign='right'>¥ {item.dailyPrice}</Text>
                </Flex>    
            )
        )
    }
    return (
        <HStack
            display='block'
            as='div'
            bgColor='white'
            borderRadius='4px'
            p='16px'
            boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
        >
            <Text
                fontSize='18px'
                color='fontColor'
                lineHeight='25.2px'
                mb='12px'
                letterSpacing='0 !important'
            >
                {getI18NText('单晚单间报价')}{more}
            </Text>
            <Box
                borderTop='1px'
                borderTopStyle='dashed'
                borderTopColor='gray.400'
                p='16px 4px 4px 4px'
                marginInlineStart='0 !important'
            >
                {renderIten()}
                {
                    showmore &&
                    <Flex
                        h='20px'
                        alignItems='center'
                        justifyContent='flex-end'
                        cursor='pointer'
                        onClick={(e)=>setMore(!more)}
                    >
                        <Text
                            fontWeight='400'
                            fontSize='12px'
                            color='blue'
                        >
                            {more ? '查看所有报价' : '收起所有报价'}
                        </Text>
                        <Icon
                            as={more ? IoChevronDown : IoChevronUp}
                            boxSize='1rem'
                            ml='4px'
                            color='blue'
                        />
                    </Flex>
                }
            </Box>
        </HStack>
    )
}