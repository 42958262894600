import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    HStack,
    Image,
    Input,
    Text,
    useToast
} from '@chakra-ui/react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import filiterControlAtom from '~/recoil/atom/filiter';
import modalControlAtom from '~/recoil/atom/modal';
import debounce from 'lodash/debounce';
import {
  FetchError,
  SearchSuggestion,
  HotelSearchSuggestion,
  LocationType,
} from '~/types';
import {
    getToken
} from '~/utils/tools';
import getPoisSuggestions from '~/awsHttpApi/getPoisSuggestions';
import getHotelsSuggestions from '~/awsHttpApi/getHotelsSuggestions';
import LocationSearch from '../LocationSearch';
import LocationIcon from '~/assets/images/location.png';

import { useI18NText } from '~/i18n/i18n';

type LocationChooseProps = {
    location?: string;
    onLocationChange?: any,
    showLocation?: boolean,
}
export default function LocationChoose({
    location = '',
    onLocationChange,
    showLocation = false,
} : LocationChooseProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' });
    const setFiliterControl = useSetRecoilState(filiterControlAtom);
    const [
      {
        isEmptyLocationModalOpen
      },
      setModalControl,
    ] = useRecoilState(modalControlAtom);
    const [search, setSearch] = useState(true);
    const toast = useToast();
    const [searchLocation, setSearchLocation] = useState('');
    const [suggestionLists, setSuggestionList] = useState<Array<HotelSearchSuggestion>>([]);
    const [poisSuggestionsLists, setPoisSuggestionsList] = useState<Array<SearchSuggestion>>([]);

    const debouncedSearch = React.useRef(debounce((localtion) => {
        getSearch(localtion);
    }, 500))

    useEffect(() => {
        if (location && location.replace(/(^\s*)|(\s*$)/g, "") !== '') {
            debouncedSearch.current(location)
        }
    }, [location, search])

    const getSearch = async(searchText: string) => {
        const suggestionList: HotelSearchSuggestion[] = [];
        const poisSuggestionsList: SearchSuggestion[] = [];
        try {
            const [poisSuggestions, hotelsSuggestions] = await Promise.all([
                getPoisSuggestions({
                    token: getToken(),
                    keyword: searchText,
                    size: 3,
                }),
                getHotelsSuggestions({
                    token: getToken(),
                    keyword: searchText,
                    size: 6,
                }),
            ]);
            poisSuggestions.forEach(({ id, type, name, displayName }) => {
                poisSuggestionsList.push({
                    id,
                    name: name.zh_CN || name.en,
                    displayName: displayName.zh_CN || displayName.en,
                    type,
                });
            });
            hotelsSuggestions.forEach(({ name, hotelId, country, city}) => {
                suggestionList.push({
                    name,
                    hotelId,
                    country,
                    city
                });
            });
        } catch (err) {
            toast({
                position: 'top',
                status: 'error',
                title: FetchError.FETCH_HOTELS_ERROR,
            });
        }
        setSuggestionList(suggestionList);
        setPoisSuggestionsList(poisSuggestionsList);
    }
    const handleChange = (event:any) => {
        onLocationChange({
            name: event.target.value,
            type: 'input'
        });
        setSearch(true);
    }
    const onQuickChoose = (event:any) => {
        let data = {...event};
        data.type = 'click'; 
        onLocationChange(data);
        setSearch(true);
    }
    const onSuggestionChoose = (event:any) => {
        let data = {...event};
        data.type = 'click'; 
        onLocationChange(data);
    }
    const showSearchLocation = (event:any) => {
        event.nativeEvent.stopImmediatePropagation();
        setModalControl((prev) => ({ ...prev, isEmptyLocationModalOpen: false }));
        setFiliterControl((prev) => ({ ...prev, showLocation: true }));
        setFiliterControl((prev) => ({ ...prev, showPerson: false }));
        setFiliterControl((prev) => ({ ...prev, showDatePicker: false }));
    }
    return (
        <Flex
            cursor='pointer'
            position='relative'
        >
            <Image
                src={LocationIcon}
                w='24px'
                h='24px'
                mr='19.27px'
            />
            <HStack
                alignItems='center'
            >
                {
                    searchLocation == '' ? (
                        <Box>
                            {
                                isEmptyLocationModalOpen ? (
                                    <Text
                                        fontSize='16px'
                                        color='#FF6770'
                                        fontWeight='400'
                                        lineHeight='22.4px'
                                    >
                                        请输入城市或酒店名称
                                    </Text>
                                ) : (
                                    <Input
                                        type='text'
                                        placeholder={getI18NText('今天想去哪一个城市？')}
                                        border='none'
                                        paddingInlineStart='0 !important'
                                        h='22px'
                                        value={location}
                                        lineHeight='22px'
                                        onChange={(e)=>handleChange(e)}
                                        onClick={(e) => showSearchLocation(e)}
                                    />
                                )
                            }
                        </Box>
                    ) : (
                        <Text
                            fontSize='16px'
                            letterSpacing='0 !important'
                            color='blue'
                        >
                            {location}
                        </Text>
                    )
                }
            </HStack>
            {
                showLocation &&
                <LocationSearch
                    locationName={location}
                    suggestionList={suggestionLists}
                    poisSuggestionsList={poisSuggestionsLists}
                    onQuickChoose={(e:any)=>onQuickChoose(e)}
                    onSuggestionChoose={(e:any)=>onSuggestionChoose(e)}
                    top='40px'
                />
            }
        </Flex>
    )
}