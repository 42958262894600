import { atom } from 'recoil';

const modalControl = atom({
  key: 'globalModalControl',
  default: {
    isWechatPayModalOpen: false,
    isPayTipModalOpen: false,
    isLoginOutModalOpen: false,
    isEmptyLocationModalOpen: false,
    isSureChangePwdModal: false,
    isSureChangeUserInfoModal: false,
  },
});

export default modalControl;