import axios from 'axios';
import i18n from '../i18n/i18n';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

axios.interceptors.request.use((config) => {
  if (!config.headers) config.headers = {};
  config.headers['i18n-language'] = i18n.language || 'zh'; // 当前选择的语言
  config.timeout = 20000;
  return config;
});
axios.interceptors.response.use(
  (data) => {
    return data;
  },
  (err) => {
    if (err.response?.status == 403) {
      toast.error('登录失效,请重新登录', {
        position: 'top-center',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        localStorage.clear();
        let url = `${window.location.host}`;
        if (!url.startsWith('http')) {
          url = `http://${url}/login`;
        }
        window.location.href = url;
      }, 500);
    }
    return Promise.resolve(err);
  },
);
