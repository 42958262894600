import axios, { AxiosError } from 'axios';
import * as queryString from 'query-string';

import { Locale, LocationType } from '~/types';

type GetPoisSuggestionsRequest = {
  token: string;
  keyword: string;
  size?: number;
};

type PoiSuggesiton = {
  id: string;
  type: LocationType;
  name: Record<Locale, string>;
  displayName: Record<Locale, string>;
};

export default async ({
  token,
  keyword,
  size = 5,
}: GetPoisSuggestionsRequest): Promise<PoiSuggesiton[]> => {
  try {
    const poisResponse = await axios.get<{
      suggestions: PoiSuggesiton[];
    }>(
      `${process.env.REACT_APP_AWS_HTTP_BASE_URL}/poi/suggestions?${queryString.stringify({
        keyword,
        size,
      })}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return poisResponse.data.suggestions;
  } catch (error) {
    const poiFailedError = error as AxiosError<{ errorCode: string; message: string }>;

    poiFailedError.response
      ? console.error('Request failed for get poi suggestions api: ', poiFailedError.response.data)
      : console.error('Request failed for get poi suggestions api: ', error);

    return [];
  }
};
