import axios, { AxiosError } from 'axios';
import { HttpApiErrorCode } from '~/types';

export async function createBooking(
  JWTToken: string,
  formData: any,
): Promise<{
  bookingId?: string;
  errorCode?: HttpApiErrorCode;
  paymentURL?: any,
  invalidTime?: number | string
}> {
  try {
    const bookingResponse = await axios.post<{
      bookingId: string;
      paymentURL: any;
      invalidTime: number | string
    }>(`${process.env.REACT_APP_AWS_HTTP_BASE_URL}/booking`, formData, {
      headers: {
        Authorization: `Bearer ${JWTToken}`,
      },
    });

    const { bookingId, paymentURL, invalidTime } = bookingResponse.data;
    return {
      bookingId,
      paymentURL,
      invalidTime
    };
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;
    if (bookingFailedError.response) {
      const { errorCode, message } = bookingFailedError.response.data;

      console.error('Request faile for create booking api: ', message);
      return {
        errorCode: errorCode as HttpApiErrorCode,
      };
    }

    return {
      errorCode: HttpApiErrorCode.UNEXPECTED_ERROR,
    };
  }
}
