import axios from 'axios';

type AuthMemberRequest = {
  token: string;
};

export default async ({ token }: AuthMemberRequest): Promise<any | null> => {
  try {
    const res = await axios.get<{}>(
      `${process.env.REACT_APP_AWS_HTTP_BASE_URL}/auth/member`, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    return false;
  }
};
