import { createContext, useState, useEffect } from 'react';
import {
  Box
} from '@chakra-ui/react';
import formatDate from 'date-fns/format';
import addDays from 'date-fns/addDays';
import parseDate from 'date-fns/parse';
import compareAsc from 'date-fns/compareAsc';
import Desktop from '~/components/Datepicker/Desktop';
import { CalendarDate } from '~/components/Datepicker/type';
import format from 'date-fns/format';

type DateRangerPickerProps = {
  showBefore: boolean;
  onChangeValue?: any,
  start?: string;
  end?: string;
}
export default function DateRangerPicker({
  start = '',
  end = '',
  onChangeValue,
  showBefore = false,
}:DateRangerPickerProps) {
  let startData = '';
  let endData = '';
  if (!showBefore) {
    if (start != '') {
      startData = start.replaceAll('\-', '\/');
    } else {
      startData = format(new Date(), 'yyyy/MM/dd');
    }
    if (end != '') {
      endData = end.replaceAll('\-', '\/');
    } else {
      endData = format(new Date(), 'yyyy/MM/dd');
    }
  }
  
  const [rangeDate, setRangeDate] = useState<CalendarDate>({
    start: startData,
    end: endData,
  });
  useEffect(()=>{
    console.log('rangeDate', rangeDate)
    onChangeValue(rangeDate)
  }, [rangeDate]);
  return (
    <Box>
      <Desktop
        showBefore={showBefore}
        rangeDate={rangeDate}
        onSetRangeDate={setRangeDate}
      />
    </Box>
  )
};
