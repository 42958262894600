import { atom } from 'recoil';

const payControl = atom({
  key: 'globalPayControl',
  default: {
    paymentURL: {},
    payOrderNo: '',
    payPrice: 0,
    payTime: 0,
    refreshOrder: false,
  },
});

export default payControl;
