import { useState, useEffect, useRef } from 'react';
import {
    Badge,
    Button,
    Box,
    Divider,
    HStack,
    Flex,
    Stack,
    VStack,
    Text,
    Image,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import PageContainer from '~/containers/PageContainer';
import ChangePwd from '~/components/ChangePwd';
import ChangeUserInfo from '~/components/ChangeUserInfo';
import { useSetRecoilState, useRecoilState } from 'recoil';
import modalControlAtom from '~/recoil/atom/modal';
import authMember from '~/awsHttpApi/authMember';
import authUpdatepassword from '~/awsHttpApi/authUpdatepassword';
import {
    getToken,
} from '~/utils/tools';
import { Helmet } from 'react-helmet';

const AgencyVerificationStatus:any = {
    'unverified': {
        text: '未认证',
        color: '#C4C4C4'
    },
    'verifying': {
        text: '认证中',
        color: '#FF6770'
    },
    'verified': {
        text: '已认证',
        color: '#59CFD5'
    }
}
const AgencyStatus:any = {
    'inactived': {
        text: '未开启',
        color: '#C4C4C4'
    },
    'actived': {
        text: '正常',
        color: '#59CFD5'
    },
    'locked': {
        text: '锁定',
        color: '#FF6770'
    }
}
export default function UserCenter() {
    const toast = useToast();
    const [
        {
            isSureChangePwdModal,
            isSureChangeUserInfoModal
        },
        setModalControl,
    ] = useRecoilState(modalControlAtom);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [ show, setShow] = useState<boolean>(false);
    const [ loaded, setLoaded] = useState<boolean>(false);
    const [ userInfo, setUserInfo ] = useState<any>({});
    useEffect(()=>{
        if (!loaded) {
            getMember();
            setLoaded(true);
        }
    }, [loaded])
    const getMember = () => {
        authMember({
            token: getToken(),
        }).then(res=>{
            console.log('res', res);
            setUserInfo(res);
        })
    }
    const onChangePwd = (event:any) => {
        console.log('event', event);
        let {
            oldPassword,
            newPassword,
        } = event;
        let params = {
            token: getToken(),
            oldPassword,
            newPassword,
        }
        authUpdatepassword(params)
            .then(res=>{
                console.log('res', res);
                if (res.message == 'ok') {
                    setModalControl((prev) => ({ ...prev, isSureChangePwdModal: true }));
                } else {
                    toast({
                        title: res.message,
                        status: 'error',
                        isClosable: true,
                        duration: 1000,
                        position: 'top'
                    });
                }
            })
        // setModalControl((prev) => ({ ...prev, isSureChangePwdModal: true }));
    }
    const onChangeUserInfo = (event:any) => {
        setModalControl((prev) => ({ ...prev, isSureChangeUserInfoModal: false }));
    }
    return (
        <>
            <Helmet>
                <meta charSet="description" content="多会儿旅行用户中心" />
                <title>用户中心｜多会儿旅行</title>
            </Helmet>
            <PageContainer
                topBoxStyle={{ position: 'static' }}
                afterHeaderComponent={<></>}
            >
                {
                    loaded &&
                    <Box
                        mx='auto'
                        w='1121px'
                    >
                        <Flex
                            pt='106px'
                            justifyContent='space-between'
                            pb='236px'
                        >
                            <Flex
                                w='267px'
                                h='47px'
                                marginInlineStart='0 !important'
                                bgColor='#EBEEF5'
                                overflow='hidden'
                                borderRadius='0 4px 4px 0'
                            >
                                <Box
                                    marginInlineStart='0 !important'
                                    w='7px'
                                    h='47px'
                                    bgColor='#002C5E'
                                    borderRadius='4px 0 0 4px !important'
                                ></Box>
                                <Text
                                    w='260px'
                                    h='47px'
                                    lineHeight='47px'
                                    marginInlineStart='0 !important'
                                    pl='16px'
                                    bgColor='#EBEEF5'
                                    color='blue'
                                    fontSize='22px'
                                    fontWeight='500'
                                    letterSpacing='0 !important'
                                >
                                    用户中心
                                </Text>
                            </Flex>
                            <Box
                                marginInlineStart='0 !important'
                                w='830px'
                            >
                                <Text
                                    h='44px'
                                    pl='16px'
                                    borderRadius='4px'
                                    bgColor='#EBEEF5'
                                    color='blue'
                                    fontSize='22px'
                                    fontWeight='500'
                                    mb='8px'
                                    lineHeight='44px'
                                    letterSpacing='0 !important'
                                >
                                    用户信息
                                </Text>
                                <Flex
                                    marginInlineStart='0 !important'
                                    alignItems='center'
                                    justifyContent='space-between'
                                    h='79px'
                                    px='24px'
                                    mb='24px'
                                    boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
                                    borderRadius='4px'
                                    bgColor='white'
                                >
                                    <Text
                                        color='#000000'
                                        fontSize='22px'
                                        fontWeight='400'
                                        letterSpacing='0 !important'
                                    >
                                        {userInfo?.contactPhone} 
                                    </Text>
                                    <Text
                                        fontSize='12px'
                                        color='blue'
                                        fontWeight='400'
                                        cursor='pointer'
                                        onClick={onOpen}
                                        letterSpacing='0 !important'
                                    >
                                        变更密码
                                    </Text>
                                </Flex>
                                <Text
                                    h='44px'
                                    lineHeight='44px'
                                    pl='16px'
                                    borderRadius='4px'
                                    bgColor='#EBEEF5'
                                    color='blue'
                                    fontSize='22px'
                                    fontWeight='500'
                                    mb='8px'
                                    letterSpacing='0 !important'
                                >
                                    企业信息
                                </Text>
                                <Box
                                    p='24px 24px 30px 24px'
                                    boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
                                    borderRadius='4px'
                                    bgColor='white'
                                    h='240px'
                                    marginInlineStart='0 !important'
                                >
                                    <Flex
                                        pb='16px'
                                        h='31px'
                                        alignItems='center'
                                    >
                                        <Text
                                            marginInlineStart='0 !important'
                                            fontSize='22px'
                                            mr='16px'
                                            color='#000000'
                                            letterSpacing='0 !important'
                                        >
                                        {userInfo?.agencyName}
                                        </Text>
                                        <Text
                                            marginInlineStart='0 !important'
                                            fontSize='14px'
                                            mr='4px'
                                            color='#8B8B8B'
                                            letterSpacing='0 !important'
                                        >
                                        企业状态 
                                        </Text>
                                        <Text
                                            marginInlineStart='0 !important'
                                            fontSize='12px'
                                            mr='16px'
                                            color='white'
                                            bgColor={AgencyStatus[userInfo?.agencyStatus]?.color}
                                            w='47px'
                                            textAlign='center'
                                            h='20px'
                                            lineHeight='20px'
                                            borderRadius='4px'
                                            letterSpacing='0 !important'
                                        >
                                            {AgencyStatus[userInfo?.agencyStatus]?.text}
                                        </Text>
                                        <Text
                                            marginInlineStart='0 !important'
                                            fontSize='14px'
                                            mr='4px'
                                            color='#8B8B8B'
                                            letterSpacing='0 !important'
                                        >
                                        企业认证
                                        </Text>
                                        <Text
                                            marginInlineStart='0 !important'
                                            fontSize='12px'
                                            color='white'
                                            bgColor={AgencyVerificationStatus[userInfo?.vertifyStatus]?.color}
                                            w='46px'
                                            textAlign='center'
                                            h='20px'
                                            lineHeight='20px'
                                            borderRadius='4px'
                                            letterSpacing='0 !important'
                                        >
                                            {AgencyVerificationStatus[userInfo?.vertifyStatus]?.text}
                                        </Text>
                                    </Flex>
                                    <Divider orientation='horizontal' bgColor='#F2F2F2' mb='22px' />
                                    <Flex
                                        justifyContent='space-between'
                                        mb='28px'
                                    >
                                        <Box
                                            marginInlineStart='0 !important'
                                            flex='1'
                                        >
                                            <Text
                                                h='20px'
                                                mb='4px'
                                                color='#8B8B8B'
                                                fontSize='14px'
                                                letterSpacing='0 !important'
                                            >
                                                企业ID
                                            </Text>
                                            <Text
                                                h='20px'
                                                color='#002C5E'
                                                fontSize='14px'
                                                letterSpacing='0 !important'
                                            >
                                                {userInfo?.agencyId}
                                            </Text>
                                        </Box>
                                        <Box
                                            marginInlineStart='0 !important'
                                            flex='1'
                                        >
                                            <Text
                                                h='20px'
                                                mb='4px'
                                                color='#8B8B8B'
                                                fontSize='14px'
                                                letterSpacing='0 !important'
                                            >
                                                联系人
                                            </Text>
                                            <Text
                                                h='20px'
                                                color='#002C5E'
                                                fontSize='14px'
                                                letterSpacing='0 !important'
                                            >
                                                {userInfo?.contactName || '-'} 
                                            </Text>
                                        </Box>
                                        <Box
                                            marginInlineStart='0 !important'
                                            flex='1'
                                        >
                                            <Text
                                                h='20px'
                                                mb='4px'
                                                color='#8B8B8B'
                                                fontSize='14px'
                                                letterSpacing='0 !important'
                                            >
                                                联系电话
                                            </Text>
                                            <Text
                                                h='20px'
                                                color='#002C5E'
                                                fontSize='14px'
                                                letterSpacing='0 !important'
                                            >
                                                {userInfo?.contactPhone || '-'} 
                                            </Text>
                                        </Box>
                                    </Flex>
                                    <Flex
                                        justifyContent='space-between'
                                    >
                                        <Box
                                            marginInlineStart='0 !important'
                                            flex='1'
                                        >
                                            <Text
                                                h='20px'
                                                mb='4px'
                                                color='#8B8B8B'
                                                fontSize='14px'
                                                letterSpacing='0 !important'
                                            >
                                                地址
                                            </Text>
                                            <Text
                                                h='20px'
                                                color='#002C5E'
                                                fontSize='14px'
                                                letterSpacing='0 !important'
                                            >
                                                {userInfo?.agencyAddress || '-'}
                                            </Text>
                                        </Box>
                                        <Box
                                            marginInlineStart='0 !important'
                                            flex='1'
                                        >
                                            <Text
                                                h='20px'
                                                mb='4px'
                                                color='#8B8B8B'
                                                fontSize='14px'
                                                letterSpacing='0 !important'
                                            >
                                                统一社会信用代码
                                            </Text>
                                            <Text
                                                h='20px'
                                                color='#002C5E'
                                                fontSize='14px'
                                                letterSpacing='0 !important'
                                            >
                                                {userInfo?.agencyUniqueId || '-'}
                                            </Text>
                                        </Box>
                                        <Box
                                            marginInlineStart='0 !important'
                                            flex='1'
                                        >
                                            <Text
                                                h='20px'
                                                mb='4px'
                                                color='#8B8B8B'
                                                fontSize='14px'
                                                letterSpacing='0 !important'
                                            >
                                                邮箱
                                            </Text> 
                                            <Text
                                                h='20px'
                                                color='#002C5E'
                                                fontSize='14px'
                                                letterSpacing='0 !important'
                                            >
                                                {userInfo?.agencyEmail || '-'}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                }
                <ChangePwd
                    isOpen={isOpen} 
                    onClose={onClose}
                    onChangePwd={(e:any)=>onChangePwd(e)}
                />
                <ChangeUserInfo
                    isOpen={show} 
                    onClose={(e:any)=>setShow(false)}
                    onChangeUserInfo={(e:any)=>onChangeUserInfo(e)}
                />
            </PageContainer>
        </>
    )
}