import React from 'react';
import { Center, SimpleGrid } from '@chakra-ui/react';

import { format, addDays, parse, getTime, addYears, startOfDay } from 'date-fns';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

import { getDateList } from '../utils/getDateList';
import GrayDay from './days/GrayDay';
import Day from './days';

import type { CalendarDate } from '../type';

type CalendarProps = {
  showBefore?: boolean;
  year: number;
  month: number;
  rangeDate: CalendarDate;
  onSetRangeDate: (rangeDate: CalendarDate) => void;
};

const Calendar: React.FC<CalendarProps> = ({ showBefore = false, year, month, rangeDate, onSetRangeDate }) => {
  const calendarDays = getDateList(year, month);

  const startTime = getTime(parse(rangeDate.start, 'yyyy/MM/dd', new Date()));
  const endTime = rangeDate.end ? getTime(parse(rangeDate.end, 'yyyy/MM/dd', new Date())) : null;

  const todayTime = getTime(new Date(addDays(new Date(), -1)));
  const todayStr = format(startOfDay(new Date()), 'yyyy/MM/dd');

  const nextYear = getTime(addYears(new Date(), 1));

  const afterStartTime28Days = getTime(addDays(startTime, 28));

  const beforeStartTime28Days = getTime(addDays(startTime, -28));

  const handlePickDate = (time: number, type: 'start' | 'end' | 'restart') => {
    const formattedDate = format(new Date(time), 'yyyy/MM/dd');

    switch (type) {
      case 'start':
        return onSetRangeDate({ ...rangeDate, start: formattedDate });

      case 'end':
        if (time < startTime) {
          return onSetRangeDate({
            start: formattedDate,
            end: rangeDate.start,
          });
        }

        return onSetRangeDate({ ...rangeDate, end: formattedDate });

      case 'restart':
        return onSetRangeDate({ start: formattedDate, end: null });

      default:
        return;
    }
  };

  return (
    <SimpleGrid columns={7}>
      {calendarDays.map(({ day, time, formatted, isThisMonth }) => {
        const isBeforeToday = time < todayTime;
        const isAfterStartTime28Days = startTime && time > afterStartTime28Days;
        const isBeforeStartTime28Days = startTime && time < beforeStartTime28Days;
        const isAfter365Days = time > nextYear;

        const currentTime = format(
          startOfDay(
            utcToZonedTime(new Date(time), Intl.DateTimeFormat().resolvedOptions().timeZone),
          ),
          'yyyy/MM/dd',
        );

        const isToday = currentTime === todayStr;

        if (!isThisMonth) {
          return <Center key={formatted} w={10} h={10} />;
        }

        if ((!showBefore && isBeforeToday) || isAfter365Days) {
          return <GrayDay key={formatted} day={day} />;
        }

        if (!rangeDate.end && (isAfterStartTime28Days || isBeforeStartTime28Days)) {
          return <GrayDay key={formatted} day={day} />;
        }

        return (
          <Day
            key={formatted}
            startTime={startTime}
            endTime={endTime}
            day={day}
            time={time}
            formatted={formatted}
            onUpdateDate={handlePickDate}
          />
        );
      })}
    </SimpleGrid>
  );
};

export default Calendar;
