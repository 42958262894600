import { useState } from 'react';
import {
    Box,
    Button,
    Center,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Input,
    Text,
} from '@chakra-ui/react';
import { useI18NText } from '~/i18n/i18n';
import HideIcon from '~/assets/images/hide.png';
import ShowIcon from '~/assets/images/show.png';

type ChangeUserInfoModalProps = {
    isOpen: boolean;
    onClose?:any;
    onChangeUserInfo?:any
};
export default function ChangeUserInfo({ isOpen, onClose, onChangeUserInfo }: ChangeUserInfoModalProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' });
    const [connact, setConnact] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [IdNo, setIdNo] = useState('');
    const [email, setEmail] = useState('');
    const [error1, setError1] = useState<Boolean>(false);
    const [error2, setError2] = useState<Boolean>(false);
    const [error3, setError3] = useState<Boolean>(false);
    const [error4, setError4] = useState<Boolean>(false);
    const [error5, setError5] = useState<Boolean>(false);
    const Submit = (event:any) => {
        let emailReg=/^(\w|(\.\w+))+@([a-zA-Z0-9_-]+\.)+(com|org|cn|net)+$/;  
        if (connact == '') {
            setError1(true);
            return;
        }
        if (phone == '') {
            setError2(true);
            return;
        }
        if (address == '') {
            setError3(true);
            return;
        }
        if (IdNo == '') {
            setError4(true);
            return;
        }
        if (!emailReg.test(email)) {
            setError5(true);
            return;
        }
        onChangeUserInfo({
            connact,
            phone,
            address,
            IdNo,
            email
        });
    }
    const changeValue = (type: string, event:any) => {
        let value = event.target.value;
        switch (type) {
            case 'connact':
                setConnact(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'address':
                setAddress(value)
                break;
            case 'IdNo':
                setIdNo(value)
                break;
            case 'email':
                setEmail(value)
                break;
            default:
                setConnact(value);
                break;
        }
    }
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="sm"
            isCentered
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody
                    py='36px'
                    px='37.5px'
                >
                    <Text
                        textAlign='center'
                        fontSize='22px'
                        fontWeight='500'
                        color='blue'
                        mb='19px'
                        h='29px'
                        lineHeight='29px'
                    >
                        {getI18NText('编辑信息')}
                    </Text>
                    <Box
                        marginInlineStart='0 !important'
                        pos='relative'
                        mb='18px'
                    >
                        <Text
                            textAlign='left'
                            h='20px'
                            lineHeight='20px'
                            fontSize='14px'
                            fontWeight='400'
                            color='#333333'
                            mb='4px'
                        >
                            {getI18NText('联系人')}
                        </Text>
                        <Input
                            fontSize='14px'
                            color='#002C5E'
                            placeholder='请输入联系人名称'
                            h='40px'
                            lineHeight='40px'
                            borderRadius='4px'
                            borderWidth='1px'
                            borderStyle='solid'
                            borderColor={error1 ? '#FF6770' : '#888888'}
                            value={connact}
                            onChange={(e) => changeValue('connact', e)}
                        />
                        {
                            error1 &&
                            <Text
                                pos='absolute'
                                right='0'
                                top='68px'
                                color='#FF6770'
                                fontSize='12px'
                            >
                                {getI18NText('联系人不得为空，仅支援英、汉拼音')}
                            </Text>
                        }
                    </Box>
                    <Box
                        marginInlineStart='0 !important'
                        pos='relative'
                        mb='18px'
                    >
                        <Text
                            textAlign='left'
                            h='20px'
                            lineHeight='20px'
                            fontSize='14px'
                            fontWeight='400'
                            color='#333333'
                            mb='4px'
                        >
                            {getI18NText('联系电话')}
                        </Text>
                        <Input
                            fontSize='14px'
                            color='#002C5E'
                            placeholder='请输入联系電話'
                            h='40px'
                            lineHeight='40px'
                            borderRadius='4px'
                            borderWidth='1px'
                            borderStyle='solid'
                            borderColor={error2 ? '#FF6770' : '#888888'}
                            value={phone}
                            onChange={(e) => changeValue('phone', e)}
                        />
                        {
                            error2 &&
                            <Text
                                pos='absolute'
                                right='0'
                                top='68px'
                                color='#FF6770'
                                fontSize='12px'
                            >
                                {getI18NText('仅支援数字输入')}
                            </Text>
                        }
                    </Box>
                    <Box
                        marginInlineStart='0 !important'
                        pos='relative'
                        mb='33px'
                    >
                        <Text
                            textAlign='left'
                            h='20px'
                            lineHeight='20px'
                            fontSize='14px'
                            fontWeight='400'
                            color='#333333'
                            mb='4px'
                        >
                            {getI18NText('地址')}
                        </Text>
                        <Input
                            fontSize='14px'
                            color='#002C5E'
                            placeholder='请输入企业的地址'
                            h='40px'
                            lineHeight='40px'
                            borderRadius='4px'
                            borderWidth='1px'
                            borderStyle='solid'
                            borderColor={error3 ? '#FF6770' : '#888888'}
                            value={address}
                            onChange={(e) => changeValue('address', e)}
                        />
                        {
                            error3 &&
                            <Text
                                pos='absolute'
                                right='0'
                                top='68px'
                                color='#FF6770'
                                fontSize='12px'
                            >
                                {getI18NText('不得输入特殊符号')}
                            </Text>
                        }
                    </Box>
                    <Box
                        marginInlineStart='0 !important'
                        pos='relative'
                        mb='18px'
                    >
                        <Text
                            textAlign='left'
                            h='20px'
                            lineHeight='20px'
                            fontSize='14px'
                            fontWeight='400'
                            color='#333333'
                            mb='4px'
                        >
                            {getI18NText('统一社会信用代码')}
                        </Text>
                        <Input
                            fontSize='14px'
                            color='#002C5E'
                            placeholder='请输入企业的统一认证'
                            h='40px'
                            lineHeight='40px'
                            borderRadius='4px'
                            borderWidth='1px'
                            borderStyle='solid'
                            borderColor={error4 ? '#FF6770' : '#888888'}
                            value={IdNo}
                            onChange={(e) => changeValue('IdNo', e)}
                        />
                        {
                            error4 &&
                            <Text
                                pos='absolute'
                                right='0'
                                top='68px'
                                color='#FF6770'
                                fontSize='12px'
                            >
                                {getI18NText('仅支援英、数字输入')}
                            </Text>
                        }
                    </Box>
                    <Box
                        marginInlineStart='0 !important'
                        pos='relative'
                        mb='18px'
                    >
                        <Text
                            textAlign='left'
                            h='20px'
                            lineHeight='20px'
                            fontSize='14px'
                            fontWeight='400'
                            color='#333333'
                            mb='4px'
                        >
                            {getI18NText('邮箱')}
                        </Text>
                        <Input
                            type={error5 ? 'text': 'password'}
                            fontSize='14px'
                            color='#002C5E'
                            placeholder='请输入可用的邮箱'
                            h='40px'
                            lineHeight='40px'
                            borderRadius='4px'
                            borderWidth='1px'
                            borderStyle='solid'
                            borderColor={error5 ? '#FF6770' : '#888888'}
                            value={email}
                            onChange={(e) => changeValue('email', e)}
                        />
                        {
                            error5 &&
                            <Text
                                pos='absolute'
                                right='0'
                                top='68px'
                                color='#FF6770'
                                fontSize='12px'
                            >
                                {getI18NText('输入可使用的邮箱地址')}
                            </Text>
                        }
                    </Box>
                    <Center
                        mt='16px'
                    >
                        <Button
                            w='96px'
                            h='48px'
                            lineHeight='48px'
                            bgColor='white'   
                            textAlign='center'
                            color='blue'
                            borderWidth='2px'
                            borderColor='blue'
                            fontSize='14px'
                            mr='16px'
                            _hover={{
                                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #ffffff'
                            }}
                            onClick={onClose}
                        >
                            {getI18NText('取消')}
                        </Button>
                        <Button
                            w='96px'
                            h='48px'
                            lineHeight='48px'
                            bgColor='blue'   
                            textAlign='center'
                            color='white'
                            fontSize='14px'
                            _hover={{
                                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #002C5E'
                            }}
                            onClick={(e)=>Submit(e)}
                        >
                            {getI18NText('确认')}
                        </Button>
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
