import { CancelPolicy } from '~/types';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import parseISO from 'date-fns/parseISO';
export default function searchRecentCancelPolicy(
  policies: Array<CancelPolicy>,
): CancelPolicy | null {
  const today = new Date();

  return policies.reduce((acc: CancelPolicy | null, policy) => {
    const timeDiff = differenceInMinutes(parseISO(policy.endAt), today);

    if (timeDiff > 0 && acc !== null) {
      return differenceInMinutes(parseISO(policy.endAt), parseISO(acc.endAt)) < 0 ? policy : acc;
    }

    if (timeDiff > 0) {
      return policy;
    }

    return acc;
  }, null);
}
