import axios, { AxiosError } from 'axios';
import { parseISO } from 'date-fns';
import { Booking, BookingFromApi } from '~/types';

type CancelBookingRequest = {
  token: string;
  bookingId: string;
};

export default async ({ token, bookingId }: CancelBookingRequest): Promise<Booking | null> => {
  try {
    const cancelBookingResponse = await axios.patch<BookingFromApi>(
      `${process.env.REACT_APP_AWS_HTTP_BASE_URL}/booking/${bookingId}/cancel`,
      '',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const bookingFromApi = cancelBookingResponse.data;
    const { createdAt, updatedAt } = bookingFromApi;

    console.log('bookingFromApi', bookingFromApi);
    return {
      ...bookingFromApi,
      createdAt: parseISO(createdAt),
      updatedAt: parseISO(updatedAt),
    };
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;

    bookingFailedError.response
      ? console.error('Request failed for cancel booking api: ', bookingFailedError.response.data)
      : console.error('Request failed for cancel booking api: ', error);

    return null;
  }
};
