import React, { useState } from 'react';
import background from '~/assets/images/background.png';
import forgetPassword from '~/assets/images/tipIcon.png';
import { AiOutlineEyeInvisible, AiOutlineEye, AiOutlinePhone } from 'react-icons/ai';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Checkbox,
  Input,
  InputGroup,
  FormControl,
  InputRightElement,
  Text,
  Icon,
  useToast,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { Navigate, useNavigate } from 'react-router-dom';

// import LoginPassword from '~/components/Login/Password';

import useAuthingClient from '~/hooks/useAuthingClient';

import { useI18NText } from '~/i18n/i18n';
import { Controller, useForm } from 'react-hook-form';
import './index.css';
import LayoutHeader from '~/containers/Header';
import LayoutFooter from '~/containers/Footer';
import { getToken } from '~/utils/tools';
import verifyUser from '~/awsHttpApi/verifyUser';

type FormData = {
  username: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'login.' });
  const [isSeePassword, setIsSeePassword] = useState<boolean>(false);
  const [isOpen, toggleModle] = useState(false);
  const {
    login,
    logout,
    checkIfLoggedIn,
    // getIdToken
  } = useAuthingClient();
  const toast = useToast();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  // //判断是否可以登录
  // const onClickLogin = () => {
  //   return new Promise<void>((resolve) => {
  //     setTimeout(() => {
  //       //输入框不为空，并且正则匹配全部成功，则跳转
  //       !errors.passWord && !errors.phoneNumber && getValues('passWord') && getValues('phoneNumber')
  //         ? navigate('/')
  //         : navigate('/login');
  //     }, 1000);
  //   });
  // };


  const onSubmit = async ({ username, password }: FormData) => {
    try {
      await login({
        username,
        password,
      });
      // const token = await getIdToken();
      const token = getToken();
      const isValidUser = await verifyUser({ token: token || '' });
      if (!isValidUser) {
        await logout();
        // eslint-disable-next-line no-throw-literal
        throw { code: 999, message: getI18NText('用户无法登入，请洽客服') };
      }

      document.title = "多会儿旅行";
      navigate('/');
    } catch (error) {
      // const { message } = error as { code: number; message: string };
      setError('username', {
        message: '',
      });
      setError('password', {
        message: '',
      });

      toast({
        position: 'top',
        status: 'error',
        title: getI18NText('登入发生错误，请洽客服'),
      });
    }
  };

  const handleRegister = () => {
    window.open('https://www.tripintl.com/');
  };

  const handleIsSeePassword = () => {
    setIsSeePassword((prev) => !prev);
  };

  if (checkIfLoggedIn()) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <>
      <Helmet>
        {/* 关于我们 - 多会儿旅行 */}
        <meta charSet="description" content="登入多会儿旅行" />
        <title>多会儿旅行 - 登入</title>
      </Helmet>
      <LayoutHeader />
      <div className="login-page">
        <Modal
          id="login-page-foget-password-modal"
          isOpen={isOpen}
          onClose={() => toggleModle}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <div
                className="title-icon"
                style={{
                  backgroundImage: `url(${forgetPassword})`,
                }}
              ></div>
              <div className="title-text">{getI18NText('忘记密码')}</div>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>请联系客服电话 +86 13810248624 ，将有专人服务</ModalBody>
            <ModalFooter>
              <Button
                fontSize="sm"
                fontWeight="normal"
                variant="outline"
                onClick={() => toggleModle(!isOpen)}
                color="#002C5E"
                borderColor="#002C5E"
                _hover={{ bgColor: '#EDF2F7' }}
              >
                {getI18NText('取消返回')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <div
          className="background-wrapper"
          style={{
            backgroundImage: `url(${background})`,
          }}
        ></div>
        <div className="login-form-wrapper">
          <div className="login-form-intro">
            {getI18NText('让你简单地')}
            <br />
            {getI18NText('获取更优质的资源')}
          </div>
          <div className="login-form">
            <div className="login-form-content">
              <div className="login-form-title">{getI18NText('登入')}</div>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* username */}
                <Controller
                  name="username"
                  control={control}
                  rules={{ required: getI18NText('无用户名') }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl mb="4" id="phonInPasswordLogin" isInvalid={!!error?.message}>
                      <InputGroup>
                        <Input
                          {...field}
                          type="text"
                          fontSize="sm"
                          h="14"
                          borderWidth="1px"
                          borderColor="#8b8b8b"
                          placeholder={getI18NText('请输入用户名')}
                          isInvalid={!!error}
                          errorBorderColor="trip.danger"
                        />
                        <InputRightElement
                          h="full"
                          children={<Icon as={AiOutlinePhone} boxSize={5} color="trip.primary" />}
                        />
                      </InputGroup>
                      <FormErrorMessage mt="0">{error?.message}</FormErrorMessage>
                      {/* <SlideFade in={!!error} offsetY="10px">
                        <Text fontSize="xs" textAlign="end" color="trip.danger">
                          {error?.message}
                        </Text>
                      </SlideFade> */}
                    </FormControl>
                  )}
                />

                {/* password */}
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: getI18NText('错误密码') }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl mb="6" id="phone" isInvalid={!!error?.message}>
                      <InputGroup>
                        <Input
                          {...field}
                          type={isSeePassword ? 'text' : 'password'}
                          fontSize="sm"
                          h="14"
                          borderWidth="1px"
                          borderColor="#8b8b8b"
                          placeholder={getI18NText('请输入登陆密码')}
                          isInvalid={!!error}
                          errorBorderColor="trip.danger"
                        />
                        <InputRightElement
                          h="full"
                          children={
                            <Icon
                              as={isSeePassword ? AiOutlineEye : AiOutlineEyeInvisible}
                              boxSize={5}
                              color="trip.primary"
                              cursor="pointer"
                              onClick={handleIsSeePassword}
                            />
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>{error?.message}</FormErrorMessage>

                      {/* <SlideFade in={!!error} offsetY="10px">
                        <Text fontSize="xs" textAlign="end" color="trip.danger">
                          {error?.message}
                        </Text>
                      </SlideFade> */}
                    </FormControl>
                  )}
                />

                {/* password login */}
                <Button
                  w="100%"
                  bgColor="#002c5e"
                  _hover={{ bgColor: '#002c5e80' }}
                  h="14"
                  mb="4"
                  type="submit"
                >
                  {getI18NText('登陆')}
                </Button>
              </form>
              <div className="login-form-actions">
                <Checkbox size="sm">{getI18NText('记住账号')}</Checkbox>
                <Text cursor="pointer" onClick={() => toggleModle(!isOpen)}>
                  {getI18NText('忘记密码？')}
                </Text>
              </div>
              <Button onClick={handleRegister} className="login-form-register">
                {getI18NText('还没注册嘛？立即注册')}
              </Button>
              <div className="login-form-service-term">
                {getI18NText('成为旅点用户，代表同意旅点服务条款')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LayoutFooter />
    </>
  );
};

export default Login;
