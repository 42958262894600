import axios, { AxiosError } from 'axios';
import { Hotel } from '~/types';

type GetHotelRequest = {
  token: string;
  hotelId: string;
};

export default async ({ token, hotelId }: GetHotelRequest): Promise<Hotel | null> => {
  if (!hotelId) {
    return null;
  }

  try {
    const hotelResponse = await axios.get<Hotel>(
      `${process.env.REACT_APP_AWS_HTTP_BASE_URL}/hotel/${hotelId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return hotelResponse.data;
  } catch (error) {
    const hotelFailedError = error as AxiosError<{ errorCode: string; message: string }>;

    hotelFailedError.response
      ? console.error('Request failed for get hotel api: ', hotelFailedError.response.data)
      : console.error('Request failed for get hotel api: ', error);

    return null;
  }
};
