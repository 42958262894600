import { ComponentStyleConfig } from '@chakra-ui/react';

const Text: ComponentStyleConfig = {
  baseStyle: {
    color: 'fontColor',
    letterSpacing: '0.1em',
  },
};

export default Text;
