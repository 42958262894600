import axios, { AxiosError } from 'axios';
import { Rate } from '~/types';

import cancelPolicyTransformation from '~/utils/cancelPolicyTransformation';

type GetHotelRatesRequest = {
  token: string;
  hotelId: string;
  checkIn: string;
  checkOut: string;
  group: {
    adults: number;
    children: number[];
  };
  roomCount: number;
};

export default async ({
  token,
  hotelId,
  checkIn,
  checkOut,
  group,
  roomCount,
}: GetHotelRatesRequest): Promise<Rate[]> => {
  let rates: Rate[] = [];
  try {
    const hotelRatesResponse = await axios.post<{
      rates: Rate[];
      message?: string;
    }>(
      `${process.env.REACT_APP_AWS_HTTP_BASE_URL}/getPriceInfo`,
      {
        hotelId,
        checkIn,
        checkOut,
        group,
        roomCount,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    rates = hotelRatesResponse.data?.rates || [];
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;

    bookingFailedError.response
      ? console.error('Request failed for get booking api: ', bookingFailedError.response.data)
      : console.error('Request failed for get booking api: ', error);
  }

  rates = rates.map(({ cancelPolicies, ...otherFields }) => ({
      ...otherFields,
      cancelPolicies: cancelPolicyTransformation(cancelPolicies),
    }))
    .sort(({ rateId: rateIdA, rateId: rateIdB }) => rateIdA.localeCompare(rateIdB));
  
  return rates;
};
