import {
    Flex,
    HStack,
    Icon,
    Image,
    Popover,
    Text,
} from '@chakra-ui/react';
import {
    IoChevronDownOutline
} from 'react-icons/io5';
import DateIcon from '~/assets/images/date.png';

import { useI18NText, getI18NTextOnce } from '~/i18n/i18n';

const getCurrentI18NTextOnce = (str: string) => getI18NTextOnce(`component.${str}`)

type CheckInTimeProps = {
    showIcon: boolean;
    start: string;
    end: string;
};

export default function CheckInTime({
    showIcon=true,
    start='',
    end='',
}:CheckInTimeProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })
    
    const caculatDay = (value:string) => {
        var weekDay = ["日", "一", "二", "三", "四", "五", "六"].map(str => getCurrentI18NTextOnce(str));
        var myDate = new Date(Date.parse(value.replace(/-/g, "/")));
        return weekDay[myDate.getDay()];
    }
    const formateTime = (value:string) => {
        if (!value) {
            return '';
        }   
        let values = value.split('/');
        return `${values[0]}${getCurrentI18NTextOnce('年')}${values[1]}${getCurrentI18NTextOnce('月')}${values[2]}${getCurrentI18NTextOnce('日')}`
    }
    const diffDay = (start:any, end:any) => {
        let diff = 0;
        if (start && end) {
            var dateStart = new Date(start).getTime();
            var dateEnd = new Date(end).getTime();
            diff = (dateEnd - dateStart) / (1000 * 60 * 60 * 24)
        }
        return diff;
    }
    return (
        <Popover
            placement='bottom'
            closeOnBlur={false}
        >
            <Flex
                cursor='pointer'
            >
                {
                    showIcon &&
                    <Image
                        src={DateIcon}
                        w='24px'
                        h='24px'
                        mr='16px'
                    />
                }
                <HStack
                    alignItems='center'
                >
                    <HStack>
                        <Text
                            fontSize='16px'
                            color='blue'
                            marginInlineStart='0 !important'
                            letterSpacing='0 !important'
                        >
                            {formateTime(start)}
                        </Text>
                        {
                            start &&
                            <Text
                                fontSize='16px'
                                color='blue'
                                marginInlineStart='0 !important'
                                letterSpacing='0 !important'
                            >
                                ({caculatDay(start)})
                            </Text>
                        }
                    </HStack>
                    <Text
                        fontSize='14px'
                        color='blue'
                        px='10px'
                        h='24px'
                        lineHeight='24px'
                        borderRadius='4px'
                        bgColor='#EBEEF5'
                        mx='10px !important'
                        letterSpacing='0 !important'
                    >
                        {diffDay(start, end)}{getI18NText('晚')}
                    </Text>
                    <HStack>
                        <Text
                            fontSize='16px'
                            color='blue'
                            marginInlineStart='0 !important'
                            letterSpacing='0 !important'
                        >
                            {formateTime(end)}
                        </Text>
                        {
                            end &&
                            <Text
                                fontSize='16px'
                                color='blue'
                                marginInlineStart='0 !important'
                                letterSpacing='0 !important'
                            >
                                ({caculatDay(end)})
                            </Text>
                        }
                    </HStack>
                    <Icon
                        as={IoChevronDownOutline}
                        boxSize='1rem'
                        color='gray.800'
                    />
                </HStack>
            </Flex>
        </Popover>
    )
}