import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export enum BtnVariant {
  SOLID = 'solid',
  OUTLINE = 'outline',
  SOLID_LIGHT = 'solid_light',
}

const Button: ComponentSingleStyleConfig = {
  baseStyle: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'md',
    _focus: {
      boxShadow: 'unset',
    },
  },
  sizes: {
    sm: {},
    md: {},
    lg: {},
    xs: {},
  },
  variants: {
    [BtnVariant.OUTLINE]: {
      bg: 'transparent',
      borderColor: 'secondary',
      color: 'secondary',
      _hover: {
        bgColor: 'secondary',
        color: 'white',
      },
      _active: {
        bgColor: 'gray.400',
        color: 'white',
      },
      _focus: {
        boxShadow: 'unset',
      },
    },
    [BtnVariant.SOLID]: {
      bgColor: 'secondary',
      color: 'white',
      _hover: {
        bgColor: 'secondary',
      },
      _active: {
        bgColor: 'gray.400',
      },
      _focus: {
        boxShadow: 'unset',
      },
    },
    [BtnVariant.SOLID_LIGHT]: {
      bgColor: 'primaryLight',
      color: 'secondary',
      _hover: {
        bgColor: 'primaryLight',
      },
      _active: {
        opacity: 0.8,
      },
      _focus: {
        boxShadow: 'unset',
      },
    },
  },
};

export default Button;
