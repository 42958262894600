import { useState, useEffect } from 'react';
import {
    Box,
    HStack,
    Image,
    Text,
    Stack,
} from '@chakra-ui/react';
import creditCard from '~/assets/images/creditcard.png';
import wechat from '~/assets/images/wechat.png';
import alipay from '~/assets/images/alipay.png';
import checkIcon from '~/assets/images/checkIcon.png';
import uncheckIcon from '~/assets/images/uncheckIcon.png';

import { useI18NText } from '~/i18n/i18n';

type payTypeProps = {
    selectPay?: string,
    price?: number,
    onChange?: any,
}
export default function PayType({
    selectPay = '',
    price,
    onChange
}: payTypeProps){
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })

    let textStyle = {
        fontSize: '14px',
        color: 'gray.800',
        mr: '8px',
        letterSpacing: '0 !important'
    }

    const changePayType = (type:string, e:any) => {
        // setSelectPay(type);
        onChange({
            type: type
        })
    }
    return (
        <Box>
            <HStack
                bgColor='#EBEEF5'
                w='100%'
                h='44px'
                lineHeight='44px'
            >
                <Text
                    fontWeight='500'
                    fontSize='22px'
                    color='blue'
                    pl='16px'
                    mr='10px'
                    letterSpacing='0 !important'
                >
                    {getI18NText("支付方式")}
                </Text>
                <Text
                    fontSize='12px'
                    color='gray.800'
                    letterSpacing='0 !important'
                    marginInlineStart='0 !important'
                >
                    {getI18NText("所有支付信息已获得安全加密保护")}
                </Text>
            </HStack>
            <Box
                p='24px 32px 32px 16px'
                borderRadius='4px'
                bgColor='white'
                boxSizing='border-box'
                justifyContent='space-between'
                boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
            >
                <Stack spacing='8px' pl='8px'>
                    {/* <HStack
                        onClick={(e)=>changePayType('balance', e)}
                        h='24px'
                        cursor='pointer'
                    >
                        <Image
                            src={selectPay == 'balance' ? checkIcon : uncheckIcon}
                            w='16px'
                            h='16px'
                            mr='8px'
                        />
                        <Text
                            {...textStyle}
                        >
                            {getI18NText("余额支付")}
                        </Text>
                        <Text>
                            ¥1,294
                        </Text>
                    </HStack> */}
                    <HStack
                        onClick={(e)=>changePayType('wechat', e)}
                        h='24px'
                        cursor='pointer'
                    >
                        <Image
                            src={selectPay == 'wechat' ? checkIcon : uncheckIcon}
                            w='16px'
                            h='16px'
                            mr='8px'
                        />
                        <Text
                            {...textStyle}
                        >
                        {getI18NText("微信")}
                        </Text>
                        <Image
                            src={wechat}
                            h='19px'
                        />
                    </HStack>
                    {/* <HStack
                        onClick={(e)=>changePayType('alipay', e)}
                        h='24px'
                        cursor='pointer'
                    >
                        <Image
                            src={selectPay == 'alipay' ? checkIcon : uncheckIcon}
                            w='16px'
                            h='16px'
                            mr='8px'
                        />
                        <Text
                            {...textStyle}
                        >
                        {getI18NText("支付宝")}
                        </Text>
                        <Image
                            src={alipay}
                            h='17px'
                        />
                    </HStack>
                    <HStack
                        onClick={(e)=>changePayType('credit', e)}
                        h='24px'
                        cursor='pointer'
                    >
                        <Image
                            src={selectPay == 'credit' ? checkIcon : uncheckIcon}
                            w='16px'
                            h='16px'
                            mr='8px'
                        />
                        <Text
                            {...textStyle}
                        >
                        {getI18NText("信用卡")}
                        </Text>
                        <Image
                            src={creditCard}
                            h='24px'
                        />
                    </HStack> */}
                </Stack> 
                <HStack
                    justifyContent='flex-end'
                    alignItems='center'
                    mt='24px'
                    borderTopWidth='1px'
                    borderTopStyle='dashed'
                    borderTopColor='#C4C4C4'
                >
                    <Text
                        color='gray.100'
                        fontSize='14px'
                        mr='10px'
                    >
                        {getI18NText("支付金额")}
                    </Text>
                    <Text
                        marginInlineStart='0 !important'
                        color='blue'
                        fontSize='14px'
                        mr='10px !important'
                    >
                        ¥
                    </Text>
                    <Text
                        marginInlineStart='0 !important'
                        color='blue'
                        fontSize='24px'
                        fontWeight='500'
                    >
                        {price}
                    </Text>
                </HStack>
            </Box>
        </Box>
    )
}