import { useState } from 'react';
import {
    Box,
    Button,
    Center,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Input,
    Text,
} from '@chakra-ui/react';
import { useI18NText } from '~/i18n/i18n';
import HideIcon from '~/assets/images/hide.png';
import ShowIcon from '~/assets/images/show.png';

type ChangePwdModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onChangePwd?:any
};
export default function ChangePwd({ isOpen, onClose, onChangePwd }: ChangePwdModalProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' });
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [reNewPassword, setReNewPassword] = useState('');
    const [error1, setError1] = useState<Boolean>(false);
    const [error2, setError2] = useState<Boolean>(false);
    const [error3, setError3] = useState<Boolean>(false);
    const [show1, setShow1] = useState<Boolean>(false);
    const [show2, setShow2] = useState<Boolean>(false);
    const [show3, setShow3] = useState<Boolean>(false);
    const Submit = (event:any) => {
        if (oldPassword == '') {
            setError1(true);
            return;
        }
        if (newPassword == '') {
            setError2(true);
            return;
        }
        if (reNewPassword == '') {
            setError3(true);
            return;
        }
        if (reNewPassword != newPassword) {
            setError3(true);
            return;
        }
        onChangePwd({
            oldPassword,
            newPassword,
        });
    }
    const changeValue = (type: string, event:any) => {
        let value = event.target.value;
        switch (type) {
            case 'oldPassword':
                setOldPassword(value);
                if (value) {
                    setError1(false);
                }
                break;
            case 'newPassword':
                setNewPassword(value);
                if (value) {
                    setError2(false);
                }
                break;
            case 'reNewPassword':
                setReNewPassword(value);
                if (value) {
                    setError3(false);
                }
                break;
            default:
                setOldPassword(value);
                if (value) {
                    setError1(false);
                }
                break;
        }
    }
    const showPwd = (type:string, event:any) => {
        console.log(1231232)
        switch (type) {
            case 'show1':
                setShow1(!show1);
                break;
            case 'show2':
                setShow2(!show2);
                break;
            case 'show3':
                setShow3(!show3)
                break;
            default:
                setShow1(!show1);
                break;
        }
    }
    const cancel = (event:any) => {
        setError1(false);
        setError2(false);
        setError3(false);
        onClose()
    }
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="sm"
            isCentered
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody
                    py='36px'
                    px='37.5px'
                >
                    <Text
                        textAlign='center'
                        fontSize='22px'
                        fontWeight='500'
                        color='blue'
                        mb='19px'
                        h='29px'
                        lineHeight='29px'
                    >
                        {getI18NText('变更密码')}
                    </Text>
                    <Box
                        marginInlineStart='0 !important'
                        pos='relative'
                        mb='18px'
                    >
                        <Text
                            textAlign='left'
                            h='20px'
                            lineHeight='20px'
                            fontSize='14px'
                            fontWeight='400'
                            color='#333333'
                            mb='4px'
                        >
                            {getI18NText('目前的密码')}
                        </Text>
                        <Input
                            type={show1 ? 'text': 'password'}
                            fontSize='14px'
                            color='#002C5E'
                            placeholder='请输入目前使用的旧密码'
                            h='40px'
                            lineHeight='40px'
                            borderRadius='4px'
                            borderWidth='1px'
                            borderStyle='solid'
                            borderColor={error1 ? '#FF6770' : '#888888'}
                            value={oldPassword}
                            onChange={(e) => changeValue('oldPassword', e)}
                        />
                        <Image
                            zIndex='999'
                            src={show1 ? HideIcon : ShowIcon}
                            onClick={(e) => showPwd('show1', e)}
                            cursor='pointer'
                            w='20px'
                            h='20px'
                            pos='absolute'
                            right='10px'
                            top='34px'
                        />
                        {
                            error1 &&
                            <Text
                                pos='absolute'
                                right='0'
                                top='68px'
                                color='#FF6770'
                                fontSize='12px'
                            >
                                {getI18NText('密码输入错误')}
                            </Text>
                        }
                    </Box>
                    <Box
                        marginInlineStart='0 !important'
                        pos='relative'
                        mb='18px'
                    >
                        <Text
                            textAlign='left'
                            h='20px'
                            lineHeight='20px'
                            fontSize='14px'
                            fontWeight='400'
                            color='#333333'
                            mb='4px'
                        >
                            {getI18NText('新的密码')}
                        </Text>
                        <Input
                            type={show2 ? 'text': 'password'}
                            fontSize='14px'
                            color='#002C5E'
                            placeholder='请输入欲变更的新密码'
                            h='40px'
                            lineHeight='40px'
                            borderRadius='4px'
                            borderWidth='1px'
                            borderStyle='solid'
                            borderColor={error2 ? '#FF6770' : '#888888'}
                            value={newPassword}
                            onChange={(e) => changeValue('newPassword', e)}
                        />
                        <Image
                            zIndex='999'
                            src={show2 ? HideIcon : ShowIcon}
                            onClick={(e) => showPwd('show2', e)}
                            cursor='pointer'
                            w='20px'
                            h='20px'
                            pos='absolute'
                            right='10px'
                            top='34px'
                        />
                        {
                            error2 &&
                            <Text
                                pos='absolute'
                                right='0'
                                top='68px'
                                color='#FF6770'
                                fontSize='12px'
                            >
                                {getI18NText('新的密码与目前密码相同')}
                            </Text>
                        }
                    </Box>
                    <Box
                        marginInlineStart='0 !important'
                        pos='relative'
                        mb='33px'
                    >
                        <Text
                            textAlign='left'
                            h='20px'
                            lineHeight='20px'
                            fontSize='14px'
                            fontWeight='400'
                            color='#333333'
                            mb='4px'
                        >
                            {getI18NText('再次确认新的密码')}
                        </Text>
                        <Input
                            type={show3 ? 'text': 'password'}
                            fontSize='14px'
                            color='#002C5E'
                            placeholder='请再重复输入新的密码'
                            h='40px'
                            lineHeight='40px'
                            borderRadius='4px'
                            borderWidth='1px'
                            borderStyle='solid'
                            borderColor={error3 ? '#FF6770' : '#888888'}
                            value={reNewPassword}
                            onChange={(e) => changeValue('reNewPassword', e)}
                        />
                        <Image
                            zIndex='999'
                            src={show3 ? HideIcon : ShowIcon}
                            cursor='pointer'
                            w='20px'
                            h='20px'
                            onClick={(e) => showPwd('show3', e)}
                            pos='absolute'
                            right='10px'
                            top='34px'
                        />
                        {
                            error3 &&
                            <Text
                                pos='absolute'
                                right='0'
                                top='68px'
                                color='#FF6770'
                                fontSize='12px'
                            >
                                {getI18NText('与新的密码输入不相同')}
                            </Text>
                        }
                    </Box>
                    <Center
                        mt='16px'
                    >
                        <Button
                            w='96px'
                            h='48px'
                            lineHeight='48px'
                            bgColor='white'   
                            textAlign='center'
                            color='blue'
                            borderWidth='2px'
                            borderColor='blue'
                            fontSize='14px'
                            mr='16px'
                            _hover={{
                                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #ffffff'
                            }}
                            onClick={(e)=>{cancel(e)}}
                        >
                            {getI18NText('取消')}
                        </Button>
                        <Button
                            w='96px'
                            h='48px'
                            lineHeight='48px'
                            bgColor='blue'   
                            textAlign='center'
                            color='white'
                            fontSize='14px'
                            _hover={{
                                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #002C5E'
                            }}
                            onClick={(e)=>Submit(e)}
                        >
                            {getI18NText('确认')}
                        </Button>
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
