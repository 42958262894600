// import { useState } from 'react';
import {
    Box,
    Button,
    Flex,
    HStack,
    Icon,
    Text,
} from '@chakra-ui/react';
import {
    IoArrowForwardOutline
} from 'react-icons/io5';

import { useI18NText } from '~/i18n/i18n';

let style = {
    timeStyle: {
        w: '105px',
        mr: '11px',
        fontSize: '14px',
        color: 'gray.800',
        letterSpacing: '0 !important'
    },
    centerStyle: {
        w: '105px',
        mr: '11px',
        fontSize: '14px',
        color: 'gray.800',
        letterSpacing: '0 !important'
    },
    descStyle: {
        w: '65px',
        fontSize: '14px',
        letterSpacing: '0 !important',
        color: 'fontColor'
    }
}
type BookingDetailProps = {
    rooms?: string | number;
    showPay: boolean,
    price: number,
    toPay?: () => void;
}
export default function BookingDetail({
    rooms,
    showPay = false,
    price,
    toPay,
} : BookingDetailProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })

    return (
        <HStack
            display='block'
            as='div'
            bgColor='white'
            borderRadius='4px'
            p='16px'
            boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
        >
            <Text
                fontSize='18px'
                color='fontColor'
                lineHeight='25.2px'
                mb='12px'
                letterSpacing='0 !important'
            >
                {getI18NText('预定明细')}
            </Text>
            <Box
                borderTop='1px'
                borderTopStyle='dashed'
                borderTopColor='gray.400'
                borderBottom='1px'
                borderBottomStyle='dashed'
                borderBottomColor='gray.400'
                px='4px'
                pt='20px'
                pb='16px'
                marginInlineStart='0 !important'
            >
                <Flex
                    mb='12px'
                    h='17px'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Text {...style.timeStyle}>{getI18NText('单间报价总计')}</Text>
                    <Text {...style.descStyle} textAlign='right'>¥ {price}</Text>
                </Flex>
                <Flex
                    mb='12px'
                    h='17px'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Text {...style.timeStyle}>{getI18NText('预定间数')}</Text>
                    <Text {...style.descStyle} textAlign='right'>{rooms}</Text>
                </Flex>
                <Flex
                    mb='12px'
                    h='17px'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Text {...style.timeStyle}>{getI18NText('结算价格')}</Text>
                    <Text {...style.descStyle} textAlign='right'>¥ {price}</Text>
                </Flex>
                <Flex
                    h='17px'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Text {...style.timeStyle}>{getI18NText('优惠金额')}</Text>
                    <Text {...style.descStyle} textAlign='right'>-</Text>
                </Flex>
            </Box>
            <Flex
                h='29px'
                justifyContent='space-between'
                alignItems='center'
                pt='8.5px'
            >
                <Text 
                    fontSize='16px'
                    color='dark'
                    w='220px'
                    letterSpacing='0 !important'
                    marginInlineStart='0 !important'
                >
                    {getI18NText('支付金额')}
                </Text>
                <HStack
                    flex='1'
                    justifyContent='flex-end'
                >
                    <Text 
                        letterSpacing='0 !important'
                        fontSize='16px'
                        color='dark'
                        marginInlineStart='0 !important'
                        mr='10px'
                    >
                        ¥
                    </Text>
                    <Text 
                        fontWeight='500'
                        textAlign='right'
                        fontSize='24px'
                        marginInlineStart='0 !important'
                        letterSpacing='0 !important'
                        marginBlockEnd='0 !important'
                    >
                        {price}
                    </Text>
                </HStack>
                
            </Flex>
            {
                showPay &&
                <Button
                    w='304px'
                    h='40px'
                    borderRadius='4px'
                    bgColor='blue'        
                    fontSize='16px'
                    color='white'
                    mt='12px !important'
                    _hover={{
                        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #002C5E'
                    }}
                    onClick={toPay}
                    marginInlineStart='0 !important'
                >
                    {getI18NText('前往支付')}
                    <Icon
                        as={IoArrowForwardOutline}
                        color='white'
                        boxSize='1rem'
                        ml='6.25px'
                    />
                </Button>
            }
        </HStack>
    )
}