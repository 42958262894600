import { useNavigate } from 'react-router-dom';

import Cookie from 'js-cookie';
// import * as jwt from 'jsonwebtoken';

// import { RouterPath } from '~/routes';
import memberLogin from '~/awsHttpApi/member/login';
// import memberRefreshToken from '~/awsHttpApi/member/refreshToken';
import memberLogout from '~/awsHttpApi/member/logout';
import memberDetail from '~/awsHttpApi/member/detail';
import memberUpdatePassword from '~/awsHttpApi/member/updatePassword';
import { User } from '~/types';
// import getUnixTime from 'date-fns/getUnixTime';
// import compareAsc from 'date-fns/compareAsc';

const clearToken = () => {
  window.localStorage.removeItem('_trip_b2b_token');
  window.localStorage.removeItem('_trip_b2b_refresh');
  Cookie.remove('_trip_token', {
    domain: '.tripintl.com',
  });
  Cookie.remove('_trip_refresh', {
    domain: '.tripintl.com',
  });
};

export default function useAuthingClient() {
  const navigate = useNavigate();

  const login = async (input: { username: string; password: string }): Promise<void> => {
    const { username, password } = input;
    try {
      const { accessToken, refreshToken } = await memberLogin({
        account: username,
        password,
      });
      if (!accessToken || !refreshToken) {
        throw new Error('No Token after login API has been called');
      }

      window.localStorage.setItem('_trip_b2b_token', accessToken);
      window.localStorage.setItem('_trip_b2b_refresh', refreshToken);
      // 添加 cookie
      Cookie.set('_trip_token', accessToken, {
        domain: '.tripintl.com',
      });
      Cookie.set('_trip_refresh', refreshToken, {
        domain: '.tripintl.com',
      });
    } catch (error) {
      clearToken();
      navigate('/login');
      throw error;
    }
  };

  const getUser = async (): Promise<User | null> => {
    const token = window.localStorage.getItem('_trip_b2b_token') || '';
    try {
      const { user = null } = await memberDetail({
        accessToken: token,
      });

      return user;
    } catch (error) {
      clearToken();
      navigate('/login');

      throw error;
    }
  };

  const checkIfLoggedIn = (): boolean => {
    const isTokenExist = !!window.localStorage.getItem('_trip_b2b_token');
    const isRefreshTokenExist = !!window.localStorage.getItem('_trip_b2b_refresh');

    return isTokenExist && isRefreshTokenExist;
  };

  // const getIdToken = async (): Promise<string> => {
  //   let token = window.localStorage.getItem('_trip_b2b_token') || '';
  //   const refresh = window.localStorage.getItem('_trip_b2b_refresh');

  //   const tokenInfo = token ? jwt.decode(token, { complete: true }) : null;
  //   const { exp = null } = tokenInfo ? tokenInfo?.payload : {};
  //   const currentUnixTimestamp = getUnixTime(new Date());
  //   if ((exp || Number.MIN_SAFE_INTEGER) < currentUnixTimestamp) {
  //     try {
  //       const { accessToken, refreshToken } = await memberRefreshToken({
  //         refreshToken: refresh || '',
  //       });

  //       if (!accessToken || !refreshToken) {
  //         throw new Error('No Token after login API has been called');
  //       }

  //       window.localStorage.setItem('_trip_b2b_token', accessToken);
  //       window.localStorage.setItem('_trip_b2b_refresh', refreshToken);

  //       token = accessToken;
  //     } catch (error) {
  //       console.error('Refreshing token has error', error);
  //       window.localStorage.removeItem('_trip_b2b_token');
  //       window.localStorage.removeItem('_trip_b2b_refresh');

  //       navigate('/login');

  //       throw '无法取得数据，请重新登入';
  //     }
  //   }

  //   return token;
  // };

  const logout = async () => {
    const token = window.localStorage.getItem('_trip_b2b_token');
    const refresh = window.localStorage.getItem('_trip_b2b_refresh');
    try {
      await memberLogout({
        accessToken: token || '',
        refreshToken: refresh || '',
      });
    } catch (error) {
      console.error('logout at server side error', error);
    }

    clearToken();
    navigate('/login');
  };

  const modifyPassword = async (oldPassword: string, newPassword: string) => {
    const token = window.localStorage.getItem('_trip_b2b_token') || '';
    try {
      await memberUpdatePassword({
        accessToken: token,
        oldPassword,
        newPassword,
      });
    } catch (error) {
      clearToken();
      navigate('/login');

      throw error;
    }
  };

  return {
    login,
    getUser,
    checkIfLoggedIn,
    // getIdToken,
    logout,
    modifyPassword,
  };
}
