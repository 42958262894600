import React, { useRef, useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalBody, ModalContent, ModalCloseButton } from '@chakra-ui/react';

import Map from './Map';

type MapModalProps = {
  isOpen: boolean;
  latitude: number;
  longitude: number;
  onClose: () => void;
};

export default function MapModal({ isOpen, latitude, longitude, onClose }: MapModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent m={0} maxH={{ base: 'full', md: '95%' }}>
        <ModalCloseButton
          borderRadius="full"
          bg="trip.primaryLight"
          color="trip.primary"
          size="sm"
        />
        <ModalBody overflow="auto" minH="400px">
          <Map latitude={latitude} longitude={longitude} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
