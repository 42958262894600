// doc https://chakra-ui.com/docs/theming/customize-theme
const colors = {
  trip: {
    primary: '#6190E8',
    primaryLight: '#E7EFFF',
    primaryDisabled: '#8DADEA',
    primaryHover: '#2B6CE7',
    success: '#52C41A',
    successLight: '#FBFFFA',
    danger: '#FF4356',
    dangerLight: '#FFF4F5',
    dangerHover: '#FC142B',
    black: '#333333',
    gray: '#888888',
    grayLight: '#E3E3E3',
    background: '#F3F3F3',
  },
};

export default colors;
