import { useState, useEffect, useRef } from 'react';
import {
    Badge,
    Button,
    Box,
    Divider,
    HStack,
    Flex,
    Stack,
    VStack,
    Text,
    Image
} from '@chakra-ui/react';
import {
    getQueryString,
    getToken
} from '~/utils/tools';
import getBooking from '~/awsHttpApi/getBooking';
import OrderStatusLabel from './OrderStatusLabel';
import cancelBooking from '~/awsHttpApi/cancelBooking';
import getHotel from '~/awsHttpApi/getHotel';
import { booking as fakeBooking } from '~/assets/fakeData';
import BookingDetail from '~/components/BookingDetail';
import CancelPolicy from '~/components/CancelPolicy';
import CheckInInformation from '~/components/CheckInInformation';
import ContactInformation from '~/components/ContactInformation';
import HotelInfo from '~/components/HotelInfo/index';
import Quote from '~/components/Quote';
import Countdown from '~/components/Countdown';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '~/containers/PageContainer';
import startOfDay from 'date-fns/startOfDay';
import differenceInDays from 'date-fns/differenceInDays';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import compareAsc from 'date-fns/compareAsc';
import modalControlAtom from '~/recoil/atom/modal';
import payControlAtom from '~/recoil/atom/pay';
import LoadingModal from '~/components/LoadingModal';
import { useSetRecoilState } from 'recoil';
import {
  Bed,
  Meal,
  FetchingState,
  Booking,
  RoomInfo,
  BookingStatus,
  OrderType,
  PaymentType,
  Lang,
  paymentAppUrlMapProps
} from '~/types';
import checkIcon from '~/assets/images/check.png';
import DefaultImg from '~/assets/images/default.png';
import { useI18NText } from '~/i18n/i18n';

interface bedProps {
    code: string,
    count: number,
    desc: string,
    seq: number
}
interface HotelProps{
    name: Record<Lang, string>,
    address: Record<Lang, string>,
    price: number,
    profitMargin: number
}

export default function OrderDetail() {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'order.orderId.' })
    const intervalRef = useRef<any>(null);
    const setModalControl = useSetRecoilState(modalControlAtom);
    const setPayControlAtom = useSetRecoilState(payControlAtom);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [isFormSent, setFormSent] = useState<boolean>(false);
    const { orderId } = useParams();
    const [booking, setBooking] = useState<Booking>(fakeBooking);
    const [hotelRoom, setHotelRoom] = useState<RoomInfo | null>(null);
    let [bedInfo, setBedIndo] = useState({});
    let [hotelDetail, setHotelDetail] = useState({})
    const [fetchBookingStatus, setFetchBookingStatus] = useState<FetchingState>(FetchingState.INIT);
    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.Wechat);
    const [paymentQrCodeDataUrl, setPaymentQrCodeDataUrl] = useState<string>('');
    let [paymentURL, setPaymentURL] = useState<paymentAppUrlMapProps>({
        Wechat: ''
    });
    const now: any = Math.round(new Date().getTime() / 1000); 
    let [refresh, setRefresh] = useState(false);
    const end = now + 1800;
    useEffect(()=>{
        if (!refresh) {
            window.scrollTo(0, 0);
            setRefresh(true);
        }
        if (loading) {
            getDetail();
        }
        intervalRef.current = setInterval(() => {
            getDetail();
        }, 2000);
        if (booking.status == 'SUCCESS') {
            clearInterval(intervalRef.current);
            getDetail();
        }
    },[loading, booking, refresh]);
    const getDetail = async() => {if (!orderId) return;
        let bookingFromApi = await getBooking({ token:getToken(), bookingId: orderId });
        if (!bookingFromApi) {
            setFetchBookingStatus(FetchingState.FAILED);
            return;
        }
        const hotel = await getHotel({
            token: getToken(),
            hotelId: bookingFromApi.rates[0].hotelId,
        });
        if (!hotel) {
            return;
        }
        const hotelRoom = hotel.rooms.find(({ roomId }) => 
            roomId === bookingFromApi?.rates[0].roomId.toString(),
        );
        bookingFromApi.rates.forEach((rate) =>
            rate.cancelPolicies.sort((a, b) => compareAsc(parseISO(a.endAt), parseISO(b.endAt))),
        );
        let bed:Bed = JSON.parse(bookingFromApi.rates[0].bed)[0];
        let hotelDetail = bookingFromApi.hotel;
        let paymentAppUrlMap = bookingFromApi.bookingPayment?.paymentAppUrlMap;
        setHotelDetail({...hotelDetail});
        setBedIndo({...bed});
        setBooking({...bookingFromApi});
        setHotelRoom(hotelRoom || null);
        if (paymentAppUrlMap) {
            setPaymentURL({...paymentAppUrlMap})
        }
        setFetchBookingStatus(FetchingState.SUCCESS);
        setLoading(false);
        clearInterval(intervalRef.current);
    }
    const payOrder = (event:any) => {
        setModalControl((prev) => ({ ...prev, isPayTipModalOpen: true }));
        setPayControlAtom((prev) => ({ ...prev, paymentURL: paymentURL }));
        if (orderId) {
            setPayControlAtom((prev) => ({ ...prev, payOrderNo: orderId }));
        }
        setPayControlAtom((prev) => ({ ...prev, payPrice: booking.price }));
        setTimeout(()=> {
            setModalControl((prev) => ({ ...prev, isPayTipModalOpen: false }));
            setModalControl((prev) => ({ ...prev, isWechatPayModalOpen: true }));
        }, 1500);
    }
    return (
        <PageContainer
            topBoxStyle={{ position: 'static' }}
            // afterHeaderComponent={}
        >
            <VStack
                align="stretch"
                bgColor='#FAFAFA'
                pl='123px'
                pr='126px'
                pb='63px'
                pt='57px'
            >
                <Flex
                    justifyContent='center'
                    alignItems='center'
                    mb='27px'
                >
                    <Image
                        src={checkIcon}
                        w='30px'
                        h='30px'
                        mr='4px'
                    />
                    <Text
                        color='blue'
                        fontSize='24px'
                        fontWeight='500'
                        letterSpacing='0 !important'
                    >
                        {getI18NText('订单成功支付')}
                    </Text>
                </Flex>
                <Flex
                    justifyContent='center'
                    alignItems='center'
                    mb='18px'
                >
                    <Button
                        w='112px'
                        h='48px'
                        borderRadius='6px'
                        lineHeight='48px'
                        textAlign='center'
                        fontSize='16px'
                        color='blue'
                        bgColor='#F0D43A'
                        mr='24px'
                        letterSpacing='0 !important'
                        _hover={{
                            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #F0D43A'
                        }}
                        onClick={(e)=> navigate('/')}
                    >
                        {getI18NText('继续购物')}
                    </Button>
                    <Button
                        w='112px'
                        h='48px'
                        borderRadius='6px'
                        lineHeight='48px'
                        textAlign='center'
                        fontSize='16px'
                        bgColor='blue'
                        color='#F2F2F2'
                        letterSpacing='0 !important'
                        _hover={{
                            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #002C5E'
                        }}
                        onClick={(e)=> navigate('/orderlist')}
                    >
                        {getI18NText('查看列表')}
                    </Button>
                </Flex>
                <Text
                    mb='18px !important'
                    fontSize='28px'
                    color='gray.100'
                    fontWeight='500'
                    letterSpacing='0 !important'
                    pl='9px'
                >
                    {getI18NText('订单详情')}
                </Text>
                <Flex
                    justifyContent='space-between'
                >
                    <VStack
                        align="stretch"
                        spacing='24px'
                        w='830px'
                    >
                        <Box>
                            <Flex
                                h='44px'
                                w='100%'
                                bgColor='#EBEEF5'
                                pl='16px'
                                pr='25px'
                                justifyContent='space-between'
                                alignItems='center'
                                mb='8px'
                                borderRadius='4px'
                            >
                                <HStack>
                                    <Text
                                        fontWeight='500'
                                        fontSize='22px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText('订单号')}
                                    </Text>
                                    <Text
                                        fontWeight='500'
                                        fontSize='18px'
                                        color='blue'
                                        ml='10px'
                                        letterSpacing='0 !important'
                                    >
                                        {booking?.bookingId}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text
                                        fontWeight='400'
                                        fontSize='14px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText('总金额')}
                                    </Text>
                                    <Text
                                        fontWeight='400'
                                        fontSize='14px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        ¥
                                    </Text>
                                    <Text
                                        fontWeight='500'
                                        fontSize='24px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        {booking?.price}
                                    </Text>
                                </HStack>
                            </Flex>
                            <HStack
                                as='div'
                                display='block'
                                pl='24px'
                                pr='25px'
                                borderRadius='4px'
                                bgColor='white'
                            >
                                <HStack
                                    pt='24px'
                                    pb='25px'
                                    justifyContent='space-between'
                                    alignItems='center'
                                >
                                    <HStack>
                                        <Box
                                            w='268px'
                                        >
                                            <Text
                                                fontSize='14px'
                                                color='gray.800'
                                                mb='12px'
                                                lineHeight='20px'
                                                letterSpacing='0 !important'
                                            >
                                                {getI18NText('订单状态')}
                                            </Text>
                                            <OrderStatusLabel status={booking?.status} />
                                        </Box>
                                        <Box>
                                            <Text
                                                fontSize='14px'
                                                color='gray.800'
                                                mb='4px'
                                                lineHeight='20px'
                                                letterSpacing='0 !important'
                                            >
                                                {booking?.status == 'PENDING' ? getI18NText('剩余时间') : getI18NText('下单时间')}
                                            </Text>
                                            {
                                                booking?.status == 'PENDING' ? (
                                                    <Text
                                                        color='danger.100'
                                                        fontSize='18px'
                                                        lineHeight='25.2px'
                                                        letterSpacing='0 !important'
                                                    >
                                                        <Countdown timeStamp={end} />
                                                    </Text>
                                                ) : (
                                                    <>
                                                        {
                                                            booking?.createdAt &&
                                                            <Text
                                                                color='danger.100'
                                                                fontSize='18px'
                                                                lineHeight='25.2px'
                                                                letterSpacing='0 !important'
                                                            >
                                                                {format(new Date(booking.createdAt), 'yyyy-MM-dd')}
                                                            </Text>
                                                        }
                                                    </>
                                                )
                                            }
                                        </Box>
                                    </HStack>
                                    {
                                        booking?.status == 'PENDING' && 
                                        <Button
                                            w='115px'
                                            h='48px'
                                            borderRadius='4px'
                                            bgColor='blue'
                                            letterSpacing='0 !important'
                                            _hover={{
                                                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #002C5E'
                                            }}
                                            onClick={(e)=> payOrder(e)}
                                        >
                                            {getI18NText('前往支付')}
                                        </Button>
                                    }
                                </HStack>
                                <Divider orientation='horizontal' bgColor='#F2F2F2' />
                                <HStack
                                    pt='16px'
                                    pb='24px'
                                >
                                    <Box
                                        w='268px'
                                    >
                                        <Text
                                            fontSize='14px'
                                            color='gray.800'
                                            mb='4px'
                                            lineHeight='20px'
                                            letterSpacing='0 !important'
                                        >
                                            {getI18NText('支付方式')}
                                        </Text>
                                        <Text
                                            color='blue'
                                            fontSize='14px'
                                            lineHeight='25.2px'
                                            letterSpacing='0 !important'
                                        >
                                            微信
                                        </Text>
                                    </Box>
                                    <Box
                                        flex='1'
                                    >
                                        <Text
                                            fontSize='14px'
                                            color='gray.800'
                                            mb='4px'
                                            lineHeight='20px'
                                            letterSpacing='0 !important'
                                        >
                                            {getI18NText('支付状态')}
                                        </Text>
                                        <Text
                                            color='blue'
                                            fontSize='14px'
                                            lineHeight='25.2px'
                                            letterSpacing='0 !important'
                                        >
                                            已付款
                                        </Text>
                                    </Box>
                                </HStack>
                            </HStack>
                        </Box>
                        <CheckInInformation
                            title={getI18NText('入住信息')}
                            subTitle=''
                            isEdit={false}
                            guestByRoom={booking?.customer.guestByRoom}
                            onChangePerson={(e:any)=>{console.log(e)}}
                            onChangeGuest={(e:any)=>{console.log(e)}}
                            isPay={false}
                        />
                        <ContactInformation
                            contactPerson={booking?.customer.contactPerson}
                            isEdit={false}
                            onChange={(e:any) => {console.log(e)}}
                            isPay={false}
                            isPhone={true}
                            isEmail={true}
                        />
                    </VStack>
                    <Box
                        w='338px'
                    >
                        <Stack
                            spacing='12px'
                        >
                            <HotelInfo
                                image={hotelRoom?.images[0] ? hotelRoom?.images[0].url : DefaultImg}
                                checkIn={booking?.rates[0].checkIn}
                                checkOut={booking?.rates[0].checkOut}
                                bedInfo={bedInfo}
                                hotelName={booking?.hotel.name['zh-CN']}
                                address={booking?.hotel.address['zh-CN']}
                                roomName={hotelRoom?.name['zh_CN']}
                                hasBreakfast={booking?.rates[0].hasBreakfast}
                                num={booking?.rates[0].group.adultCount}
                            />
                            <Quote
                                checkIn={booking?.rates[0].checkIn}
                                checkOut={booking?.rates[0].checkOut}
                                dailyPrice={booking?.rates[0].dailyPrice}
                            />
                            <BookingDetail
                                showPay={false}
                                price={booking?.price}
                                toPay={()=>console.log()}
                                rooms={booking?.rates[0].roomNum}
                            />
                            <CancelPolicy
                                cancelPolicies={booking?.rates[0].cancelPolicies}
                            />
                        </Stack>
                    </Box>
                </Flex>
            </VStack>
            <LoadingModal isOpen={isFormSent || loading} />
        </PageContainer>
    )
}