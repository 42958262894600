import { useRoutes, RouteObject } from 'react-router-dom';

import Index from '~/pages/Index';
import List from '~/pages/List';
import Hotel from '~/pages/Hotel';
import OrderList from '~/pages/OrderList';
import ConfirmOrder from '~/pages/ConfirmOrder';
import OrderDetail from '~/pages/OrderDetail';
import PayOrder from '~/pages/PayOrder';
import Login from '~/pages/Login';
import PayResult from '~/pages/PayResult';
import UserCenter from '~/pages/UserCenter';

export enum RouterPath {
  HOTELS,
  HOTEL_DETAIL,
  SCHEDULE,
  LOGIN,
  PROFILE,
  ORDER,
  ORDER_CREATE,
}
const routes: Array<RouteObject> = [
  {
    path: '',
    children: [
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '',
        element: <Index />,
      },
      {
        path: '/list',
        element: <List />,
      },
      {
        path: '/hotel/:id',
        element: <Hotel />,
      },
      {
        path: '/orderlist',
        element: <OrderList />,
      },
      {
        path: '/confirmorder',
        element: <ConfirmOrder />,
      },
      {
        path: '/order/:orderId',
        element: <OrderDetail />,
      },
      {
        path: '/payorder/:orderId',
        element: <PayOrder />,
      },
      {
        path: '/payresult/:orderId',
        element: <PayResult />,
      },
      {
        path: '/usercenter',
        element: <UserCenter />,
      }
    ],
  },
];

export default function Routes() {
  const element = useRoutes(routes);

  return element;
}
