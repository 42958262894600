import React from 'react';
import { Center, SimpleGrid } from '@chakra-ui/react';

import { useI18NText } from '~/i18n/i18n';


const week = ['日', '一', '二', '三', '四', '五', '六'];

const Week = () => {
  // i18n国际化纯文本
  const { getI18NText } = useI18NText({ prefix: 'component.' })

  return (
    <SimpleGrid fontSize="xs" columns={7}>
      {week.map(str => getI18NText(str)).map((weekDay) => (
        <Center key={weekDay} w={10} h={10} color="gray">
          {weekDay}
        </Center>
      ))}
    </SimpleGrid>
  );
};

export default Week;
