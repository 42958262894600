import React from 'react';
import { Center, Text } from '@chakra-ui/react';

import baseStyle from '../styles/baseStyle';

type TargetType = 'start' | 'end';

type TargetDayProps = {
  day: number;
  targetType: TargetType;
};

const TargetDay: React.FC<TargetDayProps> = ({ day, targetType }) => {
  const deg = targetType === 'start' ? '90deg' : '270deg';
  const bgGradient = `linear(${deg}, transparent 50%, trip.primaryLight 0);`;

  return (
    <Center
      {...baseStyle}
      cursor="pointer"
      position="relative"
      _before={{
        content: '""',
        bg: 'trip.primary',
        borderRadius: 'full',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '40px',
        zIndex: 1,
      }}
      color="white"
    >
      <Center position="relative" w="inherit" h={9} bgGradient={bgGradient}>
        <Text zIndex={2} color="white">
          {day}
        </Text>
      </Center>
    </Center>
  );
};

export default TargetDay;
