import { useState, useEffect } from 'react';
import {
    Box,
    BoxProps,
    Divider,
    Flex,
    HStack,
    Icon,
    Image,
    Text,
    Stack,
    useDisclosure,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
} from '@chakra-ui/react';
import {
    IoChevronForwardOutline,
    IoStar,
    IoLocationOutline,
} from 'react-icons/io5';
import { useRecoilState } from 'recoil';
import filiterControlAtom from '~/recoil/atom/filiter';
import { useNavigate, useParams } from 'react-router-dom';
import getHotel from '~/awsHttpApi/getHotel';
import getHotelRates from '~/awsHttpApi/getHotelRates';
import PageContainer from '~/containers/PageContainer';
import RoomType from '~/components/RoomType';
import Empty from '~/components/Empty';
import {
    conditionProps,
} from '~/types/Hotelinfo';
import { Hotel, Rate, RoomsInfoList } from '~/types';
import {
    getQueryString,
    getToken
} from '~/utils/tools';
import CommonHeader  from '~/components/CommonHeader';
import LoadingModal from '~/components/LoadingModal';
import MapModal from './MapModal';
import ImgShowModal from './ImgShowModal';
import roomIcon from '~/assets/images/room.png';
import petIcon from '~/assets/images/pet.png';
import tipsIcon from '~/assets/images/tips.png';
import feeIcon from '~/assets/images/fee.png';
import viewpointIcon from '~/assets/images/viewpoint.png';
import trafficIcon from '~/assets/images/traffic.png';
import hotelserviceIcon from '~/assets/images/hotelservice.png';
import roomserviceIcon from '~/assets/images/roomservice.png';
import DefaultImg from '~/assets/images/default.png';
import MoreIcon from '~/assets/images/more.jpeg';
import CheckImg from '~/assets/images/checkImg.png';
import OtherImg from '~/assets/images/other.png';
import { useI18NText } from '~/i18n/i18n';

const conditions:conditionProps[] = [
    {
        id: 0,
        name: '含早餐',
        selected: true
    },
    {
        id: 1,
        name: '含早餐及晚餐',
        selected: true
    },
    {
        id: 2,
        name: '单人床',
        selected: true
    },
    {
        id: 3,
        name: '可免费取消',
        selected: false
    },
    {
        id: 4,
        name: '有窗',
        selected: false
    },
    {
        id: 5,
        name: '立即确认',
        selected: false
    },
    {
        id: 6,
        name: '有Wifi',
        selected: false
    },
    {
        id: 7,
        name: '到店付款',
        selected: false
    },
]

type selectType = {
    id: any,
    value: string
}
let roomTypeList: selectType[] = [
    {
        id: 0,
        value: '大床房'
    },
    {
        id: 1,
        value: '双床房'
    }
]
let priceList: selectType[] = [
    {
        id: 0,
        value: '100以内'
    },
    {
        id: 1,
        value: '100-200'
    },
    {
        id: 1,
        value: '200-300'
    }
]
let cancelList: selectType[] = [
    {
        id: 0,
        value: '不可取消'
    },
    {
        id: 1,
        value: '无责取消'
    },
    {
        id: 2,
        value: '条件取消'
    }
]
let nationalityList: selectType[] = [
    {
        id: 0,
        value: '中国'
    },
    {
        id: 1,
        value: '美国'
    },
    {
        id: 2,
        value: '俄罗斯'
    }
]
let foodList: selectType[] = [
    {
        id: 0,
        value: '有早餐'
    },
    {
        id: 1,
        value: '无早餐'
    }
]
type NavigateProps = {
    rateId?: string;
    hotelId?: string;
    roomId?: number
}
export default function HotelDetail() {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'hotel.id.' })

    const [
        {
            showLocation,
            showDatePicker,
            showPerson,
        },
        setFiliterControl,
    ] = useRecoilState(filiterControlAtom);
    const { id } = useParams();
    const { isOpen, onClose, onOpen } = useDisclosure();
    let [latitude, setLatitude] = useState(0);
    let [longitude, setLongitude] = useState(0);
    const navigate = useNavigate();
    const [hasFood, setHasFood] = useState('');
    const [nationa, setNationa] = useState('');
    const [cancelType, setCancelType] = useState('');
    const [price, setPrice] = useState('');
    const [hometype, setHomeType] = useState('');
    const [hotelId, setHotelId] = useState(id);
    const [searchId, setSearchId] = useState(id);
    let childs = [];
    let ages = getQueryString('childAges');
    if (ages) {
        childs = JSON.parse(ages);
    }
    let [childAges, setChildAges] = useState<Array<Number>>(childs);
    let [condition, setCondition] = useState(conditions as any[]);
    let [list, setList] = useState([] as any[]);
    let [othersList, setOthersList] = useState([] as any[]);
    let keyword = getQueryString('keyword') || '';
    if (keyword) {
        keyword = decodeURI(keyword);
    }
    const [location, setLocation] = useState(keyword);
    let adultsNum = 0;
    if (getQueryString('adults')) {
        adultsNum = Number(getQueryString('adults'));
    }
    let roomsNum = 0;
    if (getQueryString('rooms')) {
        roomsNum = Number(getQueryString('rooms'));
    }
    let childrenNum = 0;
    if (getQueryString('children')) {
        childrenNum = Number(getQueryString('children'));
    }
    let isonlyCity = 'OFF';
    if (getQueryString('onlyCity')) {
        isonlyCity = getQueryString('onlyCity') || 'OFF';
    }
    const [onlyCity, setOnlyCity] = useState(isonlyCity);
    const [adults, setAdults] = useState(adultsNum);
    const [rooms, setRooms] = useState(roomsNum);
    const [children, setChildren] = useState(childrenNum);
    const [start, setStart] = useState(getQueryString('start') || '');
    const [end, setEnd] = useState(getQueryString('end') || '');
    const [hotel, setHotel] = useState<Hotel | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [isFormSent, setFormSent] = useState<boolean>(false);
    const [ratesMap, setRatesMap] = useState<Record<string, Rate>>({});
    let [isShowImg, setIsShowImg] = useState<boolean>(false);
    let [selectedImageIndex, setSelectedImageIndex] = useState(0);
    let [facilities, setFacilities] = useState({} as any);
    let [stars, setStars] = useState<any>([]);
    useEffect(()=>{
        if (hotelId && loading) {
            getInit(hotelId);
        }
        const hideModal = () => {
            setFiliterControl((prev) => ({ ...prev, showLocation: false }));
            setFiliterControl((prev) => ({ ...prev, showPerson: false }));
            setFiliterControl((prev) => ({ ...prev, showDatePicker: false }));
        }
        window.addEventListener("click", hideModal);
        return () => {
        window.removeEventListener("click", hideModal);
        };
    },[loading,hotelId])
    const onReload = (event:any) => {
        setLoading(true);
    }
    const getInit = async (hotelId:string) => {
        let rates: Rate[];
        rates = await getHotelRates({
            token: getToken(),
            hotelId: hotelId,
            checkIn: start.replace(new RegExp('/', 'g'), '-'),
            checkOut: end.replace(new RegExp('/', 'g'), '-'),
            group: {
                adults: Number(adults),
                //   children: kids.map(({ age }) => age),
                children: [],
            },
            roomCount: Number(rooms),
        });
        let hotelInfo = await getHotel({
            token: getToken(),
            hotelId
        });
        setLongitude(hotelInfo?.location[0] || 0)
        setLatitude(hotelInfo?.location[1] || 0);
        let roomRates = hotelInfo?.rooms || [];
        let star = hotelInfo?.star || 0;
        let starlist:any = [];
        for (let i = 0; i < star; i++) {
            starlist.push(i);
        }
        setStars(starlist);
        let roomRatesList:any = [];
        hotelInfo?.rooms.forEach((item, index)=>{
            let ratesList:any = [];
            ratesList = rates?.filter(json=>{
                return Number(json?.roomId) == Number(item?.roomId);
            });
            ratesList = ratesList?.map((json:any)=>{
                json.AveragePrice = getAveragePrice(json.dailyPrice);
                return json;
            });
            let price = 0;
            if (ratesList[0]) {
                price = getAveragePrice(ratesList[0].dailyPrice);
            }
            ratesList = ratesList.sort(sortList)
            if(ratesList.length > 0) {
                roomRatesList.push({
                    roomId: item.roomId,
                    name: item.name,
                    images: item.images,
                    showMore: false,
                    list: ratesList,
                    price
                });
            }
        });
        roomRatesList = roomRatesList.sort(sortHotelList);
        if (roomRatesList[0]) {
            roomRatesList[0].showMore = true;
        }
        let others:any = [];
        others = roomRatesList.map((item:any)=> Number(item?.roomId));
        let otherslist:any = [];
        otherslist = rates.filter((item:any)=>{
            return !others.includes(Number(item?.roomId));
        });
        otherslist = otherslist.map((item:any)=>{
            item.showMore = false;
            return item;
        });
        let otherList:any = [];
        if (otherslist.length > 0) {
            otherList.push({
                images: [],
                list: otherslist,
                name: {
                    en: 'other',
                    zh_CN: '其他'
                },
                price: 0,
                roomId: '-1'
            })
        }
        if (otherList[0]) {
            otherList[0].showMore = false;
        }
        let facility:any = {};
        let keys:any = [];
        if (hotelInfo?.facilities) {
            hotelInfo?.facilities.forEach((item:any) => {
                if (keys.indexOf(item?.type) == -1) {
                    keys.push(item?.type);
                }
            });
            keys.forEach((item:any)=>{
                facility[item] = [];
            });
            hotelInfo?.facilities.forEach((item:any) => {
                facility[item?.type].push(item);
            });
        }
        setFacilities(facility);
        setOthersList(otherList);
        setList(roomRatesList);
        setHotel(hotelInfo);
        setLoading(false);
    }
    const sortHotelList =(a:any, b:any)=>{
        return a.price-b.price;
    }
    const sortList =(a:any, b:any)=>{
        return a.AveragePrice-b.AveragePrice;
    }
    const getAveragePrice = (dailyPrice: Array<number>) => {
        let AveragePrice = 0;
        let totalPrice = dailyPrice.reduce((acr, cur) =>{
            return acr + cur;
        });
        AveragePrice = Math.ceil(totalPrice / dailyPrice.length);
        return AveragePrice;
    }
    const handleActiveStyle = (isActive: boolean): BoxProps => {
        return isActive
            ? {
                color: 'blue',
                borderColor: 'blue',
            }
            : {
                color: 'gray.800',
                borderColor: 'gray.800',
            };
    };
    const showMoreDetail = (event:any) => {
        let {
            id
        } = event;
        list = list.map(item=>{
            if (item.roomId == id) {
                item.showMore = !item.showMore;
            }
            return item;
        });
        setList(list);
    }
    const showOtherMoreDetail = (event:any) => {
        let {
            id
        } = event;
        othersList = othersList.map(item=>{
            if (item.roomId == id) {
                item.showMore = !item.showMore;
            }
            return item;
        });
        setOthersList(othersList);
    }
    const changeHome = (event:any) => {
        setHomeType(event.value);
    }
    const changePrice = (event:any) => {
        setPrice(event.value);
    }
    const changeCancelType = (event:any) => {
        setCancelType(event.value);
    }
    const changeNationa = (event:any) => {
        setNationa(event.value);
    }
    const changeFood = (event:any) => {
        setHasFood(event.value);
    }
    const chooseCondition = (id:any, event:any) => {
        condition = condition.map(item=>{
            if (item.id == id) {
                item.selected = !item.selected;
            }
            return item;
        })
        setCondition(condition);
    }
    const createOrder = (event: any) => {
        let url = `/confirmorder?rateId=${event.rateId}&adults=${adults}&rooms=${rooms}&checkin=${start}&checkout=${end}&children=${children}&hotelid=${event.hotelId}&tripid=${event.hotelId}&roomId=${event.roomId}`
        navigate(url);
    }
    const resetFiliter = (event: any) => {
        setHomeType('');
        setPrice('');
        setCancelType(''); 
        setNationa('');
        setHasFood('');
        condition = condition.map(item=>{
            item.selected = false;
            return item;
        });
        setCondition(condition);
    }
    const onChangeTime = (event: any) => {
        if (event.start) {
            setStart(event.start)
        }
        if (event.end) {
            setEnd(event.end)
        }
    }
    const onLocationChange = (event: any) => {
        let {
            name,
            type,
        } = event;
        let keyword = '';
        if (typeof(name) === 'string') {
            keyword = name;
        } else {
            keyword = name['zh_CN']
        }
        setLocation(keyword);

        event?.hotelId ? setOnlyCity('OFF') : setOnlyCity('ON');
        if (event && event?.hotelId) {
            // window.open(`${window.location.origin}/hotel/${event?.hotelId}?keyword=${encodeURI(keyword)}&onlyCity=OFF&adults=${adults}&rooms=${rooms}&children=${children}&start=${start.replaceAll('\/', '\-')}&end=${end.replaceAll('\/', '\-')}&childAges=${JSON.stringify(childAges)}`);
            setSearchId(event?.hotelId);
        } else {
            setSearchId('');
        }
        if (type == 'click') {
            setFiliterControl((prev) => ({ ...prev, showDatePicker: true }));
            setFiliterControl((prev) => ({ ...prev, showPerson: false }));
            setFiliterControl((prev) => ({ ...prev, showLocation: false }));
        } else {
            setFiliterControl((prev) => ({ ...prev, showDatePicker: false }));
            setFiliterControl((prev) => ({ ...prev, showPerson: false }));
            setFiliterControl((prev) => ({ ...prev, showLocation: true }));
        }
    }
    const onChangePerson = (event: any) => {
        switch (event.type) {
            case 'roomNum':
                setRooms(event.value);
                break;
            case 'aldultNum':
                setAdults(event.value);
                break;
            case 'childNum':
                setChildren(event.value);
                if (event.action === 'reduce') {
                    childAges.pop();
                } else {
                    childAges.push(0);
                }
                setChildAges(childAges);
                break;
            case 'childAge':
                let childs = [];
                childs = childAges.map((item, index)=> {
                    if (index === event.key) {
                        item = event.value
                    }
                    return item;
                })
                setChildAges(childs);
                break;
            default:
                setRooms(event.value);
                break;
        }
    }
    const handleChangeSelectedImageIndex = (event:any) => {
        setSelectedImageIndex(event);
    }
    const showMoreImages =(event:any)=> {
        if (hotel && hotel?.images.length < 5) {
            return;
        } else {
            setIsShowImg(true);
        }
    }
    return (
        <PageContainer
            // topBoxStyle={{ position: 'static' }}
            afterHeaderComponent={
                <CommonHeader
                    showLocation={showLocation}
                    showDatePicker={showDatePicker}
                    showPerson={showPerson}
                    location={location}
                    start={start}
                    end={end}
                    rooms={rooms}
                    adults={adults}
                    children={children}
                    childAges={childAges}
                    onChangeTime={(e:any)=>onChangeTime(e)}
                    onChangeLocation={(e:any)=>onLocationChange(e)}
                    onChangePerson={(e:any)=>onChangePerson(e)}
                    onSearchList={(e:any)=>onReload(e)}
                    form='hotel'
                    hotelId={searchId}
                />
            }
        >
            {
                loading ? (
                    <LoadingModal isOpen={isFormSent || loading} />
                ) : (
                    <HStack
                        display='block'
                        as='div'
                        bgColor='#FAFAFA'
                        pl='140px'
                        pr='141px'
                        pb='63px'
                        pt='140px'
                    >
                        <Flex
                            mb='19.5px'
                            alignItems='center'
                            pt='20.5px'
                        >
                            <Text
                                fontSize='12px'
                                color='gray.800'
                                marginInlineStart='0 !important'
                                cursor='pointer'
                                onClick={(e)=>{
                                    let keyword = '';
                                    if (hotel && hotel.city.zh_CN) {
                                        keyword = hotel.city.zh_CN;
                                    }
                                    // navigate(`/?adults=${adults}&keyword=${encodeURI(keyword)}&rooms=${rooms}&children=${children}&start=${start.replaceAll('\/', '\-')}&end=${end.replaceAll('\/', '\-')}&childAges=${JSON.stringify(childAges)}`);
                                    navigate('/')
                                }}
                            >
                                {getI18NText("酒店")}
                            </Text>
                            <Icon
                                as={IoChevronForwardOutline}
                                boxSize='1rem'
                                color='gray.800'
                                // mx='14.59px'
                                mx='8.59px'
                            />
                            <Text
                                fontSize='12px'
                                color='blue'
                                marginInlineStart='0 !important'
                                cursor='pointer'
                                onClick={(e)=>{
                                    let keyword = '';
                                    if (hotel && hotel.city.zh_CN) {
                                        keyword = hotel.city.zh_CN;
                                    }
                                    navigate(`/list?adults=${adults}&keyword=${encodeURI(keyword)}&rooms=${rooms}&children=${children}&start=${start.replaceAll('\/', '\-')}&end=${end.replaceAll('\/', '\-')}&childAges=${JSON.stringify(childAges)}`);
                                }}
                            >
                                {hotel?.city.zh_CN}
                            </Text>
                            <Icon
                                as={IoChevronForwardOutline}
                                boxSize='1rem'
                                color='gray.800'
                                // mx='14.59px'
                                mx='8.59px'
                            />
                            <Text
                                fontSize='12px'
                                color='blue'
                                marginInlineStart='0 !important'
                            >
                                {hotel?.name.zh_CN}
                            </Text>
                        </Flex>
                        <Flex
                            justifyContent='space-between'
                            marginInlineStart='0 !important'
                            mb='16.57px !important'
                        >
                            <HStack
                                w='670px'
                                justifyContent='space-between'
                            >
                                <Image
                                    // src='https://cdn.pixabay.com/photo/2021/09/03/04/40/orange-gull-6594958_960_720.jpg'
                                    src={hotel?.images[0] ? hotel?.images[0].url : DefaultImg}
                                    w='374px'
                                    h='299.43px'
                                    borderRadius='4px'
                                    objectFit='cover'
                                />
                                <HStack
                                    w='288px'
                                    flexWrap='wrap'
                                    justifyContent='space-between'
                                >
                                    <Image
                                        src={hotel?.images[1] ? hotel?.images[1].url : DefaultImg}
                                        w='288px'
                                        h='150.22px'
                                        borderRadius='4px'
                                        mb='8.12px'
                                        objectFit='cover'
                                    />
                                    <Image
                                        src={hotel?.images[2] ? hotel?.images[2].url : DefaultImg}
                                        w='140px'
                                        h='141.65px'
                                        borderRadius='4px'
                                        marginInlineStart='0 !important'
                                        objectFit='cover'
                                    />
                                    <Image
                                        src={(hotel && hotel?.images.length > 4) ? MoreIcon : DefaultImg}
                                        w='140px'
                                        h='141.65px'
                                        borderRadius='4px'
                                        marginInlineStart='0 !important'
                                        objectFit='cover'
                                        cursor={(hotel && hotel?.images.length > 4) ? 'pointer' : 'initial'}
                                        onClick={(e)=>showMoreImages(e)}
                                    />
                                </HStack>
                            </HStack>
                            <HStack
                                display='block'
                                as='div'
                                w='480px'
                                h='300px'
                                borderRadius='4px'
                                marginInlineStart='0 !important'
                                bgColor='white'
                                p='24px 20px 13px 24px'
                                position='relative'
                            >
                                <Flex
                                    alignItems='center'
                                    justifyContent='space-between'
                                    mb='8px'
                                >
                                    <Text
                                        fontSize='16px'
                                        color='gray.800'
                                        letterSpacing='0 !important'
                                    >
                                        {hotel?.city.zh_CN},{hotel?.country.zh_CN}
                                    </Text>
                                    {/* <Text
                                        color='white'
                                        bgColor='#2D6CDF'
                                        w='63px'
                                        h='24px'
                                        lineHeight='24px'
                                        textAlign='center'
                                        fontSize='12px'
                                        letterSpacing='0 !important'
                                    >
                                        {hotel?.star}/5分
                                    </Text> */}
                                </Flex>
                                <HStack
                                    alignItems='center'
                                    mb='4px'
                                    marginInlineStart='0 !important'
                                >
                                    <Text
                                        fontSize='28px'
                                        fontWeight='500'
                                        color='fontColor'
                                        marginInlineStart='0 !important'
                                        letterSpacing='0 !important'
                                    >
                                        {hotel?.name.zh_CN}
                                        {
                                            stars.map((item:any)=>
                                                <Icon
                                                    key={item}
                                                    as={IoStar}
                                                    boxSize="1rem"
                                                    color='#FEC214'
                                                    mr='2px'
                                                />    
                                            )
                                        }
                                    </Text>
                                </HStack>
                                <Text
                                    fontSize='16px'
                                    lineHeight='22.4px'
                                    fontWeight='400'
                                    color='fontColor'
                                    mb='8px !important'
                                    marginInlineStart='0 !important'
                                    letterSpacing='0 !important'
                                >
                                    {hotel?.name.en}
                                </Text>
                                <HStack
                                    alignItems='center'
                                    mb='10px !important'
                                    marginInlineStart='0 !important'
                                >
                                    <Icon
                                        as={IoLocationOutline}
                                        boxSize="1.25rem"
                                        color='gray.800'
                                        mr='8.5px'
                                    />
                                    <Text
                                        color='gray.800'
                                        fontSize='14px'
                                        marginInlineStart='0 !important'
                                        mr='4px'
                                        letterSpacing='0 !important'
                                    >
                                        {hotel?.address.zh_CN}
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                        onClick={onOpen}
                                    >
                                        {getI18NText("查看地图")}
                                    </Text>
                                </HStack>
                                {/* <HStack
                                    alignItems='center'
                                    mb='10px !important'
                                    marginInlineStart='0 !important'
                                >
                                    <Icon
                                        as={IoCallOutline}
                                        boxSize="1.25rem"
                                        color='gray.800'
                                        mr='8.5px'
                                    />
                                    <Text
                                        color='gray.800'
                                        fontSize='14px'
                                        marginInlineStart='0 !important'
                                        letterSpacing='0 !important'
                                    >
                                        86-10-68 41 22 11
                                    </Text>
                                </HStack> */}
                                {/* <HStack
                                    position='absolute'
                                    left='24px'
                                    bottom='13px'
                                    w='435px'
                                    justifyContent='flex-end'
                                    alignItems='baseline'
                                    pt='8px'
                                    h='39px'
                                    borderTopWidth='1px'
                                    borderTopColor='gray.200'
                                >
                                    <Text
                                        fontSize='12px'
                                        color='fontColor'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText("最低价")}¥  
                                    </Text>
                                    <Text
                                        fontSize='22px'
                                        color='blue'
                                        fontWeight='600'
                                        mr='4px'
                                        letterSpacing='0 !important'
                                    >
                                        1,095
                                    </Text>
                                    <Text
                                        fontSize='12px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText("起")}
                                    </Text>
                                </HStack> */}
                            </HStack>
                        </Flex>
                        {/* <HStack
                            justifyContent='flex-end'
                            mb='8px !important'
                        >
                            <Text
                                fontSize='12px'
                                lineHeight='17px'
                                color='blue'
                                cursor='pointer'
                                letterSpacing='0 !important'
                                onClick={(e)=>resetFiliter(e)}
                            >
                                {getI18NText("清空")}
                            </Text>
                        </HStack> */}
                        {/* <HStack
                            alignItems='center'
                            mb='12px !important'
                        >
                            <Select
                                placeholder={getI18NText('床型')}
                                w='223px'
                                h='40px'
                                mr='11px'
                                value={hometype}
                                list={roomTypeList.map(item => ({ ...item, value: getI18NText(item.value) }))}
                                onChange={(e:any)=>changeHome(e)}
                            />
                            <Select
                                placeholder={getI18NText('价格范围')}
                                w='223px'
                                h='40px'
                                mr='11px'
                                value={price}
                                list={priceList.map(item => ({ ...item, value: getI18NText(item.value) }))}
                                onChange={(e:any)=>changePrice(e)}
                            />
                            <Select
                                placeholder={getI18NText('取消政策')}
                                w='223px'
                                h='40px'
                                mr='11px'
                                value={cancelType}
                                list={cancelList.map(item => ({ ...item, value: getI18NText(item.value) }))}
                                onChange={(e:any)=>changeCancelType(e)}
                            />
                            <Select
                                placeholder={getI18NText('国籍')}
                                w='223px'
                                h='40px'
                                mr='11px'
                                value={nationa}
                                list={nationalityList.map(item => ({ ...item, value: getI18NText(item.value) }))}
                                onChange={(e:any)=>changeNationa(e)}
                            />
                            <Select
                                placeholder={getI18NText('餐点')}
                                w='223px'
                                h='40px'
                                mr='0px'
                                value={hasFood}
                                list={foodList.map(item => ({ ...item, value: getI18NText(item.value) }))}
                                onChange={(e:any)=>changeFood(e)}
                            />
                        </HStack> */}
                        {/* <HStack
                            mb='29px !important'
                            flexWrap='wrap'
                        >
                            {
                                condition.map(item=>
                                    <Text
                                        key={item.id}
                                        h='40px'
                                        lineHeight='40px'
                                        px='16px'
                                        borderRadius='4px'
                                        marginInlineStart='16 !important'
                                        bgColor='white'
                                        borderWidth='1px'
                                        cursor='pointer'
                                        letterSpacing='0 !important'
                                        {...handleActiveStyle(item.selected)}
                                        onClick={(e)=>chooseCondition(item.id, e)}
                                        _hover={{
                                            color: 'blue',
                                            bgColor: '#EBEEF5',
                                            borderColor: '#002C5E',
                                        }}
                                    >
                                        {getI18NText(item.name)}
                                    </Text>
                                )
                            }
                        </HStack> */}
                        <Text
                            fontWeight='500'
                            fontSize='28px'
                            color='blue'
                            lineHeight='33.6px'
                            mb='24px !important'
                            letterSpacing='0 !important'
                        >
                            {getI18NText("房型价格")}
                        </Text>
                        <Stack
                            spacing='16px'
                        >
                            {
                                list.map((item, index)=>
                                    <RoomType
                                        img={hotel?.images[0] ? hotel?.images[0].url : DefaultImg}
                                        data={item}
                                        key={index}
                                        onNavigate={(obj: NavigateProps)=> createOrder(obj)}
                                        onShow={(e:any)=>showMoreDetail(e)}
                                        type={1}
                                    />    
                                )
                            }
                            {
                                othersList.length > 0 && othersList.map((item, index)=>
                                    <RoomType
                                        img={OtherImg}
                                        data={item}
                                        key={index}
                                        onNavigate={(obj: NavigateProps)=> createOrder(obj)}
                                        onShow={(e:any)=>showOtherMoreDetail(e)}
                                        type={0}
                                    />    
                                )
                            }
                            {
                                (othersList.length == 0 && list.length == 0) && 
                                <Empty
                                    tip='您慢了一步！目前无任一房型报价，建议您重新搜索其他酒店。'
                                />
                            }
                        </Stack>
                        <Text
                            fontSize='28px'
                            fontWeight='500'
                            color='blue'
                            lineHeight='33.6px'
                            my='16px !important'
                            letterSpacing='0 !important'
                        >
                            {getI18NText("酒店政策")}
                        </Text>
                        <Box
                            p='24px'
                            boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
                            borderRadius='4px'
                            bgColor='white'
                        >
                            <Flex>
                                <HStack
                                    h='25px'
                                    alignItems='center'
                                    w='276px'
                                >
                                    <Image
                                        src={roomIcon}
                                        w='24px'
                                        h='24px'
                                        mr='4px'
                                    />
                                    <Text
                                        fontSize='18px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText("入住及退房")}
                                    </Text>
                                </HStack>
                                <Box
                                    flex='1'
                                >
                                    {/* <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText("入住时间开始于")} 14:00
                                    </Text> */}
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        --
                                    </Text>
                                </Box>
                            </Flex>
                            <Divider orientation='horizontal' bgColor='gray.200' my='8px'/>
                            <Flex>
                                <HStack
                                    h='25px'
                                    alignItems='center'
                                    w='276px'
                                >
                                    <Image
                                        src={petIcon}
                                        w='24px'
                                        h='24px'
                                        mr='4px'
                                    />
                                    <Text
                                        fontSize='18px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText("宠物")}
                                    </Text>
                                </HStack>
                                <Box
                                    flex='1'
                                >
                                    {/* <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText("不允许宠物入住")}
                                    </Text> */}
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >--</Text>
                                </Box>
                            </Flex>
                            <Divider orientation='horizontal' bgColor='gray.200' my='8px'/>
                            <Flex>
                                <HStack
                                    h='25px'
                                    alignItems='center'
                                    w='276px'
                                >
                                    <Image
                                        src={tipsIcon}
                                        w='24px'
                                        h='24px'
                                        mr='4px'
                                    />
                                    <Text
                                        fontSize='18px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText("出行提示")}
                                    </Text>
                                </HStack>
                                <Box
                                    flex='1'
                                >
                                    {/* <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        只有经过登记的住客才可进入客房。
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        酒店可提供连通房/相邻房，具体视空房情况而定。住客可使用预订确认中的联系信息向酒店提出要求。
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        此住宿表示当前已实施更严格的清洁与住客安全保护措施。
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        已使用消毒剂清洁住宿；住宿在下一批客人入住前会使用消毒剂清洁公共接触表面；床单和毛巾会经过至少 60℃ 的高温清洁。
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        将为住客提供个人防护用品，包括口罩。
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        实施社交距离措施；住宿员工佩戴了个人防护用品；定期对员工进行体温检测；可为住客提供体温检测；向住客提供洗手液。
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        住宿确认执行下列清洁和消毒规程：洲际“洲全”清洁安全承诺。
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        住宿接待方欢迎所有性取向和性别认同的客人（LGBTQ 友好）。
                                    </Text> */}
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >--</Text>
                                </Box>
                            </Flex>
                            <Divider orientation='horizontal' bgColor='gray.200' my='8px'/>
                            <Flex>
                                <HStack
                                    h='25px'
                                    alignItems='center'
                                    w='276px'
                                >
                                    <Image
                                        src={feeIcon}
                                        w='24px'
                                        h='24px'
                                        mr='4px'
                                    />
                                    <Text
                                        fontSize='18px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText("费用")}
                                    </Text>
                                </HStack>
                                <Box
                                    flex='1'
                                >
                                    {/* <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        mb='40px'
                                        letterSpacing='0 !important'
                                    >
                                        以下费用和押金由酒店在提供服务、办理入住或退房手续时收取。 
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        自助式早餐收费：每人大约 CNY 148
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        机场接送费：每辆车 CNY480（单程） 
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        机场接送费/每位儿童：CNY480（单程），（收费年龄为 1至 18 岁） 
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        代客停车费用：每天 CNY240 
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        付费可提前入住（视供应情况而定、金额不定） 
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        需付延时退房费（视供应情况而定、金额不定）
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >
                                        折叠床使用费：每晚 CNY 402.0 上面所列内容可能并不完整。
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        letterSpacing='0 !important'
                                        color='gray.100'
                                    >
                                        这些费用和押金可能不包括税款，并且可能会随时发生变化。
                                    </Text> */}
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >--</Text>
                                </Box>
                            </Flex>
                        </Box>
                        <Text
                            fontSize='28px'
                            fontWeight='500'
                            color='blue'
                            lineHeight='33.6px'
                            my='16px !important'
                            letterSpacing='0 !important'
                        >
                            {getI18NText("酒店周遭")}
                        </Text>
                        <Box
                            p='24px'
                            boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
                            borderRadius='4px'
                            bgColor='white'
                        >
                            <Flex>
                                <HStack
                                    h='25px'
                                    alignItems='center'
                                    w='276px'
                                >
                                    <Image
                                        src={viewpointIcon}
                                        w='24px'
                                        h='24px'
                                        mr='4px'
                                    />
                                    <Text
                                        fontSize='18px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText("附近景点")}
                                    </Text>
                                </HStack>
                                <Box
                                    flex='1'
                                >
                                    <Flex>
                                        <Text
                                            fontSize='14px'
                                            lineHeight='28px'
                                            color='gray.100'
                                            letterSpacing='0 !important'
                                        >--</Text>
                                    </Flex>
                                </Box>
                            </Flex>
                            <Divider orientation='horizontal' bgColor='gray.200' my='8px'/>
                            <Flex>
                                <HStack
                                    h='25px'
                                    alignItems='center'
                                    w='276px'
                                >
                                    <Image
                                        src={trafficIcon}
                                        w='24px'
                                        h='24px'
                                        mr='4px'
                                    />
                                    <Text
                                        fontSize='18px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText("附近交通")}
                                    </Text>
                                </HStack>
                                <Box
                                    flex='1'
                                >
                                    <Text
                                        fontSize='14px'
                                        lineHeight='28px'
                                        color='gray.100'
                                        letterSpacing='0 !important'
                                    >--</Text>
                                </Box>
                            </Flex>
                        </Box>
                        <Text
                            fontSize='28px'
                            fontWeight='500'
                            color='blue'
                            lineHeight='33.6px'
                            my='16px !important'
                            letterSpacing='0 !important'
                        >
                            {getI18NText("酒店设施")}
                        </Text>
                        <Box
                            p='24px'
                            boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
                            borderRadius='4px'
                            bgColor='white'
                        >
                            <Flex>
                                <HStack
                                    h='25px'
                                    alignItems='center'
                                    w='276px'
                                >
                                    <Image
                                        src={hotelserviceIcon}
                                        w='24px'
                                        h='24px'
                                        mr='4px'
                                    />
                                    <Text
                                        fontSize='18px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText("酒店服务")}
                                    </Text>
                                </HStack>
                                {
                                    (facilities['FACILITY_HOTEL'] && facilities['FACILITY_HOTEL'].length > 0) ? (
                                        <Flex
                                            flex='1'
                                            flexWrap='wrap'
                                        >
                                            {
                                                facilities['FACILITY_HOTEL'] && facilities['FACILITY_HOTEL'].map((item:any)=>
                                                    <HStack
                                                        alignItems='center'
                                                        marginInlineStart='0 !important'
                                                        w='25%'
                                                        h='28px'
                                                        mb='4px'
                                                    >
                                                        <Image
                                                            src={CheckImg}
                                                            w='18px'
                                                            h='18px'
                                                            mr='13px'
                                                        />
                                                        <Popover
                                                            placement='right'
                                                            closeOnBlur={false}
                                                            trigger='hover'
                                                        >
                                                            <PopoverTrigger>
                                                                <Text
                                                                    fontSize='14px'
                                                                    lineHeight='28px'
                                                                    color='gray.100'
                                                                    letterSpacing='0 !important'
                                                                    marginInlineStart='0 !important'
                                                                    cursor='pointer'
                                                                >
                                                                    {item.value?.zh_CN.length < 10 ? item.value?.zh_CN : `${item.value?.zh_CN.substr(0, 10)}...`}
                                                                </Text>
                                                            </PopoverTrigger>
                                                            <PopoverContent
                                                                w='218px'
                                                                color='white'
                                                                bg='blue'
                                                                borderColor='blue'
                                                                p='12px 30px 12px 12px'
                                                            >
                                                                <PopoverArrow bg='blue' />
                                                                {/* <PopoverCloseButton /> */}
                                                                <PopoverBody
                                                                    p='0 !important'
                                                                >
                                                                     <Text
                                                                        fontSize='14px'
                                                                        lineHeight='28px'
                                                                        letterSpacing='0 !important'
                                                                        marginInlineStart='0 !important'
                                                                        color='white'
                                                                    >
                                                                        {item.value?.zh_CN}
                                                                    </Text>
                                                                </PopoverBody>
                                                            </PopoverContent>
                                                        </Popover>
                                                    </HStack>
                                                )
                                            }
                                        </Flex> 
                                    ) : (
                                        <Box
                                            flex='1'
                                        >
                                            <Text
                                                fontSize='14px'
                                                lineHeight='28px'
                                                color='gray.100'
                                                letterSpacing='0 !important'
                                            >--</Text>
                                        </Box>
                                    )
                                }
                            </Flex>
                            <Divider orientation='horizontal' bgColor='gray.200' my='8px'/>
                            <Flex>
                                <HStack
                                    h='25px'
                                    alignItems='center'
                                    w='276px'
                                >
                                    <Image
                                        src={roomserviceIcon}
                                        w='24px'
                                        h='24px'
                                        mr='4px'
                                    />
                                    <Text
                                        fontSize='18px'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText("客房服务")}
                                    </Text>
                                </HStack>
                                {
                                    (facilities['FACILITY_ROOM'] && facilities['FACILITY_ROOM'].length > 0) ? (
                                        <Flex
                                            flex='1'
                                            flexWrap='wrap'
                                        >
                                            {
                                                facilities['FACILITY_ROOM'] && facilities['FACILITY_ROOM'].map((item:any)=>
                                                    <HStack
                                                        alignItems='center'
                                                        marginInlineStart='0 !important'
                                                        w='25%'
                                                        h='28px'
                                                        mb='4px'
                                                    >
                                                        <Image
                                                            src={CheckImg}
                                                            w='18px'
                                                            h='18px'
                                                            mr='13px'
                                                        />
                                                        <Popover
                                                            placement='right'
                                                            closeOnBlur={false}
                                                            trigger='hover'
                                                        >
                                                            <PopoverTrigger>
                                                                <Text
                                                                    fontSize='14px'
                                                                    lineHeight='28px'
                                                                    color='gray.100'
                                                                    letterSpacing='0 !important'
                                                                    marginInlineStart='0 !important'
                                                                    cursor='pointer'
                                                                >
                                                                    {item.value?.zh_CN.length < 10 ? item.value?.zh_CN : `${item.value?.zh_CN.substr(0, 10)}...`}
                                                                </Text>
                                                            </PopoverTrigger>
                                                            <PopoverContent
                                                                w='218px'
                                                                color='white'
                                                                bg='blue'
                                                                borderColor='blue'
                                                                p='12px 30px 12px 12px'
                                                            >
                                                                <PopoverArrow bg='blue' />
                                                                {/* <PopoverCloseButton /> */}
                                                                <PopoverBody
                                                                    p='0 !important'
                                                                >
                                                                     <Text
                                                                        fontSize='14px'
                                                                        lineHeight='28px'
                                                                        letterSpacing='0 !important'
                                                                        marginInlineStart='0 !important'
                                                                        color='white'
                                                                    >
                                                                        {item.value?.zh_CN}
                                                                    </Text>
                                                                </PopoverBody>
                                                            </PopoverContent>
                                                        </Popover>
                                                    </HStack>
                                                )
                                            }
                                        </Flex> 
                                    ) : (
                                        <Box
                                            flex='1'
                                        >
                                            <Text
                                                fontSize='14px'
                                                lineHeight='28px'
                                                color='gray.100'
                                                letterSpacing='0 !important'
                                            >--</Text>
                                        </Box>
                                    )
                                }
                            </Flex>
                        </Box>
                    </HStack>
                )
            }
            <MapModal isOpen={isOpen} latitude={latitude} longitude={longitude} onClose={onClose} />
            <ImgShowModal
                isOpen={isShowImg}
                imageLists={hotel?.images || []}
                selectedImageIndex={selectedImageIndex}
                onChangeSelectedImageIndex={(e:any)=>handleChangeSelectedImageIndex(e)}
                onClose={(e:any)=> setIsShowImg(false)}
            />
        </PageContainer>
    )
}