import axios from 'axios';

type AuthUpdatepasswordRequest = {
  token: string;
  oldPassword: string;
  newPassword: string; 
};

export default async ({ token, oldPassword, newPassword }: AuthUpdatepasswordRequest): Promise<any> => {
  try {
    const res = await axios.post<{
      oldPassword:string,
      newPassword:string
    }>(
      `${process.env.REACT_APP_AWS_HTTP_BASE_URL}/auth/updatepassword`, 
      {
        oldPassword,
        newPassword
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    return;
  }
};
