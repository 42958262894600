import React from 'react';
import {
    Box,
    Image,
    Text,
    Flex
} from '@chakra-ui/react';
import EmptyIcon from '~/assets/images/empty.png';

type EmptyProps = {
    tip: string;
}
export default function Empty ({tip}:EmptyProps) {
    return (
        <Box
            h='196px'
            bgColor='white'
            boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
            borderRadius='4px'
            pt='33px'
        >
            <Flex
                justifyContent='center'
                mb='4px'
                marginInlineStart='0 !important'
            >
                <Image
                    src={EmptyIcon}
                    w='104px'
                    h='104px'
                />
            </Flex>
            <Text
                textAlign='center'
                color='#333'
                fontSize='16px'
                fontWeight='normal'
                letterSpacing='0 !important'
            >
                {tip}
            </Text>
        </Box>
    )
}