// import { useState } from 'react';
import {
    Box,
    Divider,
    Flex,
    HStack,
    Text,
} from '@chakra-ui/react';
import {
    CancelPolicy
} from '~/types';
import formatDate from 'date-fns/format';

import { useI18NText } from '~/i18n/i18n';


let style = {
    timeStyle: {
        w: '105px',
        mr: '11px',
        fontSize: '14px',
        color: 'gray.800',
        letterSpacing: '0 !important'
    },
    centerStyle: {
        // w: '105px',
        flex: 1,
        // mr: '11px',
        fontSize: '14px',
        color: 'gray.800',
        letterSpacing: '0 !important'
    },
    descStyle: {
        w: '65px',
        fontSize: '14px',
        letterSpacing: '0 !important'
        
    }
}

type cancelPoliciesProps = {
    cancelPolicies?: Array<CancelPolicy>
}

export default function CancelPolicyInfo({
    cancelPolicies = []
} : cancelPoliciesProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })

    return (
        <HStack
            display='block'
            as='div'
            bgColor='white'
            borderRadius='4px'
            boxShadow='0px 0px 3px rgba(0, 0, 0, 0.15)'
            p='16px'
        >
            <Text
                fontSize='18px'
                color='fontColor'
                lineHeight='25.2px'
                mb='12px'
                letterSpacing='0 !important'
            >
                {getI18NText('取消政策')}
            </Text>
            <Box
                borderTop='1px'
                borderTopStyle='dashed'
                borderTopColor='gray.400'
                p='4px'
                marginInlineStart='0 !important'
            >
                {
                    cancelPolicies.map((item, index)=>{
                        let {
                            endAt,
                            fromTime,
                            currency,
                            percent,
                            refund
                        } = item;
                        if (refund == 0) {
                            return (
                                <Flex
                                    pt='12px'
                                    pb='6px'
                                    alignItems='center'
                                    borderBottomColor='#F2F2F2'
                                    borderBottomWidth={index < cancelPolicies.length - 1 ? '1px' : 0}
                                    borderBottomStyle='solid'
                                >
                                    <Text {...style.timeStyle} w='105px'>{formatDate(new Date(endAt), 'yyyy-MM-dd HH:mm')} {getI18NText('起')}</Text>
                                    <Text {...style.centerStyle} w='105px' mx='11px' textAlign='center'>-</Text>
                                    <Text {...style.descStyle} w='65px' color='#FE5D6D' textAlign='right'>{getI18NText('不可取消')}</Text>
                                </Flex>
                            )
                        }
                        if (percent == 1) {
                            return (
                                <Flex
                                    pt='12px'
                                    pb='6px'
                                    alignItems='center'
                                    borderBottomColor='#F2F2F2'
                                    borderBottomWidth={index < cancelPolicies.length - 1 ? '1px' : 0}
                                    borderBottomStyle='solid'
                                >
                                    <Text {...style.timeStyle} w='105px'>{formatDate(new Date(endAt), 'yyyy-MM-dd HH:mm')}{getI18NText('前')}</Text>
                                    <Text {...style.centerStyle} w='105px' mx='11px' textAlign='center'>-</Text>
                                    <Text {...style.descStyle} w='65px' color='#59CFD5' textAlign='right'>{getI18NText('免费取消')}</Text>
                                </Flex>
                            )
                        }
                        else {
                            return (
                                <Flex
                                    pt='12px'
                                    pb='6px'
                                    alignItems='center'
                                    borderBottomColor='#F2F2F2'
                                    borderBottomWidth={index < cancelPolicies.length - 1 ? '1px' : 0}
                                    borderBottomStyle='solid'
                                >
                                    <HStack
                                        as='div'
                                        display='block'
                                        w='105px'
                                    >
                                        <Text {...style.timeStyle} marginInlineStart='0 !important'>{formatDate(new Date(fromTime), 'yyyy-MM-dd HH:mm')}</Text>
                                        <Text {...style.timeStyle} marginInlineStart='0 !important'>{getI18NText('至')}</Text>
                                        <Text {...style.timeStyle} marginInlineStart='0 !important'>{formatDate(new Date(endAt), 'yyyy-MM-dd HH:mm')}</Text>
                                    </HStack>
                                    <Text {...style.centerStyle} w='105px' mx='11px' textAlign='center'> {getI18NText('需要扣取')}</Text>
                                    <Text {...style.descStyle} w='65px' textAlign='right'>¥ 304</Text>
                                </Flex>
                            )
                        }
                    })
                }
                {/* <Flex
                    pt='12px'
                    pb='6px'
                    alignItems='center'
                >
                    <Text {...style.timeStyle} w='105px'>12-28 17:50:59{getI18NText('前')}</Text>
                    <Text {...style.centerStyle} w='105px' mx='11px' textAlign='center'>-</Text>
                    <Text {...style.descStyle} w='65px' color='#59CFD5' textAlign='right'>{getI18NText('免费取消')}</Text>
                </Flex>
                <Divider orientation='horizontal' bgColor='gray.200' />
                <Flex
                    pt='12px'
                    pb='6px'
                    alignItems='center'
                >
                    <HStack
                        as='div'
                        display='block'
                        w='105px'
                    >
                        <Text {...style.timeStyle} marginInlineStart='0 !important'>12-29 17:51:00</Text>
                        <Text {...style.timeStyle} marginInlineStart='0 !important'>{getI18NText('至')}</Text>
                        <Text {...style.timeStyle} marginInlineStart='0 !important'>01-21 23:59:59</Text>
                    </HStack>
                    <Text {...style.centerStyle} w='105px' mx='11px' textAlign='center'> {getI18NText('需要扣取')}</Text>
                    <Text {...style.descStyle} w='65px' textAlign='right'>¥ 304</Text>
                </Flex>
                <Divider orientation='horizontal' bgColor='gray.200' />
                <Flex
                    pt='12px'
                    pb='6px'
                    alignItems='center'
                >
                    <Text {...style.timeStyle} w='105px'>01-03 00:00:00 {getI18NText('起')}</Text>
                    <Text {...style.centerStyle} w='105px' mx='11px' textAlign='center'>-</Text>
                    <Text {...style.descStyle} w='65px' color='#FE5D6D' textAlign='right'>{getI18NText('不可取消')}</Text>
                </Flex> */}
            </Box>
        </HStack>
    )
}