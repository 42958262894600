import { ComponentSingleStyleConfig } from '@chakra-ui/react';

const Checkbox: ComponentSingleStyleConfig = {
  baseStyle: {
    control: {
      border: '2px solid',
      borderColor: 'gray.200',
      _checked: {
        bgColor: 'dark',
        border: 'none',
      },
      _focus: {
        boxShadow: 'unset',
      },
    },
    label: {
      display: 'block',
      w: 'full',
    },
  },
};

export default Checkbox;
