import { atom } from 'recoil';

const filiterControl = atom({
  key: 'globalFiliterControl',
  default: {
    showLocation: false,
    showDatePicker: false,
    showPerson: false,
  },
});

export default filiterControl;
