import { useState, useEffect, useRef } from 'react';
import {
    Badge,
    Button,
    Box,
    Divider,
    HStack,
    Flex,
    Stack,
    VStack,
    Text,
    Image
} from '@chakra-ui/react';
import {
    getQueryString,
    getToken
} from '~/utils/tools';
import getBooking from '~/awsHttpApi/getBooking';
import cancelBooking from '~/awsHttpApi/cancelBooking';
import getHotel from '~/awsHttpApi/getHotel';
import { booking as fakeBooking } from '~/assets/fakeData';
import BookingDetail from '~/components/BookingDetail';
import CancelPolicy from '~/components/CancelPolicy';
import CheckInInformation from '~/components/CheckInInformation';
import ContactInformation from '~/components/ContactInformation';
import HotelInfo from '~/components/HotelInfo/index';
import PayType from '~/components/PayType';
import Quote from '~/components/Quote';
import Countdown from '~/components/Countdown';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '~/containers/PageContainer';
import startOfDay from 'date-fns/startOfDay';
import differenceInDays from 'date-fns/differenceInDays';
import parseISO from 'date-fns/parseISO';
import formatDate from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import compareAsc from 'date-fns/compareAsc';
import modalControlAtom from '~/recoil/atom/modal';
import payControlAtom from '~/recoil/atom/pay';
import LoadingModal from '~/components/LoadingModal';
import { useSetRecoilState, useRecoilState } from 'recoil';
import StepTwo from '~/assets/images/stepTwo.png';
import ClockIcon from '~/assets/images/clock.png';
import {
  Bed,
  Meal,
  FetchingState,
  Booking,
  RoomInfo,
  BookingStatus,
  OrderType,
  PaymentType,
  Lang,
  paymentAppUrlMapProps
} from '~/types';
import DefaultImg from '~/assets/images/default.png';
import { useI18NText } from '~/i18n/i18n';

interface bedProps {
    code: string,
    count: number,
    desc: string,
    seq: number
}
interface HotelProps{
    name: Record<Lang, string>,
    address: Record<Lang, string>,
    price: number,
    profitMargin: number
}

export default function OrderDetail() {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'order.orderId.' });
    const intervalRef = useRef<any>(null);
    const setModalControl = useSetRecoilState(modalControlAtom);
    const setPayControlAtom = useSetRecoilState(payControlAtom);
    const [
        {
            refreshOrder
        }
    ] = useRecoilState(payControlAtom);
    console.log('refreshOrder', refreshOrder)
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [isFormSent, setFormSent] = useState<boolean>(false);
    const { orderId } = useParams();
    const [booking, setBooking] = useState<Booking>(fakeBooking);
    const [hotelRoom, setHotelRoom] = useState<RoomInfo | null>(null);
    let [bedInfo, setBedIndo] = useState({});
    let [hotelDetail, setHotelDetail] = useState({})
    const [fetchBookingStatus, setFetchBookingStatus] = useState<FetchingState>(FetchingState.INIT);
    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.Wechat);
    const [paymentQrCodeDataUrl, setPaymentQrCodeDataUrl] = useState<string>('');
    let [selectPay, setSelectPay] = useState('wechat');
    let [paymentURL, setPaymentURL] = useState<paymentAppUrlMapProps>({
        Wechat: ''
    });
    let [end, setEnd] = useState('');
    let [refresh, setRefresh] = useState(false);
    useEffect(()=>{
        if (!refresh) {
            window.scrollTo(0, 0);
            setRefresh(true);
        }
        if (loading) {
            getDetail();
        }
        intervalRef.current = setInterval(() => {
            getDetail();
        }, 2000);
        if (refreshOrder) {
            clearInterval(intervalRef.current);
            getOrderStatus();
        }
        if (booking?.status == 'INVALID') {
            clearInterval(intervalRef.current);
            setModalControl((prev) => ({ ...prev, isWechatPayModalOpen: false }));
            navigate(`/order/${encodeURIComponent(booking.bookingId)}`);
        }
        if (booking.status == 'PROCESSING') {
            clearInterval(intervalRef.current);
            setModalControl((prev) => ({ ...prev, isWechatPayModalOpen: false }));
            navigate(`/payresult/${encodeURIComponent(booking.bookingId)}`);
        }
    },[loading, booking, refreshOrder, refresh]);
    const getOrderStatus  = async() => {
        if (!orderId) return;
        let bookingFromApi = await getBooking({ token:getToken(), bookingId: orderId });
        if (!bookingFromApi) {
            setFetchBookingStatus(FetchingState.FAILED);
            return;
        }
        if (booking.status != 'PENDING') {
            setLoading(true);
            const hotel = await getHotel({
                token: getToken(),
                hotelId: bookingFromApi.rates[0].hotelId,
            });
            if (!hotel) {
                return;
            }
            const hotelRoom = hotel.rooms.find(({ roomId }) => 
                roomId === bookingFromApi?.rates[0].roomId.toString(),
            );
            bookingFromApi.rates.forEach((rate) =>
                rate.cancelPolicies.sort((a, b) => compareAsc(parseISO(a.endAt), parseISO(b.endAt))),
            );
            let bed:Bed = JSON.parse(bookingFromApi.rates[0].bed)[0];
            let hotelDetail = bookingFromApi.hotel;
            let paymentAppUrlMap = bookingFromApi.bookingPayment?.paymentAppUrlMap;
            const end: any = Math.round(Number(bookingFromApi.bookingPayment?.invalidAt) / 1000);
            setPayControlAtom((prev) => ({ ...prev, payTime: end }));
            setEnd(end);
            setHotelDetail(hotelDetail);
            setBedIndo(bed);
            setBooking(bookingFromApi);
            setHotelRoom(hotelRoom || null);
            if (paymentAppUrlMap) {
                setPaymentURL(paymentAppUrlMap);
            }
            setFetchBookingStatus(FetchingState.SUCCESS);
            setLoading(false);
        }
    }
    const getDetail = async() => {if (!orderId) return;
        let bookingFromApi = await getBooking({ token:getToken(), bookingId: orderId });
        if (!bookingFromApi) {
            setFetchBookingStatus(FetchingState.FAILED);
            return;
        }
        const hotel = await getHotel({
            token: getToken(),
            hotelId: bookingFromApi.rates[0].hotelId,
        });
        if (!hotel) {
            return;
        }
        const hotelRoom = hotel.rooms.find(({ roomId }) => 
            roomId === bookingFromApi?.rates[0].roomId.toString(),
        );
        bookingFromApi.rates.forEach((rate) =>
            rate.cancelPolicies.sort((a, b) => compareAsc(parseISO(a.endAt), parseISO(b.endAt))),
        );
        let bed:Bed = JSON.parse(bookingFromApi.rates[0].bed)[0];
        let hotelDetail = bookingFromApi.hotel;
        let paymentAppUrlMap = bookingFromApi.bookingPayment?.paymentAppUrlMap;
        const end: any = Math.round(Number(bookingFromApi.bookingPayment?.invalidAt) / 1000);
        setPayControlAtom((prev) => ({ ...prev, payTime: end }));
        setEnd(end);
        setHotelDetail(hotelDetail);
        setBedIndo(bed);
        setBooking(bookingFromApi);
        setHotelRoom(hotelRoom || null);
        if (paymentAppUrlMap) {
            setPaymentURL(paymentAppUrlMap);
        }
        setFetchBookingStatus(FetchingState.SUCCESS);
        setLoading(false);
        clearInterval(intervalRef.current);
    }
    const payOrder = (event:any) => {
        setModalControl((prev) => ({ ...prev, isPayTipModalOpen: true }));
        setPayControlAtom((prev) => ({ ...prev, paymentURL: paymentURL }));
        if (orderId) {
            setPayControlAtom((prev) => ({ ...prev, payOrderNo: orderId }));
        }
        setPayControlAtom((prev) => ({ ...prev, payPrice: booking.price }));
        // setTimeout(()=> {
            setModalControl((prev) => ({ ...prev, isPayTipModalOpen: false }));
            setModalControl((prev) => ({ ...prev, isWechatPayModalOpen: true }));
        // }, 1500);
    }
    const onChangePay = (event:any) => {
        setSelectPay(event.type);
    }
    return (
        <PageContainer
            topBoxStyle={{ position: 'static' }}
            // afterHeaderComponent={}
        >
            <Box
                bgColor='#FFFFFF'
                pt='75px'
            >
                <Image
                    src={StepTwo}
                    h='47px'
                    ml='auto'
                    mr='auto'
                    mb='24px'
                />
            </Box>
            <VStack
                align="stretch"
                bgColor='#FAFAFA'
                px='124px'
                pb='63px'
            >
                <Text
                    my='24px'
                    fontSize='28px'
                    color='gray.100'
                    fontWeight='500'
                    letterSpacing='0 !important'
                >
                    {getI18NText('前往支付')}
                </Text>
                <Flex
                    justifyContent='space-between'
                >
                    <VStack
                        align="stretch"
                        spacing='24px'
                        w='830px'
                    >
                        {
                            booking?.status == 'PENDING' &&
                            <HStack
                                justifyContent='space-between'
                                alignItems='center'
                                h='84px'
                                bgColor='white'
                                borderRadius='4px'
                                px='24px'
                                mb='16px'
                                filter='drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.15))'
                            >
                                <HStack>
                                    <Image
                                        src={ClockIcon}
                                        w='36px'
                                        h='36px'
                                        mr='4px'
                                    />
                                    <Text
                                        fontSize='24px'
                                        fontWeight='500'
                                        color='blue'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText('订单提交成功')}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <Text
                                        fontSize='14px'
                                        color='dark'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText('为避免订单被取消，请在')}
                                    </Text>
                                    <Text
                                        fontSize='24px'
                                        fontWeight='500'
                                        color='danger.100'
                                        letterSpacing='0 !important'
                                    >
                                        {/* <Countdown timeStamp='2842923305' /> */}
                                        {
                                            Number(end) > 0 &&
                                            <Countdown timeStamp={end} />
                                        }
                                    </Text>
                                    <Text
                                        fontSize='14px'
                                        color='dark'
                                        letterSpacing='0 !important'
                                    >
                                        {getI18NText('内付款')}
                                    </Text>
                                </HStack>
                            </HStack>
                        }
                        <CheckInInformation
                            title={getI18NText('入住信息')}
                            subTitle=''
                            isEdit={false}
                            guestByRoom={booking?.customer.guestByRoom}
                            onChangePerson={(e:any)=>{console.log(e)}}
                            onChangeGuest={(e:any)=>{console.log(e)}}
                            isPay={false}
                        />
                        <ContactInformation
                            contactPerson={booking?.customer.contactPerson}
                            isEdit={false}
                            onChange={(e:any) => {console.log(e)}}
                            isPay={false}
                            isPhone={true}
                            isEmail={true}
                        />
                        <PayType
                            selectPay={selectPay}
                            price={booking?.price || 0}
                            onChange={(e:any)=>onChangePay(e)}
                        />
                        <HStack
                            justifyContent='flex-end'
                            alignItems='center'
                            mt='16px'
                        >
                            {/* <Button
                                w='140px'
                                h='40px'
                                textAlign='center'
                                lineHeight='40px'
                                borderRadius='4px'
                                border='2px solid #002C5E'
                                bgColor='white'
                                color='blue'
                                fontSize='16px'
                                _hover={{
                                    borderColor: '#EBEEF5',
                                    
                                }}
                                onClick={()=>setStep(1)}
                            >{getI18NText('返回修改')}</Button> */}

                            {
                                booking?.status == 'PENDING' &&
                                <Button
                                    w='140px'
                                    h='40px'
                                    textAlign='center'
                                    lineHeight='40px'
                                    borderRadius='4px'
                                    color='white'
                                    bgColor='blue'
                                    fontSize='16px'
                                    _hover={{
                                        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #002C5E'
                                    }}
                                    onClick={(e:any) => payOrder(e)}
                                >{getI18NText('确认支付')}</Button>
                            }
                        </HStack>
                    </VStack>
                    <Box
                        w='338px'
                    >
                        <Stack
                            spacing='12px'
                        >
                            <HotelInfo
                                image={hotelRoom?.images[0] ? hotelRoom?.images[0].url : DefaultImg}
                                checkIn={booking?.rates[0].checkIn}
                                checkOut={booking?.rates[0].checkOut}
                                bedInfo={bedInfo}
                                hotelName={booking?.hotel.name['zh-CN']}
                                address={booking?.hotel.address['zh-CN']}
                                roomName={hotelRoom?.name['zh_CN']}
                                hasBreakfast={booking?.rates[0].hasBreakfast}
                                num={booking?.rates[0].group.adultCount}
                            />
                            <Quote
                                checkIn={booking?.rates[0].checkIn}
                                checkOut={booking?.rates[0].checkOut}
                                dailyPrice={booking?.rates[0].dailyPrice}
                            />
                            <BookingDetail
                                showPay={false}
                                price={booking?.price}
                                toPay={()=>console.log()}
                                rooms={booking?.rates[0].roomNum}
                            />
                            <CancelPolicy
                                cancelPolicies={booking?.rates[0].cancelPolicies}
                            />
                        </Stack>
                    </Box>
                </Flex>
            </VStack>
            <LoadingModal isOpen={isFormSent || loading} />
        </PageContainer>
    )
}