import { useEffect } from 'react';
import { useMediaQuery } from '@chakra-ui/react';

import mediaQueryStateAtom from '~/recoil/atom/mediaQueryState';
import { useSetRecoilState } from 'recoil';

export default function MediaQuery() {
  const [isLargerThan768] = useMediaQuery(['(min-width: 768px)']);
  const setMediaQueryState = useSetRecoilState(mediaQueryStateAtom);

  useEffect(() => {
    setMediaQueryState({
      isLargerThan768,
    });
  }, [isLargerThan768]);

  return null;
}
