import {
    Box,
    HStack,
    Input,
    Flex,
    Text,
    FormControl,
    FormErrorMessage
} from '@chakra-ui/react';
import {
    ContactInfo,
} from '~/types';

import { useI18NText } from '~/i18n/i18n';

type ContactInformationProps = {
    contactPerson?: ContactInfo;
    isEdit?: boolean,
    onChange?: any,
    isPay?: boolean,
    isPhone?: boolean,
    isEmail?: boolean,
};

export default function ContactInformation({
    contactPerson,
    isEdit = false,
    onChange,
    isPay = false,
    isPhone = true,
    isEmail = true,
}:ContactInformationProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })

    const changeValue = (type:string, event:any) => {
        let value = event.target.value;
        if (type == 'firstName' || type == 'lastName') {
            // value = value.replace(/[^a-zA-Z]/g,'')
            value = value.replace(/[\d]/g,'');
        }
        if (type == 'phone') {
            value = value.replace(/[^\d]/g,'');
            if (value.length > 11) {
                return
            }
        }
        if (type == 'email') {
            value = value.replace(/[\u4E00-\u9FA5]/g,'');
        }
        onChange({
            value: value, 
            type: type
        })
    }
    return (
        <Box>
            <Text
                fontWeight='500'
                fontSize='22px'
                color='blue'
                h='44px'
                lineHeight='44px'
                w='100%'
                bgColor='#EBEEF5'
                pl='16px'
                pr='25px'
                mb='8px'
                borderRadius='4px'
                letterSpacing='0 !important'
            >
                {getI18NText('联系信息')}
            </Text>
            <Flex
                p='24px'
                borderRadius='4px'
                bgColor='white'
                boxSizing='border-box'
            >
                <Box
                    w='275px'
                    // flex='1'
                    pr={isEdit ? '15px' : 0}
                >
                    <HStack>
                        <Text
                            fontSize='14px'
                            lineHeight='19.6px'
                            mb='4px'
                            color={isEdit ? 'dark' : 'gray.800'}
                            letterSpacing='0 !important'
                        >
                            {getI18NText('名')}
                        </Text>
                        {
                            isEdit &&
                            <Text
                                fontSize='14px'
                                lineHeight='19.6px'
                                mb='4px !important'
                                color='danger.100'
                                letterSpacing='0 !important'
                                marginInlineStart='0 !important'
                            >
                                *
                            </Text>
                        }
                    </HStack>
                    {
                        isEdit ? (
                            <FormControl
                                w='260px'
                                isInvalid={isPay && contactPerson?.name.firstName === ''}
                            >
                                <Input
                                    w='260px'
                                    h='40px'
                                    color='gray.800'
                                    placeholder={`${getI18NText('拼音 例')}: Fang`}
                                    fontSize='14px'
                                    value={contactPerson?.name.firstName}
                                    onChange={(e:any)=> changeValue('firstName', e)}
                                />
                                {
                                    (isPay && contactPerson?.name.firstName === '') &&
                                    <FormErrorMessage>{getI18NText("请输入正确信息")}</FormErrorMessage>
                                }
                            </FormControl>
                        ) : (
                            <Text
                                color='blue'
                                fontSize='14px'
                                lineHeight='19.6px'
                                letterSpacing='0 !important'
                            >
                                {contactPerson?.name.firstName}
                            </Text>
                        )
                    }
                </Box>
                <Box
                    w='275px'
                    // flex='1'
                    pr={isEdit ? '15px' : 0}
                >
                    <HStack>
                        <Text
                            fontSize='14px'
                            lineHeight='19.6px'
                            mb='4px'
                            color={isEdit ? 'dark' : 'gray.800'}
                            letterSpacing='0 !important'
                        >
                            {getI18NText('姓')}
                        </Text>
                        {
                            isEdit &&
                            <Text
                                fontSize='14px'
                                lineHeight='19.6px'
                                mb='4px !important'
                                color='danger.100'
                                letterSpacing='0 !important'
                                marginInlineStart='0 !important'
                            >
                                *
                            </Text>
                        }
                    </HStack>
                    {
                        isEdit ? (
                            <FormControl
                                w='260px'
                                isInvalid={isPay && contactPerson?.name.lastName === ''}
                            >
                                <Input
                                    w='260px'
                                    h='40px'
                                    color='gray.800'
                                    placeholder={`${getI18NText('拼音 例')}: Fang`}
                                    fontSize='14px'
                                    value={contactPerson?.name.lastName}
                                    onChange={(e:any)=> changeValue('lastName', e)}
                                />
                                {
                                    (isPay && contactPerson?.name.lastName === '') &&
                                    <FormErrorMessage>{getI18NText("请输入正确信息")}</FormErrorMessage>
                                }
                            </FormControl>
                        ) : (
                            <Text
                                color='blue'
                                fontSize='14px'
                                lineHeight='19.6px'
                                letterSpacing='0 !important'
                            >
                                {contactPerson?.name.lastName}
                            </Text>
                        )
                    }
                </Box>
            </Flex>
            <Flex
                p='24px'
                borderRadius='4px'
                bgColor='white'
                boxSizing='border-box'
            >
                <Box
                    w='275px'
                    // flex='1'
                    pr={isEdit ? '15px' : 0}
                >
                    <HStack>
                        <Text
                            fontSize='14px'
                            lineHeight='19.6px'
                            mb='4px'
                            color={isEdit ? 'dark' : 'gray.800'}
                            letterSpacing='0 !important'
                        >
                            {getI18NText('手机号')}
                        </Text>
                        {
                            isEdit &&
                            <Text
                                fontSize='14px'
                                lineHeight='19.6px'
                                mb='4px !important'
                                color='danger.100'
                                letterSpacing='0 !important'
                                marginInlineStart='0 !important'
                            >
                                *
                            </Text>
                        }
                    </HStack>
                    {
                        isEdit ? (
                            <FormControl
                                w='260px'
                                isInvalid={isPay && (!isPhone || contactPerson?.phone === '')}
                            >
                                <Input
                                    w='260px'
                                    h='40px'
                                    color='gray.800'
                                    placeholder={getI18NText('输入可用手机号')}
                                    fontSize='14px'
                                    value={contactPerson?.phone}
                                    onChange={(e:any)=> changeValue('phone', e)}
                                />
                                {
                                    (isPay && (!isPhone || contactPerson?.phone === '')) &&
                                    <FormErrorMessage>{getI18NText("请输入正确信息")}</FormErrorMessage>
                                }
                            </FormControl>
                        ) : (
                            <Text
                                color='blue'
                                fontSize='14px'
                                w='260px'
                                lineHeight='19.6px'
                                letterSpacing='0 !important'
                            >
                                {contactPerson?.phone || '--'}
                            </Text>
                        )
                    }
                </Box>
                <Box
                    w='275px'
                    // flex='1'
                >
                    <Text
                        fontSize='14px'
                        lineHeight='19.6px'
                        mb='4px'
                        color={isEdit ? 'dark' : 'gray.800'}
                        letterSpacing='0 !important'
                    >
                        {getI18NText('邮箱')}
                    </Text>
                    {
                        isEdit ? (
                            <FormControl
                                w='260px'
                                isInvalid={isPay && !isEmail}
                            >
                               <Input
                                    w='260px'
                                    h='40px'
                                    color='gray.800'
                                    placeholder={getI18NText('输入正确的信箱')}
                                    fontSize='14px'
                                    value={contactPerson?.email}
                                    onChange={(e:any)=> changeValue('email', e)}
                                />
                                {
                                    (isPay && !isEmail) &&
                                    <FormErrorMessage>{getI18NText("请输入正确信息")}</FormErrorMessage>
                                }
                            </FormControl>
                        ) : (
                            <Text
                                color='blue'
                                fontSize='14px'
                                lineHeight='19.6px'
                                letterSpacing='0 !important'
                            >
                               {contactPerson?.email || '--'}
                            </Text>
                        )
                    }
                </Box>
            </Flex>
        </Box>
    )
}