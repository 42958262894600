import React, { useState } from 'react';
import {
    Box,
    Flex,
    HStack,
    Image,
    Text,
} from '@chakra-ui/react';
import reduceIcon from '~/assets/images/reduce.png';
import enreduceIcon from '~/assets/images/enreduce.png';
import addIcon from '~/assets/images/add.png';
import enaddIcon from '~/assets/images/enadd.png';

import { useI18NText } from '~/i18n/i18n';

type PersonInfoProps = {
    bottom?: string,
    onChange?: any,
    roomNum?: number,
    aldultNum?: number,
    childNum?: number,
    childAges?: Array<Number>,
}
export default function PersonInfo({
    bottom = '0',
    roomNum = 1,
    aldultNum = 1,
    childNum = 0,
    childAges = [0],
    onChange
}: PersonInfoProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })
    const changeNum = (value:number, key:string, type: string, index: number, e:any) => {
        console.log('value', value)
        if (type == 'reduce' && ((key === 'childNum' && value == 0) || (key === 'childAge' && value === 0) || (key !== 'childNum' && value == 1))) {
            return;
        } else {
            let num;
            if (type == 'reduce') {
                num = value - 1;
            } else {
                if (key === 'roomNum' && value === 5) {
                    return;
                }
                if (key === 'aldultNum' && value === 10) {
                    return;
                }
                if (key === 'childNum' && value === 3) {
                    return;
                }
                if (key === 'childAge' && value === 17) {
                    return;
                }
                num = value + 1;
            }
            onChange({
                type: key,
                action: type,
                value: num,
                key: index
            });
        }
    }
    return (
        <Box
            position='absolute'
            left='0'
            w='250px'
            bgColor='white'
            boxShadow='2px 2px 20px rgba(0, 0, 0, 0.2)'
            borderRadius='4px'
            top='40px'
            p='16px'
        >
            <Flex
                h='26px'
                alignItems='center'
                justifyContent='center'
                mb='9px'
            >
                <Text
                    color='gray.100'
                    fontSize='16px'
                    flex='1'
                >
                    {getI18NText('房间数')}
                </Text>
                <HStack
                    w='112px'
                    justifyContent='flex-end'
                >
                    <Image
                        src={roomNum == 1 ? enreduceIcon : reduceIcon}
                        w='24px'
                        h='24px'
                        cursor='pointer'
                        onClick={(e)=>changeNum(roomNum, 'roomNum', 'reduce', 0, e)}
                    />
                    <Text
                        w='42px'
                        color='#002C5E'
                        fontSize='16px'
                        textAlign='center'
                    >
                        {roomNum}
                    </Text>
                    <Image
                        src={addIcon}
                        w='24px'
                        h='24px'
                        cursor='pointer'
                        onClick={(e)=>changeNum(roomNum, 'roomNum', 'add', 0, e)}
                    />
                </HStack>
            </Flex>
            <Text
                color='gray.800'
                fontSize='14px'
                lineHeight='19.6px'
                mb='9px'
            >
                {getI18NText('每间入住')}
            </Text>
            <Flex
                h='26px'
                alignItems='center'
                justifyContent='center'
                mb='9px'
            >
                <Text
                    color='gray.100'
                    fontSize='16px'
                    flex='1'
                >
                    {getI18NText('成人')}
                </Text> 
                <HStack
                    w='112px'
                    justifyContent='flex-end'
                >
                    <Image
                        src={aldultNum == 1 ? enreduceIcon : reduceIcon}
                        w='24px'
                        h='24px'
                        cursor='pointer'
                        onClick={(e)=>changeNum(aldultNum, 'aldultNum', 'reduce', 0, e)}
                    />
                    <Text
                        w='42px'
                        color='#002C5E'
                        fontSize='16px'
                        textAlign='center'
                    >
                        {aldultNum}
                    </Text>
                    <Image
                        src={addIcon}
                        w='24px'
                        h='24px'
                        cursor='pointer'
                        onClick={(e)=>changeNum(aldultNum, 'aldultNum', 'add', 0, e)}
                    />
                </HStack>
            </Flex>
            <Flex
                h='26px'
                alignItems='center'
                justifyContent='center'
                mb='9px'
            >
                <Text
                    color='gray.100'
                    fontSize='16px'
                    flex='1'
                >
                    {getI18NText('儿童')}
                </Text>
                <HStack
                    w='112px'
                    justifyContent='flex-end'
                >
                    <Image
                        src={childNum == 0 ? enreduceIcon : reduceIcon}
                        w='24px'
                        h='24px'
                        cursor='pointer'
                        onClick={(e)=>changeNum(childNum, 'childNum', 'reduce', 0, e)}
                    />
                    <Text
                        w='42px'
                        color='#002C5E'
                        fontSize='16px'
                        textAlign='center'
                    >
                        {childNum}
                    </Text>
                    <Image
                        src={addIcon}
                        w='24px'
                        h='24px'
                        cursor='pointer'
                        onClick={(e)=>changeNum(childNum, 'childNum', 'add', 0, e)}
                    />
                </HStack>
            </Flex>
            {
                childAges.length > 0 &&
                <Flex
                    justifyContent='center'
                    mb='9px'
                >
                    <Text
                        color='gray.100'
                        fontSize='16px'
                        flex='1'
                        pl='16px'
                    >
                        {getI18NText('儿童年龄')}
                    </Text>
                    <Box
                        flex='1'
                    >
                        {
                            childAges.map((item, key) =>
                                <Box
                                    key={key}
                                    mb='9px'
                                >
                                    <HStack
                                        w='112px'
                                        justifyContent='flex-end'
                                    >
                                        <Image
                                            src={item == 1 ? enreduceIcon : reduceIcon}
                                            w='24px'
                                            h='24px'
                                            cursor='pointer'
                                            onClick={(e)=>changeNum(Number(item), 'childAge', 'reduce', key, e)}
                                        />
                                        <Text
                                            w='42px'
                                            color='#002C5E'
                                            fontSize='16px'
                                            textAlign='center'
                                        >
                                            {item}
                                        </Text>
                                        <Image
                                            src={addIcon}
                                            w='24px'
                                            h='24px'
                                            cursor='pointer'
                                            onClick={(e)=>changeNum(Number(item), 'childAge', 'add', key, e)}
                                        />
                                    </HStack>
                                </Box>
                            )
                        }
                    </Box>
                </Flex>
            }
        </Box>
    )
}