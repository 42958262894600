import React from 'react';

import { Box } from '@chakra-ui/react';

import { isSameDay } from 'date-fns';

import NormalDay from './NormalDay';
import PeriodDay from './PeriodDay';
import TargetDay from './TargetDay';
import OnlyStartDay from './OnlyStartDay';

type DayProps = {
  startTime: number | null;
  endTime: number | null;
  day: number;
  time: number;
  formatted: string;
  onUpdateDate: (time: number, type: 'start' | 'end' | 'restart') => void;
};

export default function Day({ startTime, endTime, day, time, formatted, onUpdateDate }: DayProps) {
  const handleDay = () => {
    if (endTime) {
      return onUpdateDate(time, 'restart');
    }

    if (startTime && !endTime) {
      return onUpdateDate(time, 'end');
    }

    return onUpdateDate(time, 'start');
  };

  if (endTime && startTime && time > startTime && time < endTime) {
    return (
      <Box key={formatted} onClick={handleDay}>
        <PeriodDay key={formatted} day={day} />
      </Box>
    );
  }

  if (time !== startTime && time !== endTime) {
    return (
      <Box onClick={handleDay}>
        <NormalDay day={day} />
      </Box>
    );
  }

  // end day can't be start day
  if (startTime && !endTime && isSameDay(new Date(startTime), new Date(time))) {
    return (
      <Box key={formatted}>
        <OnlyStartDay key={formatted} day={day} />
      </Box>
    );
  }

  if (!endTime) {
    return (
      <Box key={formatted} onClick={handleDay}>
        <OnlyStartDay key={formatted} day={day} />
      </Box>
    );
  }

  return (
    <Box onClick={handleDay}>
      <TargetDay day={day} targetType={time === startTime ? 'start' : 'end'} />
    </Box>
  );
}
