import { useEffect, useState } from 'react';
import fp from 'lodash/fp';
import Countdown from '~/components/Countdown';
import {
    Center,
    Divider,
    Flex,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Text,
    HStack
} from '@chakra-ui/react';
import WechatPayIcon from '~/assets/images/WechatPay.png';
import QRCode from 'qrcode';
import payControlAtom from '~/recoil/atom/pay';
import { useRecoilState } from 'recoil';

import { useI18NText } from '~/i18n/i18n';

type WechatPayModalProps = {
    paymentURL: any;
    isOpen: boolean;
    payPrice: any;
    payOrderNo: string;
    onClose: () => void;
};
// TODO: error message 介面優化
export default function WechatPay({ isOpen, paymentURL,payOrderNo,payPrice, onClose }: WechatPayModalProps) {
    // i18n国际化纯文本
    const [
        {
          payTime
        },
        setPayControl,
    ] = useRecoilState(payControlAtom);
    const { getI18NText } = useI18NText({ prefix: 'component.' })
    const [qrcodeDataUrl, setQrcodeDataUrl] = useState('');
    const getQrcode = async() => {
        let qrcodeDataUrl = await QRCode.toDataURL(paymentURL.Wechat);
        setQrcodeDataUrl(qrcodeDataUrl);
    }
    if (paymentURL?.Wechat) {
        getQrcode();
    }
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="sm"
            isCentered
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent h="450px">
                <ModalBody
                    pt='16px'
                >
                    <Center
                        mb='16px'
                    >
                        <Image
                            src={WechatPayIcon}
                            w='179px'
                        />
                    </Center>
                    <Center
                        pb='20px'
                    >
                        <Image
                            src={qrcodeDataUrl}
                            w='179px'
                        />
                    </Center>
                    <Divider orientation='horizontal' color='#C4C4C4'/>
                    <Flex
                        justifyContent='space-between'
                        alignItems='center'
                        mt='12px'
                        h='17px'
                    >
                        <Text
                            fontSize='14px'
                            color='gray.800'
                        >
                            {getI18NText('订单号')}
                        </Text>
                        <Text
                            fontSize='14px'
                            color='fontColor'
                        >
                            {payOrderNo}
                        </Text> 
                    </Flex>
                    <Flex
                        justifyContent='space-between'
                        alignItems='center'
                        mt='12px'
                        h='17px'
                    >
                        <Text
                            fontSize='14px'
                            color='gray.800'
                        >
                            {getI18NText('支付金额')}
                        </Text>
                        <Text
                            fontSize='14px'
                            color='fontColor'
                        >
                            ¥ {payPrice}
                        </Text>
                    </Flex>
                    {/* <Divider orientation='horizontal' color='#C4C4C4' mt='12px'/>
                    <HStack
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Text
                            fontSize='14px'
                            color='dark'
                            letterSpacing='0 !important'
                        >
                            {getI18NText('为避免订单被取消，请在')}
                        </Text>
                        <Text
                            fontWeight='500'
                            color='danger.100'
                            fontSize='24px'
                            marginInlineStart='4px !important'
                            letterSpacing='0 !important'
                        >
                            <Countdown timeStamp={payTime}/>
                        </Text>
                        <Text
                            fontSize='14px'
                            color='dark'
                            marginInlineStart='4px !important'
                            letterSpacing='0 !important'
                        >
                            {getI18NText('内付款')}
                        </Text>
                    </HStack> */}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
