import axios, { AxiosError } from 'axios';
import { Booking, BookingFromApi } from '~/types';

import parseISO from 'date-fns/parseISO';

type GetBookingRequest = {
  token: string;
  bookingId: string;
};

export default async ({ token, bookingId }: GetBookingRequest): Promise<Booking | null> => {
  try {
    const bookingResponse = await axios.get<BookingFromApi>(
      `${process.env.REACT_APP_AWS_HTTP_BASE_URL}/booking/${encodeURIComponent(bookingId)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const booking = bookingResponse.data;
    const { createdAt, updatedAt, rates } = booking;
    return {
      ...booking,
      createdAt: parseISO(createdAt),
      updatedAt: parseISO(updatedAt),
      rates: rates.map(({ price, dailyPrice, cancelPolicies, ...otherFields }) => ({
        ...otherFields,
        price: Math.round(price),
        dailyPrice: dailyPrice.map((pricePerDay) => Math.round(pricePerDay)),
        cancelPolicies,
      })),
    };
  } catch (error) {
    const bookingFailedError = error as AxiosError<{ errorCode: string; message: string }>;

    bookingFailedError.response
      ? console.error('Request failed for get booking api: ', bookingFailedError.response.data)
      : console.error('Request failed for get booking api: ', error);

    return null;
  }
};
