import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import './App.css';
import theme from '~/theme';
import Routes from '~/routes';
import MediaQuery from './globalScript/MediaQuery';

function App() {
  String.prototype.replaceAll = function(targetStr?:any, newStr?:any) {
    var sourceStr = this.valueOf()
    while (sourceStr.indexOf(targetStr) !== -1) {
      sourceStr = sourceStr.replace(targetStr, newStr)
    }
    return sourceStr
  }
  return (
    <RecoilRoot>
      <ChakraProvider theme={theme}>
        <MediaQuery />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ChakraProvider>
    </RecoilRoot>
  );
}

export default App;
