import axios from 'axios';

type VerifyUserRequest = {
  token: string;
};

export default async ({ token }: VerifyUserRequest): Promise<boolean> => {
  try {
    await axios.get<{}>(`${process.env.REACT_APP_AWS_HTTP_BASE_URL}/agency/user/verify`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return true;
  } catch (error) {
    return false;
  }
};
