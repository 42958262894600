import { ComponentStyleConfig } from '@chakra-ui/react';

const Heading: ComponentStyleConfig = {
  baseStyle: {
    color: 'fontColor',
    letterSpacing: '0.1em',
  },
};

export default Heading;
