import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Image, Container, Flex, HStack, Box } from '@chakra-ui/react';

import logo from '~/assets/images/logo.png';
import IconHotel from '~/components/icons/IconHotel';
import NavMenuButton from '~/components/Nav/NavMenuButton';
import NavMenuOrder from '~/components/Nav/NavMenuOrder';
import NavMenuLogout from '~/components/Nav/NavMenuLogout';
import IconUser from '~/components/icons/IconUser';
import IconTicket from '~/components/icons/IconTicket';

const LayoutHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedOut = location.pathname === '/login';

  return (
    <Box w="full" bgColor="white">
      <Box px="4" w="full">
        <Container maxW="1200px" px="0">
          <Flex align="center" h="20">
            <Link to="/">
              <Image w="48" minW="48" src={logo} alt="" />
            </Link>

            {isLoggedOut ? null : (
              <Flex align="center" justify="space-between" w="full">
                <HStack ml="10" spacing="10">
                  <NavMenuButton
                    onClick={() => navigate('/')}
                    isActive
                    text="酒店"
                    icon={<IconHotel size={5} />}
                  />
                  <NavMenuButton
                    onClick={() => {
                      window.location.href = 'https://local-exp.tripintl.com/';
                    }}
                    text="当地玩乐"
                    icon={<IconTicket size={5} />}
                  />
                </HStack>
                <HStack spacing="4">
                  <NavMenuOrder />
                  <NavMenuButton
                    onClick={() => navigate('/usercenter')}
                    icon={<IconUser size={5} />}
                  />
                  <NavMenuLogout />
                </HStack>
              </Flex>
            )}
          </Flex>
        </Container>
      </Box>

      {/* {locationNavMenuVisible(location.pathname) ? (
        <Box px="4" py="3.5" w="full">
          <Container maxW="1200px" px="0">
            <HStack spacing="8">
              <Center w="8" h="8" color="white" rounded="lg" bgColor="#9CBFFF">
                <IconLocation size={5} />
              </Center>
              {NavLocations.map((i, idx) => {
                return <NavLocationButton key={idx} title={i.name} data={i.data} />;
              })}
            </HStack>
          </Container>
        </Box>
      ) : null} */}
    </Box>
  );
};

export default LayoutHeader;
