import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Radio,
    RadioGroup,
    Stack,
    VStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
} from '@chakra-ui/react';
import getAllBookings from '~/awsHttpApi/getAllBookings';
import {
    IoChevronForwardOutline,
    IoSearchOutline,
} from 'react-icons/io5';
import {
    getToken
} from '~/utils/tools';
import formatDate from 'date-fns/format';
import addDays from 'date-fns/addDays';
import { useNavigate } from 'react-router-dom';
import LoadingModal from '~/components/LoadingModal';
import Select from '~/components/Select';
import PageContainer from '~/containers/PageContainer';
import DateRangerPicker from '~/components/DateRangerPicker';
import CheckInTime from '~/components/CheckInTime';
import Empty from '~/components/Empty';
import OrderStatusLabel from './OrderStatusLabel';
import {
    BookingListItem,
    OrderStatus,
    PaymentTypeStatus,
    TMIBookingStatus
} from '~/types';
import checkInIcon from '~/assets/images/checkInIcon.png';
import checkoutIcon from '~/assets/images/checkoutIcon.png';
import checkboxIcon from '~/assets/images/checkbox.png';
import uncheckboxIcon from '~/assets/images/uncheckbox.png';
import upIcon from '~/assets/images/up.png';
import downIcon from '~/assets/images/down.png';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import '~/assets/paginations.css';
import { useI18NText } from '~/i18n/i18n';

type selectType = {
    id: any,
    value: string
}
  
let payTypeList:selectType []= [
    {
        id: '',
        value: '全部支付状态'
    },
    {
        id: PaymentTypeStatus.Wechat,
        value: '微信'
    },
    {
        id: PaymentTypeStatus.AliPay,
        value: '支付宝'
    },
    {
        id: PaymentTypeStatus.CreditCard,
        value: '境外信用卡'
    },
    {
        id: PaymentTypeStatus.Balance,
        value: '账户余额'
    }
]

let orderStatusList:selectType []= [
    {
        id: '',
        value: '全部订单状态'
    },
    {
        id: TMIBookingStatus.PENDING,
        value: '待付款'
    },
    {
        id: TMIBookingStatus.IN_BOOKING,
        value: '待确认'
    },
    {
        id: TMIBookingStatus.INVALID,
        value: '已失效'
    },
    {
        id: TMIBookingStatus.SUCCESS,
        value: '已确认'
    },
    {
        id: TMIBookingStatus.FAILED,
        value: '预定失败'
    },
    {
        id: TMIBookingStatus.CANCELLING,
        value: '取消中'
    },
    {
        id: TMIBookingStatus.CANCELLED,
        value: '已取消'
    }
]

let style = {
    thStyle: {
        fontSize: '14px',
        color: '#002C5E',
        letterSpacing: '0 !important'
    },
    tdStyle: {
        py: '31px',
        borderColor: 'gray.700',
        letterSpacing: '0 !important'
    },
    textStyle: {
        fontSize: '14px',
        color: 'fontColor',
        letterSpacing: '0 !important'
    }
}

export default function OrderList() {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'orderlist.' })

    const navigate = useNavigate();
    let startDate = formatDate(addDays(new Date(), 0), 'yyyy/MM/dd');
    let endDate = formatDate(addDays(new Date(), 1), 'yyyy/MM/dd');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    let [active, setActive] = useState('');
    const [checkAll, setCheckAll] = useState(false);
    let [pageNo, setPageNo] = useState(1);
    let [pageSize, setPageSize] = useState(10);
    let [count, setCount] = useState(0);
    const [pay, setPay] = useState('');
    const [payType, setPayType] = useState('');
    const [orderStatus, setOrderStatus] = useState<OrderStatus>(OrderStatus.NONE);
    const [statusType, setStatusType] = useState('');
    const [totalPage, setTotalPage] = useState(0);
    const [checkSize, setCheckSize] = useState(0);
    const [createdtime, setCreatedtime] = useState('createdtime_desc');
    const [checkindate, setCheckindate] = useState('checkindate_desc');
    const [agencytotalprice, setAgencytotalprice] = useState('agencytotalprice_desc');
    const [loading, setLoading] = useState<boolean>(true);
    const [isFormSent, setFormSent] = useState<boolean>(false);
    const [showDate, setShowDate] = useState(false);
    let [sortType, setSortType] = useState<string>('createdtime_desc');
    let [bookings, setBookings] = useState<BookingListItem[]>([]);
    let [keyword, setKeyword] = useState('');
    let [BookingType, setBookingType] = useState('-1');
    useEffect(()=>{
        if (loading) {
            getList();
        }
        console.log('BookingType', BookingType) 
    },[loading, BookingType]);
    document.addEventListener('click', (e) => {
        setShowDate(false);
    });
    const getList = async() => {
        let status: string = BookingType;
        if (BookingType == '-1') {
            status = '';
        }
        let res = await getAllBookings({
            token: getToken(),
            status: statusType,        // 未选择则置空
            payStatus: payType,        // 未选择则置空
            sort: sortType,
            keyword,
            dateType: status,   // 未选择则置空
            dateRagne: {
                min: start.replaceAll('\/', '\-'),
                max: end.replaceAll('\/', '\-')
            },
            page: pageNo,
            size: pageSize,
            gateway: ''
        });
        console.log('res', res);
        setTotalPage(res.totalPage);
        setLoading(false);
        setBookings(res.bookings);
        setCount(res.totalCount);
    }
    const chooseAll = (e:any) => {
        if (checkAll) {
            bookings = bookings.map(item=>{
                item.checked = false;
                return item;
            });
        } else {
            bookings = bookings.map(item=>{
                item.checked = true;
                return item;
            });
        }
        setBookings(bookings);
        setCheckAll(!checkAll);
    }
    const singleChoose = (e: any, id: any) => {
        let all = false;
        let num = 0;
        bookings = bookings.map(item=>{
            if (item.id === id) {
                item.checked = !item.checked
            }
            if (item.checked) {
                num++;
            }
            return item;
        });
        if (num === bookings.length) {
            all = true;
        }
        setBookings(bookings);
        setCheckAll(all);
        setCheckSize(num);
    }

    const changePayType = (event:any) => {
        setPay(event.value);
        setPayType(event.id);
    }

    const changeOrderStatus = (event:any) => {
        setOrderStatus(event.value);
        setStatusType(event.id);
    }
    
    const changeSortType = (type:string, e:any) => {
        switch (type) {
            case 'createdtime':
                if (createdtime === 'createdtime_desc') {
                    setCreatedtime('createdtime_asc');
                    setSortType('createdtime_asc');
                    setPageNo(1);
                    setLoading(true);
                } else {
                    setCreatedtime('createdtime_desc');
                    setSortType('createdtime_desc');
                    setPageNo(1);
                    setLoading(true);
                }
                break;
            case 'checkindate':
                if (checkindate === 'checkindate_desc') {
                    setCheckindate('checkindate_asc');
                    setSortType('checkindate_asc');
                    setPageNo(1);
                    setLoading(true);
                } else {
                    setCheckindate('checkindate_desc');
                    setSortType('checkindate_desc');
                    setPageNo(1);
                    setLoading(true);
                }
                break;
            case 'agencytotalprice':
                if (agencytotalprice === 'agencytotalprice_desc') {
                    setAgencytotalprice('agencytotalprice_asc');
                    setSortType('agencytotalprice_asc');
                    setPageNo(1);
                    setLoading(true);
                } else {
                    setAgencytotalprice('agencytotalprice_desc');
                    setSortType('agencytotalprice_desc');
                    setPageNo(1);
                    setLoading(true);
                }
                break;
            default:
                if (createdtime === 'createdtime_desc') {
                    setCreatedtime('createdtime_asc');
                    setSortType('createdtime_asc');
                    setPageNo(1);
                    setLoading(true);
                } else {
                    setCreatedtime('createdtime_desc');
                    setSortType('createdtime_desc');
                    setPageNo(1);
                    setLoading(true);
                }
                break;
        }
    }
    const onChangePage = (event:any) => {
        setPageNo(event);
        setLoading(true);
    }
    const showTimeChoose = (event:any) => {
        event.nativeEvent.stopImmediatePropagation();
        setShowDate(true);
    }
    const onChangeValue = (event:any) => {
        console.log('event', event)
        if (event.start) {
            setStart(event.start);
        } else {
            setStart('');
        }
        if (event.end) {
            setEnd(event.end);
        } else {
            setEnd('');
        }
    }
    const searchList = (event:any) => {
        setPageNo(1);
        setLoading(true);
    }
    const dateFormate = (value: Date) => {
        const sampleDate = new Date(value);
        return formatDate(sampleDate, 'HH:mm');
    }
    const renderPayStatus = (key: string) => {
        let PayStatus:any = {};
        PayStatus = {
            Wechat: '微信',
            AliPay: '支付宝',
            Credit: '信用卡',
            Balance: '余额支付'
        }
        return PayStatus[key];
    }
    const changeBookingType = (event:any) => {
        console.log('event', event)
    }
    return (
        <PageContainer
            topBoxStyle={{ position: 'static' }}
            afterHeaderComponent={<></>}
        >
            <VStack
                align="stretch"
                spacing='0px'
                bgColor='#FAFAFA'
                pl='122px'
                pr='101px'
                pb='63px'
            >
                <Flex
                    mb='19.5px'
                    alignItems='center'
                    pt='20.5px'
                >
                    <Text
                        fontSize='12px'
                        color='gray.800'
                        marginInlineStart='0 !important'
                    >
                        {getI18NText("我的")}
                    </Text>
                    <Icon
                        as={IoChevronForwardOutline}
                        boxSize='1rem'
                        color='gray.800'
                        mx='8.59px'
                    />
                    <Text
                        fontSize='12px'
                        color='gray.800'
                        marginInlineStart='0 !important'
                    >
                        {getI18NText("订单")}
                    </Text>
                    <Icon
                        as={IoChevronForwardOutline}
                        boxSize='1rem'
                        color='gray.800'
                        mx='8.59px'
                    />
                     <Text
                        fontSize='12px'
                        color='gray.800'
                        marginInlineStart='0 !important'
                    >
                        {getI18NText("酒店订单")}
                    </Text>
                </Flex>
                <Text
                    fontSize='28px'
                    fontWeight='500'
                    color='gray.100'
                    mb='8px !important'
                    letterSpacing='0 !important'
                >
                    {getI18NText("订单列表")}
                </Text>
                <HStack
                    alignItems='center'
                    mb='8px !important'
                >
                    <InputGroup
                        w='400px'
                        h='40px'
                        mr='16px'
                        bgColor='white'
                    >
                        <InputLeftElement
                            pointerEvents='none'
                            children={<Icon as={IoSearchOutline} boxSize='1.25rem' color='gray.100' />}
                        />
                        <Input
                            type='text'
                            placeholder={getI18NText('输入订单号、入住人')}
                            value={keyword}    
                            onChange={(e)=>setKeyword(e.target.value)}
                        />
                    </InputGroup>
                    <Select
                        placeholder={getI18NText('订单状态')}
                        w='223px'
                        h='40px'
                        mr='16px'
                        value={orderStatus}
                        list={orderStatusList.map(item => ({ ...item, value: getI18NText(item.value) }))}
                        onChange={(e:any)=>changeOrderStatus(e)}
                    />
                    <Select
                        placeholder={getI18NText('支付方式')}
                        w='223px'
                        h='40px'
                        mr='16px'
                        value={pay}
                        list={payTypeList.map(item => ({ ...item, value: getI18NText(item.value) }))}
                        onChange={(e:any)=>changePayType(e)}
                    />
                </HStack>
                <HStack
                    alignItems='center'
                    mb='16px'
                    h='48px'
                    position='relative'
                >
                    <RadioGroup onChange={setBookingType} value={BookingType}>
                        <Stack direction='row'>
                            <Radio value='-1'>{getI18NText("所有日期")}</Radio>
                            <Radio value='Booking'>{getI18NText("预订提交日期")}</Radio>
                            <Radio value='CheckIn'>{getI18NText("预订入住日期")}</Radio>
                            <Radio value='Cancelling'>{getI18NText("预订退房时间")}</Radio>
                        </Stack>
                    </RadioGroup>
                    <Box
                        position='relative'
                        ml='20px'
                        px='20px'
                        bgColor='white'
                        w='440px'
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        h='40px'
                        lineHeight='40px'
                        color={start ? 'blue' : 'gray.800'}
                        fontSize='16px'
                        fontWeight={start ? '500' : '400'}
                        borderWidth='1px'
                        borderColor={start ? 'blue' : 'gray.800'}
                        borderRadius='4px'
                        onClick={(e)=>{
                            showTimeChoose(e)
                        }}
                    >
                        <CheckInTime
                            showIcon={false}
                            start={start}
                            end={end}
                        />
                        {
                        showDate &&
                            <Box
                                // w='722px'
                                w='660px'
                                bgColor='white'
                                boxShadow='2px 4px 8px rgba(0, 0, 0, 0.2)'
                                borderRadius='4px'
                                position='absolute'
                                bottom='-380px'
                                zIndex='999'
                                px='29px'
                            >
                                <DateRangerPicker
                                    showBefore={true}
                                    onChangeValue={(e:any)=>onChangeValue(e)}
                                />
                            </Box>
                        }
                    </Box>
                    <Button
                        bgColor='#002C5E'
                        color='white'
                        fontSize='16px'
                        w='112px'
                        h='48px'
                        textAlign='center'
                        lineHeight='48px'
                        borderRadius='6px'
                        position='absolute'
                        right='0'
                        top='0'
                        letterSpacing='0 !important'
                        _hover={{
                            background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #002C5E'
                        }}
                        onClick={(e=>searchList(e))}
                    >
                        {getI18NText("筛选")}
                    </Button>
                </HStack>
                <Box
                    pt='16px'
                >
                    <Divider
                        orientation='horizontal'
                        bgColor='#D7D7D7'
                        mb='26px'
                    />
                    <HStack
                        justifyContent='space-between'
                        alignItems='center'
                        mb='9px'
                    >
                        <HStack>
                            <Text
                                fontSize='22px'
                                fontWeight='400'
                                color='dark'
                                mr='8px'
                                letterSpacing='0 !important'
                            >
                                {getI18NText("共有_笔订单", { interpolation: { num: count } })}
                            </Text>
                            {
                                checkSize > 0 &&
                                <Text
                                    fontSize='22px'
                                    fontWeight='400'
                                    color='gray.100'
                                    marginInlineStart='0 !important'
                                    letterSpacing='0 !important'
                                >
                                    {getI18NText("已选择_笔", { interpolation: { num: checkSize } })}
                                </Text>
                            }
                        </HStack>
                        {/* <HStack>
                            <Button
                                bgColor='#F0D43A'
                                color='#002C5E'
                                fontSize='16px'
                                w='112px'
                                h='48px'
                                textAlign='center'
                                lineHeight='48px'
                                borderRadius='6px'
                                letterSpacing='0 !important'
                                _hover={{
                                    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #F0D43A'
                                }}
                            >
                                {getI18NText("导出选取")}
                            </Button>
                            <Button
                                bgColor='#F0D43A'
                                color='#002C5E'
                                fontSize='16px'
                                w='148px'
                                h='48px'
                                textAlign='center'
                                lineHeight='48px'
                                borderRadius='6px'
                                letterSpacing='0 !important'
                                _hover={{
                                    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #F0D43A'
                                }}
                            >
                                {getI18NText("导出全部_笔", { interpolation: { num: bookings.length } })}
                            </Button>
                        </HStack> */}
                    </HStack>
                    <Table
                        mb='20px'
                    >
                        <Thead
                            h='76px'
                            bgColor='#EBEEF5'
                        >
                            <Tr>
                                <Th>
                                    {/* <Checkbox
                                        w='24px'
                                        h='24px'
                                        isChecked={checkAll}
                                        onChange={(e) => chooseAll(e.target.checked)}
                                    /> */}
                                    <Image
                                        src={checkAll ? checkboxIcon : uncheckboxIcon}
                                        w='24px'
                                        h='24px'
                                        cursor='pointer'
                                        onClick={(e)=> chooseAll(e)}
                                    />
                                </Th>
                                <Th
                                    {...style.thStyle}
                                >
                                    {getI18NText("订单号")}
                                </Th>
                                <Th
                                    {...style.thStyle}
                                >
                                    {getI18NText("酒店名")}/{getI18NText("房型")}/{getI18NText("数量")}
                                </Th>
                                <Th
                                    {...style.thStyle}
                                >
                                    {getI18NText("订单状态")}/{getI18NText("支付方式")}
                                </Th>
                                <Th
                                    {...style.thStyle}
                                >
                                    <Flex alignItems='center'>
                                        {getI18NText("预定提交时间")}
                                        <Image
                                            src={createdtime === 'createdtime_desc' ? upIcon : downIcon}
                                            w='10.37px'
                                            h='10.67px'
                                            cursor='pointer'
                                            ml='4px'
                                            onClick={(e)=> changeSortType('createdtime', e)}
                                        />
                                    </Flex>
                                </Th>
                                <Th
                                    {...style.thStyle}   
                                >
                                    <Flex alignItems='center'>
                                        {getI18NText("预定入住时间")}
                                        <Image
                                            src={checkindate === 'checkindate_desc' ? upIcon : downIcon}
                                            w='10.37px'
                                            h='10.67px'
                                            cursor='pointer'
                                            ml='4px'
                                            onClick={(e)=> changeSortType('checkindate', e)}
                                        />
                                    </Flex>
                                </Th>
                                <Th
                                    {...style.thStyle}
                                >
                                    <Flex alignItems='center'>
                                        {getI18NText('金额总价')}
                                        <Image
                                            src={agencytotalprice === 'agencytotalprice_desc' ? upIcon : downIcon}
                                            w='10.37px'
                                            h='10.67px'
                                            cursor='pointer'
                                            ml='4px'
                                            onClick={(e)=> changeSortType('agencytotalprice', e)}
                                        />
                                    </Flex>
                                </Th>
                                <Th
                                    {...style.thStyle}
                                    maxW='160px'
                                >
                                    {getI18NText("入住人信息")}
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody
                            borderWidth='1px'
                            borderColor='gray.700'
                        >
                            {
                                bookings.map(item=>
                                    <Tr
                                        key={item.id}
                                        borderColor='gray.700'
                                        _hover={{
                                            bgColor: '#F6F9FF'
                                        }}
                                        onMouseOver={(e)=>{
                                            setActive(item.id);
                                        }}
                                        onMouseOut={(e)=>{
                                            setActive('');
                                        }}
                                    >
                                        <Td
                                            {...style.tdStyle}
                                        >
                                            <Image
                                                src={item.checked ? checkboxIcon : uncheckboxIcon}
                                                w='24px'
                                                h='24px'
                                                cursor='pointer'
                                                onClick={(e)=> singleChoose(e, item.id)}
                                            />
                                        </Td>
                                        <Td
                                            {...style.tdStyle}
                                        >
                                            <a href={`/order/${encodeURIComponent(item.id)}`} target="_blank" style={{ textDecoration: 'none' }}>
                                                <Text
                                                    {...style.textStyle}
                                                    color={(item.checked || item.id == active) ? '#2D6CDF' : 'fontColor'}
                                                    cursor='pointer'
                                                >
                                                    {item.id.split('#')[1]}
                                                </Text>
                                            </a>
                                        </Td>
                                        <Td
                                            {...style.tdStyle}
                                        >
                                            <a href={`/order/${encodeURIComponent(item.id)}`} target="_blank" style={{ textDecoration: 'none' }}>
                                                <Text
                                                    {...style.textStyle}
                                                    cursor='pointer'
                                                    color={(item.checked || item.id == active)? '#2D6CDF' : 'fontColor'}
                                                >
                                                    {item.hotelname}
                                                </Text>
                                            </a>
                                            <Text {...style.textStyle} color='gray.800'>{item.hotelroomtype} / {item.roomnum} {getI18NText("间")}</Text>
                                        </Td>
                                        <Td
                                            {...style.tdStyle}
                                        >
                                            <OrderStatusLabel status={item.status} />
                                            <Text {...style.textStyle}>
                                                {renderPayStatus(item.paymenttype)}
                                            </Text>
                                        </Td>
                                        <Td
                                            {...style.tdStyle}
                                        >
                                            <Text {...style.textStyle}>{formatDate(new Date(item.createdtime), 'yyyy-MM-dd')}</Text>
                                            <Text>{dateFormate(item.createdtime)}</Text>
                                        </Td>
                                        <Td
                                        {...style.tdStyle}
                                        >
                                            <Flex alignItems='center'>
                                                <Image
                                                    src={checkInIcon}
                                                    w='16px'
                                                    h='16px'
                                                    mr='4px'
                                                />
                                                <Text {...style.textStyle}>
                                                    {formatDate(new Date(item.checkindate), 'yyyy-MM-dd')}
                                                </Text>
                                            </Flex>
                                            <Flex alignItems='center'>
                                                <Image
                                                    src={checkoutIcon}
                                                    w='16px'
                                                    h='16px'
                                                    mr='4px'
                                                />
                                                <Text {...style.textStyle}>
                                                    {formatDate(new Date(item.checkoutdate), 'yyyy-MM-dd')}
                                                </Text>
                                            </Flex>
                                        </Td>
                                        <Td
                                            {...style.tdStyle}
                                        >
                                            <Text {...style.textStyle}>¥ {item.agencytotalprice}</Text>
                                        </Td>
                                        <Td
                                            {...style.tdStyle}
                                            maxW='160px'
                                        >
                                            {
                                                item.guests[0].split('|').map(json=>
                                                    <Text {...style.textStyle}>{json}</Text>    
                                                )
                                            }
                                        </Td>
                                    </Tr>     
                                )
                            }
                        </Tbody>
                    </Table>
                    {
                        (count == 0 && !loading) && 
                        <Empty
                            tip='暂无订单'
                        />
                    }
                    {
                        count > 0 &&
                        <Flex
                            justifyContent='flex-end'
                        >
                            <Pagination
                                total={count}
                                current={pageNo}  
                                pageSize={pageSize}
                                onChange={(e)=> {onChangePage(e)}}
                                className="paginations"
                                prevIcon={<div className='prevIcon'></div>}
                                nextIcon={<div className='nextIcon'></div>}
                                jumpPrevIcon={<div className='jumpPrevIcon'></div>}
                                jumpNextIcon={<div className='jumpNextIcon'></div>}
                            />
                        </Flex>
                    }
                </Box>
            </VStack>
            <LoadingModal isOpen={isFormSent || loading} />
        </PageContainer>
    )
}