import {
    useState,
    useEffect,
} from 'react';
import {
    HStack,
    Image,
    Icon,
    Flex,
    Text
} from '@chakra-ui/react';

import {
    IoLocationOutline,
    IoStar
} from 'react-icons/io5';
import {
    Locale,
    Facility,
    ImageInfo,
    Policy,
    RoomInfo,
} from '~/types';

import fp from 'lodash/fp';

import { useI18NText } from '~/i18n/i18n';
import DefaultImg from '~/assets/images/default.png';

type HotelItemProps = {
    searchlocation?: string;
    adults: string | number;
    roomnum: string | number;
    children: string | number;
    start: string;
    end: string;
    key: string;
    hotelId: string;
    address: Record<Locale, string>;
    city: Record<Locale, string>;
    country: Record<Locale, string>;
    attractions: Record<Locale, string>;
    description: Record<Locale, string>;
    facilities: Facility[];
    images: ImageInfo[];
    location: number[];
    name: Record<Locale, string>;
    policies: Policy[];
    rooms: RoomInfo[];
    star: number;
    lowestPrice: number;
    childAges?: Array<Number>;
    onlyCity?: string
};

export default function HotelItem({
    searchlocation = '',
    adults = 0,
    roomnum = 0,
    children = 0,
    start = '',
    end = '',
    key='',
    hotelId='',
    address={
        en: '',
        zh_CN: '',
    },
    city={
        en: '',
        zh_CN: '',
    },
    attractions={
        en: '',
        zh_CN: '',
    },
    description={
        en: '',
        zh_CN: '',
    },
    facilities=[],
    country={
        en: '',
        zh_CN: '',
    },
    images=[],
    location=[],
    name={
        en: '',
        zh_CN: '',
    },
    policies=[],
    rooms=[],
    star=0,
    lowestPrice=0,
    childAges = [0],
    onlyCity = 'OFF'
}: HotelItemProps) {
    // i18n国际化纯文本
    const { getI18NText } = useI18NText({ prefix: 'component.' })
    let stars = [];
    for (let i = 0; i < star; i++) {
        stars.push(i);
    }
    return (
        <a
            href={`/hotel/${encodeURIComponent(hotelId)}?keyword=${name.zh_CN}&onlyCity=${onlyCity}&adults=${adults}&rooms=${roomnum}&children=${children}&start=${start.replaceAll('\/', '\-')}&end=${end.replaceAll('\/', '\-')}&childAges=${JSON.stringify(childAges)}`}
            target="_blank"
            style={{ textDecoration: 'none' }}
            key={hotelId}
        >
            <HStack
                as='div'
                h='182px'
                justifyContent='space-between'
                borderRadius='4px'
                bgColor='white'
                cursor='pointer'
                overflow='hidden'
                mb='16px'
                _hover={{
                    filter: 'drop-shadow(2px 2px 20px rgba(0, 0, 0, 0.2))'
                }}
            >
                <Image
                    w="334.06px"
                    h='182px'
                    loading="lazy"
                    objectFit="cover"
                    src={images.length > 0 ? images[0].url : DefaultImg }
                />
                <HStack
                    flex='1'
                    as='div'
                    display='block'
                    py='18px'
                    pr='22px'
                    pl='18.94px'
                    position='relative'
                >
                    <Flex
                        justifyContent='space-between'
                        marginInlineStart='0 !important'
                        alignItems='center'
                        mb='0.5px'
                    >
                        <HStack
                            alignItems='center'
                        >
                            <Text
                                mr='4px'
                                fontSize='22px'
                                color='fontColor'
                                letterSpacing='0 !important'
                            >
                                {name.zh_CN}
                                {
                                    stars.map(item=>
                                        <Icon
                                            key={item}
                                            as={IoStar}
                                            boxSize="1rem"
                                            color='#FEC214'
                                            marginInlineStart='0 !important'
                                            mr='2px !important'
                                        />    
                                    )
                                }
                            </Text>
                        </HStack>
                        {/* <Text
                            h='24px'
                            w='63px'
                            textAlign='center'
                            lineHeight='24px'
                            color='white'
                            bgColor='#2D6CDF'
                            fontSize='12px'
                            borderRadius='4px'
                            letterSpacing='0 !important'
                        >
                            {star}/5分
                        </Text> */}
                    </Flex>
                    <Text
                        marginInlineStart='0 !important'
                        fontSize='14px'
                        color='fontColor'
                        lineHeight='19.6px'
                        mb='2.5px'
                        letterSpacing='0 !important'
                    >
                        {name.en}
                    </Text>
                    <Flex
                        alignItems='center'
                        marginInlineStart='0 !important'
                        mb='24px !important'
                    >
                        <Icon
                            as={IoLocationOutline}
                            boxSize="1.25rem"
                            color='#D7D7D7'
                            mr='8.5px'
                            letterSpacing='0 !important'
                        />
                        <Text
                            color='#D7D7D7'
                            fontSize='14px'
                            letterSpacing='0 !important'
                        >
                            {country.zh_CN} {city.zh_CN}
                        </Text>
                    </Flex>
                    {/* {
                        price &&
                        <HStack
                            alignItems='center'
                            justifyContent='flex-end'
                        >
                            <Text
                                fontSize='12px'
                                color='#D7D7D7'
                                marginInlineStart='0 !important'
                                letterSpacing='0 !important'
                            >
                                ¥
                            </Text>
                            <Text
                                fontSize='16px'
                                color='#D7D7D7'
                                marginInlineStart='0 !important'
                                letterSpacing='0 !important'
                            >
                                {price}
                            </Text>
                            <Text
                                fontSize='12px'
                                color='#D7D7D7'
                                marginInlineStart='0 !important'
                                letterSpacing='0 !important'
                            >
                                起
                            </Text>
                        </HStack>
                    } */}
                    <HStack
                        alignItems='center'
                        justifyContent='flex-end'
                        h='21px'
                    >
                        <Text 
                            fontSize='12px'
                            color='gray.100'
                            letterSpacing='0 !important'
                        >
                            {getI18NText("含税")}
                        </Text>
                        <HStack
                            w='90px'
                            justifyContent='flex-end'
                        >
                            <Text
                                fontSize='12px'
                                color='fontColor'
                                marginInlineStart='0 !important'
                                letterSpacing='0 !important'
                            >
                                ¥
                            </Text>
                            <Text
                                fontSize='22px'
                                color='#002C5E'
                                marginInlineStart='0 !important'
                                letterSpacing='0 !important'
                                fontWeight='500'
                            >
                                {lowestPrice}
                            </Text>
                            <Text
                                fontSize='12px'
                                color='gray.100'
                                marginInlineStart='0 !important'
                                letterSpacing='0 !important'
                            >
                                {getI18NText("起")}
                            </Text> 
                        </HStack>
                    </HStack>
                    {/* {
                        limitPrice &&
                        <HStack
                            alignItems='center'
                            justifyContent='flex-end'
                            h='21px'
                        >
                            <Text
                                color='danger.100'
                                letterSpacing='0 !important'
                            >
                                限时价
                            </Text>
                            <HStack
                                w='90px'
                                justifyContent='flex-end'
                            >
                                <Text
                                    fontSize='12px'
                                    color='danger.100'
                                    marginInlineStart='0 !important'
                                    letterSpacing='0 !important'
                                >
                                    ¥
                                </Text>
                                <Text
                                    fontSize='22px'
                                    color='danger.100'
                                    marginInlineStart='0 !important'
                                    letterSpacing='0 !important'
                                    fontWeight='500'
                                >
                                    {limitPrice}
                                </Text>
                                <Text
                                    fontSize='12px'
                                    color='danger.100'
                                    marginInlineStart='0 !important'
                                    letterSpacing='0 !important'
                                >
                                    起
                                </Text>
                            </HStack>
                        </HStack>
                    } */}
                    <Text
                        position='absolute'
                        left='18.94px'
                        bottom='18px'
                        width='76px'
                        height='24px'
                        lineHeight='24px'
                        bgColor='rgba(255, 103, 112, 0.2)'
                        borderRadius='4px'
                        color='danger.100'
                        fontSize='14px'
                        textAlign='center'
                        letterSpacing='0 !important'
                    >
                        {getI18NText("立即确认")}
                    </Text>
                </HStack>
            </HStack>
        </a>
    );
}

